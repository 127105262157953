import jwt from 'jsonwebtoken';
import {fetching} from "../../../config/context";
let timerHandler;
export default {
    async getPublicKey ()  {
        const publicKey = localStorage.getItem('publicKey');
        if (publicKey) return atob(publicKey);

        const response = await fetching('/api/admin/key/public', {
            method: 'GET'
        }).catch(e => {
            throw e;
        });

        localStorage.setItem('publicKey', response.key);
        return atob(response.key);
    },
    async login(context, payload) {
        const response = await fetching('/api/twoFactorCode', {
            method: 'POST',
            body: payload
        }).catch(e => {
            throw e;
        });

        const token = response.accessToken;
        const pub = await context.dispatch('getPublicKey');
        await context.dispatch('afterGettingToken', [token, pub, response]);
    },
    async loginWithOkta(context, accessToken) {
        const response = await fetching('/api/loginWithOkta', {
            method: 'POST',
            body: {
                "accessToken": accessToken
            }
        }).catch(e => {
            throw e;
        });

        const token = response.accessToken;
        const pub = await context.dispatch('getPublicKey');
        // await this.__afterVerify(token, pub, context, response);
        await context.dispatch('afterGettingToken', [token, pub, response]);
    },
    async afterGettingToken(context, [token, pub, response]) {
        jwt.verify(token, pub, async function (err, user) {
            // if token alg != RS256,  err == invalid signature
            if (err) {
                throw err;
            }

            user.token = token;
            context.commit('setUser', user);
            await context.dispatch('logoutTimer', response.expiresIn);
            await context.dispatch('title/download');
            await context.dispatch('stringTable/download');
        });

    },
    async tryLogin(context) {
        console.log("tryLogin .. . . .");
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const pub = await context.dispatch('getPublicKey');

        if (token && userId) {
            jwt.verify(token, pub, function (err, user) {
                // if token alg != RS256,  err == invalid signature
                // console.log(err);
                if (err) {
                    context.dispatch("logout");
                    return;
                }

                user.token = token;
                context.commit('setUser', user);

                const expire = parseInt(user.exp)- parseInt(Date.now()/1000);
                context.dispatch('logoutTimer', expire);
            });
        }
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('titleData');
        localStorage.removeItem('stringData');
        localStorage.removeItem('publicKey');

        localStorage.removeItem('okta-cache-storage');
        localStorage.removeItem('okta-token-storage');
        localStorage.removeItem('okta-original-uri-storage');
        localStorage.removeItem('okta-shared-transaction-storage');
        
        clearTimeout(timerHandler);

        context.commit('setUser', {
            token: null,
            userId: null,
            privileges : null,
            roles : null,
            // tokenExpiration: null
        });
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    },
    logoutTimer(context, exp) {
        timerHandler = setTimeout(async ()  => {
            await context.dispatch('autoLogout');
        }, exp * 1000);

    }

};
