<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Player Group List</h2>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
            <tr class="table-active text-center">
              <th>#</th>
              <th>Name</th>
              <th>Desc</th>
              <th>Total numbers</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="item.rowKey" scope="row" :class="(item.status)?'': 'inactive'"  valign="middle">
              <td class="text-center col-md-1">{{index + 1}}</td>
              <td>
                <a :href="'/groups/edit/' + item.id">{{(item.name)}}</a>
              </td>
              <td>{{(item.desc)}}</td>
              <td class="text-right">{{(item.players.length)}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr >
              <td colspan="6" class="text-right">
                <w-button route="/groups/new" v-privilege="PRIVILEGES.GROUP_WRITE" shadow>Create New Group</w-button>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>


    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import {PRIVILEGES} from "../../config/context";
import {fetching} from "../../config/context";
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);

    onBeforeMount(() => {
      fetching( '/api/group/players').then( data  => {
        items.value = data;
      }).catch(e => {
        error.value = e.message || 'Failed to load the Groups, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });


    const handleError = () => {
      error.value = null;
    };

    return {
      items, isLoading, error, PRIVILEGES,
      handleError
    };
  },
};
</script>