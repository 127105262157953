<template>
  <w-app>
    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div>
      Sorting by:
      <w-radios
          v-model="sortBy"
          :items="[{ value: 'ItemFullName', label: 'Name' }, { value: 'ItemId', label: 'ID' }, { value: 'ItemClass', label: 'Class' }]"
          inline
      />
      <span id="SortButtonGroup">
        <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
        <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
      </span>
    </div>
    <div v-if="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <div v-show="displayedItems.length == 0">
      There are <b>no items</b> in this inventory.
    </div>
    <ul>
      <inventory-item
                v-for="item in displayedItems"
                  :key="item.ItemInstanceId"
                  :id="item.ItemId"
                  :instanceId="item.ItemInstanceId"
                  :fullname="item.ItemFullName"
                  :desc="item.ItemDesc"
                  :purchaseDate="item.PurchaseDate"
                  :itemClass="item.ItemClass"
                  :customData="item.CustomData"
                  :remainingUses="item.RemainingUses"
                  :expiration="item.Expiration"
                  :getItemThumbnail="getItemThumbnail"
                  @revoke-item="$emit('revoke-item', $event)"
              />
    </ul>
  </w-app>
</template>

<script>
import {ref, toRefs, onBeforeMount} from 'vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import InventoryItem from "./InventoryItem";

export default {
  components: {
    InventoryItem
  },
  emits: ['revoke-item'],
  props: ['items', 'getItemThumbnail'],
  setup(props) {
    const { items } = toRefs(props);
    const sortBy = ref('ItemFullName');
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['ItemId', 'ItemInstanceId', "ItemFullName",'ItemClass']
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        sortBy
    );

    onBeforeMount(()=>{
      // console.log("items = " + items)
      sorting.value = 'desc'
    })

    return {
      enteredSearchTerm,
      updateSearch,
      displayedItems,
      sorting,
      sortBy,
      sort,
      availableItems
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
div.w-app {
  min-height: 10px ;
}

#SortButtonGroup {
  margin-left: 10px;
}

.w-radios {
  margin-left: 10px;
}
</style>
