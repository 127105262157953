<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Dynamic Segment List</h2>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Player Id</th>
                <th>displayName</th>
                <th>LastLogin</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.rowKey"
                scope="row"
                :class="item.status ? '' : 'inactive'"
                valign="middle"
              >
                <td class="text-center col-md-1">{{ index + 1 }}</td>

                <td>
                  <a :href="'/players/' + item.playerId">{{ item.playerId }}</a>
                </td>
                <td>{{ item.displayName }}</td>
                <td>{{ item.createdAgo }}</td>
                <td>{{ item.LastLogin }}</td>
                <td>{{ item.Location }}</td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Loading..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";
import { useRoute } from "vue-router";
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const route = useRoute();

    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);
    const uuid = ref(null);
    uuid.value = route.path.split("/").reverse()[0];

    onBeforeMount(() => {
      fetching(
        `/api/dynamic_segment/PlayerList/${uuid.value}?&pageNumber=1&pageSize=1000000`
      )
        .then((data) => {
          items.value = data.results;
          console.log(data);
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the Dynamic Segments, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items,
      isLoading,
      error,
      PRIVILEGES,
      handleError,
    };
  },
};
</script>
