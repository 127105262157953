<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Segments List</h2>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
            <tr class="table-active text-center">
              <th>#</th>
              <th>ID</th>
              <th>Name</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="item.rowKey" scope="row" :class="(item.status)?'': 'inactive'"  valign="middle">
              <td class="text-center col-md-1">{{index + 1}}</td>
              <td class="text-center">
                <a :href="'/segments/'+item.Id + '/players?name=' + item.Name">{{item.Id}}</a>
              </td>
              <td>{{(item.Name)}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr >
              <td colspan="6" class="text-right">
                <w-button :route="'https://developer.playfab.com/en-US/r/t/' + titleId + '/segments/new'"
                          target="_blank"
                          v-privilege="PRIVILEGES.GROUP_WRITE"
                          shadow>
                  Add New Segment
                </w-button>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>


    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import {fetching} from "../../config/context";
import {PRIVILEGES} from "../../config/context";
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);

    const items = ref([]);
    const titleId = ref(null);

    onBeforeMount(() => {
      fetching( '/api/playfab/segments').then( data  => {
        items.value = data.segments;
        titleId.value = data.titleId;
      }).catch(e => {
        error.value = e.message || 'Failed to load the User, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items, isLoading, error, titleId, PRIVILEGES,
      handleError
    };
  },
};
</script>