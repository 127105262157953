<template>
  <section>
    <w-card>
      <h1>Not authorized</h1>
      <p>
        You don't have enough permissions.
        <br/>
        <w-button @click="$router.go(-1)">back</w-button>
      </p>
    </w-card>
  </section>
</template>