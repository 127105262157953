<template>
  <li>

    <div style="clear:both; display: flex;">
      <div>
        <v-lazy-image
            :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(thumbnail) + '.png'"
            onerror="this.onerror=null;this.src='/images/onerror.png'"
            class="item-thumbnail"
            style="width: 250px"
        />
      </div>
      <div style="width: 100%">
        <h3 @click="showInventory">{{ getFullName() }}</h3>
        <w-button
            v-show="superCapitalFlagshipList?.indexOf(name) > -1"
            bg-color="info"
            v-privilege="PRIVILEGES.PLAYER_WRITE"
            @click="setFlagship(id)">Set Flagship</w-button>

        <div><span>ID:</span> {{ id }} </div>
        <div><span>Type:</span> {{ name }} </div>
        <div>{{ desc }}</div>
        <div id="child" v-show="showChild">
          <div align="center" style="margin: 20px" v-show="isLoading">
            <div class="spinner-border text-primary"/>
            <h4>Loading ...</h4>
          </div>

          <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
            {{ error }}
            <w-flex justify-end>
              <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
            </w-flex>
          </w-dialog>

          <inventory-list :items="inventory"
                          v-show="!isLoading"
                          :getItemThumbnail="toItemThumbnail"
                          @revoke-item="revokeItem"/>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ref, toRefs } from 'vue';
import {fetching} from "../../config/context";
import InventoryList from '../../components/inventory/InventoryList.vue'
import VLazyImage from "v-lazy-image";
import {PRIVILEGES} from "../../config/context";

export default {
  components: {
    InventoryList,
    VLazyImage
  },
  props: [
      'id', 'name', 'fullname', 'desc', 'playfabId', "getItemFullName", "getItemDesc",
      "getItemThumbnail", 'currentFlagship', 'superCapitalFlagshipList'
  ],
  emits: ['set-flagship'],
  setup(props, context){
    const item = toRefs(props);
    const showChild = ref(false);
    const isLoading = ref(false);
    const error = ref(null);
    const inventory = ref(null);
    const thumbnail = ref(props.getItemThumbnail(props.name));

    const handleError = () => {
      error.value = null;
      showChild.value = false;
    }

    function showInventory(){
      showChild.value = !showChild.value

      isLoading.value = true;
      fetching('/api/profile/' + item.playfabId.value + '/characters/' + item.id.value + '/inventory')
          .then((data)=>{

            data.map(c => c.ItemFullName = props.getItemFullName(c));
            data.map(c => c.ItemDesc = props.getItemDesc(c));
            inventory.value = data;

            isLoading.value = false;
          }).catch(e => {
            error.value = e.message || 'Failed to get the items from this character, try later.';
          }).finally(() => {
            isLoading.value = false;
          });
    }

    const revokeItem = (instanceId) => {

      if(!confirm("Please confirm?")){
        return;
      }

      isLoading.value = true;

      fetching('/api/profile/' + item.playfabId.value + '/characters/' + item.id.value + '/inventory/' + instanceId, { "method" : 'DELETE'})
        .then(()=>{
              showChild.value = false;
              inventory.value = null;
              // isLoading.value = true;

              showInventory();
        }).catch(e => {
          error.value = e.message || 'Failed to revoke the item, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
    }

    const getFullName = () => {
      // console.log(props);
      if (props.name === "stash" || props.name === "mailbox") {
        return props.name;
      }

      // "Stash":fullname
      return props.fullname;
    }

    const toItemThumbnail = (id) => {
      return props.getItemThumbnail(id)
    }

    const setFlagship = (id) => {
      context.emit("set-flagship", id);
    }

    return {
      inventory, showChild, isLoading, error, thumbnail, PRIVILEGES,
      showInventory, handleError, revokeItem, getFullName, toItemThumbnail, setFlagship
    };
  }

};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h3 {
  margin: 0;
  color: darkblue;
  cursor: pointer;
  text-underline: #3d008d;
  text-decoration: underline;
}

button {
  float: right;
}
span {
  color: darkgray;
}

#child {
  padding: 10px;
  background-color: #fdfdfd;
}

</style>