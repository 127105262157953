export default {
    setTitle(state, payload) {
        // ShopData&key=&key=/

        // state.blueprintData = payload.BlueprintData;
        // state.colorData = payload.ColorData;
        // state.combatVariables = payload.CombatVariables;
        // state.combatVariables_QDB = payload.CombatVariables_QDB;
        // state.conditionalStatModData = payload.ConditionalStatModData;
        // state.conditionalStatModData_QDB = payload.ConditionalStatModData_QDB;
        // state.dialogSequenceData = payload.DialogSequenceData;
        // state.econModData = payload.EconModData;
        // state.economyConfig = payload.EconomyConfig;
        // state.effectAreas = payload.EffectAreas;
        // state.effectAreas_QDB = payload.EffectAreas_QDB;
        // state.enemySetData = payload.EnemySetData;
        // state.externalModuleData = payload.ExternalModuleData;
        // state.externalModuleData_QDB = payload.ExternalModuleData_QDB;
        // state.factionData = payload.FactionData;
        // state.factionEffectsList = payload.FactionEffectsList;
        state.globalVariables = payload.GlobalVariables;
        // state.internalModuleData = payload.InternalModuleData;
        state.itemData = payload.ItemData;
        // state.kiithData = payload.KiithData;
        // state.layoutData = payload.LayoutData;
        // state.levelData = payload.LevelData;
        // state.lootData = payload.LootData;
        // state.miningData = payload.MiningData;
        // state.missionData = payload.MissionData;
        // state.missionStepActions = payload.MissionStepActions;
        // state.missionStepActions_QDB = payload.MissionStepActions_QDB;
        // state.missionSteps = payload.MissionSteps;
        // state.missionSteps_QDB = payload.MissionSteps_QDB;
        // state.missionTemplateData = payload.MissionTemplateData;
        // state.officerData = payload.OfficerData;
        // state.officerData_QDB = payload.OfficerData_QDB;
        // state.officerEffectsData = payload.OfficerEffectsData;
        // state.officerNameData = payload.OfficerNameData;
        // state.officerPortraitData = payload.OfficerPortraitData;
        // state.officerSlotConfig = payload.OfficerSlotConfig;
        // state.officerSlotConfig_QDB = payload.OfficerSlotConfig_QDB;
        // state.officerStatModData = payload.OfficerStatModData;
        // state.penaltyData = payload.PenaltyData;
        // state.premiumShopData = payload.PremiumShopData;
        // state.profileVersionData = payload.ProfileVersionData;
        // state.pushNotifications = payload.PushNotifications;
        state.questData = payload.QuestData;
        state.questLineData = payload.QuestLineData;
        // state.questTemplateData = payload.QuestTemplateData;
        // state.researchData = payload.ResearchData;
        // state.rewardData = payload.RewardData;
        // state.sellData = payload.SellData;
        state.shopData = payload.ShopData;
        // state.skillData = payload.SkillData;
        // state.skillData_QDB = payload.SkillData_QDB;
        // state.starSystemConfigData = payload.StarSystemConfigData;
        state.starSystemData = payload.StarSystemData;
        // state.statModData = payload.StatModData;
        // state.statModData_QDB = payload.StatModData_QDB;
        // state.status = payload.Status;
        state.stringData = payload.StringData;
        // state.uIHightlightData = payload.UIHightlightData;
        state.unitData = payload.UnitData;
        // state.unitData_QDB = payload.UnitData_QDB;
        // state.utilitySystemData = payload.UtilitySystemData;
        // state.utilitySystemData_QDB = payload.UtilitySystemData_QDB;
        // state.valueBasedStatMod = payload.ValueBasedStatMod;
        // state.valueBasedStatMod_QDB = payload.ValueBasedStatMod_QDB;
        // state.versionControl = payload.VersionControl;
        // state.weaponEffects = payload.WeaponEffects;
        // state.weaponEffects_QDB = payload.WeaponEffects_QDB;
        // state.weaponSpecialEffects = payload.WeaponSpecialEffects;
        // state.weaponSpecialEffects_QDB = payload.WeaponSpecialEffects_QDB;
        // state.weaponSystemData = payload.WeaponSystemData;
        // state.weaponSystemData_QDB = payload.weaponSystemData_QDB;
    }
};