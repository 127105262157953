<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link to="/index" class="nav-link" active-class="active">
            <span>Server Status</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/players" class="nav-link" active-class="active">
            Players
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/segments" class="nav-link" active-class="active">
            Segments
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            to="/dynamic_segment"
            class="nav-link"
            active-class="active"
          >
            Dynamic Segment
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/groups" class="nav-link" active-class="active">
            Groups
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/clans" class="nav-link" active-class="active">
            Clans
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/InvalidClans"
            class="nav-link"
            active-class="active"
          >
            Invalid Clans
          </router-link>
        </li>

        <!-- <li class="nav-item">
          <router-link to="/inventory" class="nav-link" active-class="active">
            Inventory
          </router-link>
        </li> -->

        <li class="nav-item">
          <router-link
            to="/give-item-to-player"
            class="nav-link"
            active-class="active"
          >
            Give Item To Player
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            to="/batch-item-send"
            class="nav-link"
            active-class="active"
          >
            Batch Item send
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            to="/webview/banner/list"
            class="nav-link"
            active-class="active"
          >
            WebView Banner
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/mail/editor" class="nav-link" active-class="active">
            Group Mail
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            to="/bannedplayerlist"
            class="nav-link"
            active-class="active"
          >
            Banned Player List
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            to="/premiumaccountlog"
            class="nav-link"
            active-class="active"
          >
            Premium Account list
          </router-link>
        </li>

        <li class="nav-item" v-show="isShow">
          <router-link to="/admin" class="nav-link" active-class="active">
            GM Management
          </router-link>
        </li>
        <!-- <li class="nav-item" v-show="isShow">
          <router-link
            to="/player-support-history"
            class="nav-link"
            active-class="active"
          >
            Player Support
          </router-link>
        </li> -->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/shop" class="nav-link" active-class="active">-->
        <!--            <span>Shop Management</span>-->
        <!--          </router-link>-->
        <!--        </li>-->

        <!--        <li class="nav-item">-->
        <!--          <router-link to="/liveSheet" class="nav-link" active-class="active">-->
        <!--            <span>Live Sheet Data</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/Maintenance" class="nav-link" active-class="active">-->
        <!--            <span>Maintenance/Update</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/Profile" class="nav-link" active-class="active">-->
        <!--            <span>Profile Management</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/Announcement" class="nav-link" active-class="active">-->
        <!--            <span>Announcement</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/Rights" class="nav-link" active-class="active">-->
        <!--            <span>Rights Management</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item">-->
        <!--          <router-link to="/Security" class="nav-link" active-class="active">-->
        <!--            <span>Security</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="nav-item" v-show="isShow">-->
        <!--          <router-link to="/auditing" class="nav-link" active-class="active">-->
        <!--            <span>Auditing</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
      </ul>
    </div>
  </nav>
</template>

<script>
import store from "./../store/index.js";
import { computed } from "vue";
export default {
  name: "Menu",
  setup() {
    const isShow = computed(function() {
      // todo: javascript some?
      return store.getters.roles.includes("ADMIN");
    });

    return { isShow };
  },
};
</script>
<style scoped>
li span {
  color: #42b983;
  text-decoration: line-through;
}
</style>
