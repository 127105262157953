<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Player Clan List</h2>
        <w-form @submit.prevent="submitForm">

          <w-flex xl12 wrap>
            <w-input label-position="left" label="Clan Name:" v-model.trim="ClanName" />
            <w-input label-position="left" label="Clan Acronym :" v-model.trim="ClanAcronym" />
            <w-button @click="Search()">Search</w-button>
          </w-flex>

        </w-form>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Name</th>
                <th>Acronym</th>
                <th>Description</th>
                <th>Clan Leader ID</th>
                <th>Total numbers</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.rowKey" scope="row" :class="(item.status) ? '' : 'inactive'"
                valign="middle">
                <td class="text-center col-md-1">{{ index + 1 }}</td>
                <td> <a :href="'/clans/edit/' + item.id">{{ (item.data.name) }}</a> </td>
                <td>{{ (item.data.acronym) }}</td>
                <td>{{ (item.data.description) }}</td>
                <td><a :href='"/players/" + item.leaderId'>{{ (item.leaderId) }}</a> </td>
                <td><a :href='"/clans/clansmemberList/" + item.id'>{{ (item.memberCount) }}</a></td>
                <td>
                  <w-button :route="'/clans/edit/' + item.id" target="_blank" shadow>
                    Edit Clans
                  </w-button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" class="text-right">
                  <!-- <w-button route="/Clans/new" v-privilege="PRIVILEGES.Clan_WRITE" shadow>Create New Clan</w-button> -->
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>



    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading..." persistent :width="400">
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script >
import { ref, onBeforeMount } from 'vue';
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";


export default {
  name: "Clan",
  components: {

  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);
    const ClanName = ref("");
    const ClanAcronym = ref("");

    const Search = () => {

      const body = {
        "Name": ClanName.value,
        "Acronym": ClanAcronym.value
      }

      fetching('/api/Alliance/AllianceList',
        {
          method: "POST",
          body: body
        }).then(data => {
          items.value = data.alliances;
          console.log(items);
        }).catch(e => {
          error.value = e.message || 'Failed to load the Clans, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
    }

    onBeforeMount(() => {
      Search();
    });


    const handleError = () => {
      error.value = null;
    };

    return {
      items, isLoading, error, PRIVILEGES, ClanName, ClanAcronym,
      handleError, Search
    };
  },
};
</script>