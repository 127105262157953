<template>
  <w-app>
    <Nav />
    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Authenticating..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <div class="d-flex justify-content-center">
      <w-card style="width: 640px; padding: 20px; margin-top:100px" shadow>
        <h2>Enter your code</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
          <div>
            <w-input
              label="Security Code"
              v-model.trim="twoFactorCode"
              :validators="[validators.required, validators.min_6_length]"
            >
            </w-input>
            * Enter the 6-digit security code from your authenticator app.
          </div>

          <div class="text-right">
            <w-button type="submit" lg shadow> Got It </w-button>
          </div>
        </w-form>
      </w-card>
    </div>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useCookie } from "vue-cookie-next";
// import {fetching} from "../../config/context";
import { useStore } from "vuex";

import Nav from "@/components/Nav.vue";

export default {
  components: {
    Nav,
  },
  setup() {
    const store = useStore();
    const cookie = useCookie();
    const router = useRouter();
    const formIsValid = ref(false);
    const twoFactorCode = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const token = ref(null);

    let validators = {
      required: (value) => !!value || "This field is required",
      min_6_length: (value) =>
        value.length === 6 || "This field must be 6 characters long.",
    };

    const submitForm = async () => {
      if (!formIsValid.value) {
        return;
      }

      isLoading.value = true;

      const actionPayload = {
        token: token.value,
        code: twoFactorCode.value,
      };

      try {
        await store.dispatch("login", actionPayload);
        cookie.removeCookie("mfa");
        await router.replace("/");
      } catch (err) {
        error.value = err.message || "Failed to authenticate, try later.";
        isLoading.value = false;
      }
    };

    onBeforeMount(() => {
      const mfa = cookie.getCookie("mfa");
      token.value = mfa.token;
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      formIsValid,
      twoFactorCode,
      isLoading,
      error,
      validators,
      submitForm,
      handleError,
    };
  },
};
</script>
<style scoped>
form div {
  padding-block-start: 10px;
}
</style>
