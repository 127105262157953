function getItemThumbnail(itemData, itemId) {

  // return id part of "bp_{id}"
  itemId = (itemId.length == 5 && itemId.startsWith("bp_")) ? itemId.substring(3) : itemId;

  try {
    if (Object.keys(itemData).indexOf(itemId) > -1) {
      if (Object.keys(itemData[itemId]).indexOf("IconOverride") > -1) {
        return itemData[itemId].IconOverride || itemId;
      }
    }
  } catch (e) {
    console.error(`${itemId} : ${e.message}`)
  }

  return itemId;
}


function getItemFullName(stringData, itemId) {
  const itemFullName = getItemFullNameFallback(stringData, itemId);
  if (itemFullName !== "n/a") return itemFullName;

  const _itemId = itemId.replace(/_t\d$/, "_tX")
  const _itemFullName = getItemFullNameFallback(stringData, _itemId);
  if (_itemFullName !== "n/a") return _itemFullName;

  const __itemId = itemId.replace(/_t\d$/, "_tx");
  return getItemFullNameFallback(stringData, __itemId);
}


function getItemFullNameFallback(stringData, itemId) {
  if (itemId.startsWith("bp_")) {
    const key = itemId.substring(3);
    if (Object.keys(stringData).indexOf(key) > -1) {
      return itemId.substring(0, 2).toUpperCase() + ": " + stringData[key]
    }
    return "n/a";
  }
  return (Object.keys(stringData).indexOf(itemId) > -1) ? stringData[itemId] : 'n/a';
}


function getItemFullName2(itemData, stringData, itemId) {
  let item = null;
  if (Object.keys(itemData).indexOf(itemId) > -1) {
    item = itemData[itemId];
    if (Object.keys(item).indexOf("LocaOverride") > -1) {
      itemId = itemData[itemId].LocaOverride;
    }
    // TODO: check item to usse server side data
  }

  let tier = item?.Tier || '';
  tier = (tier !== '' && tier !== "-1") ? `T${tier}` : "";

  let rarity = item?.Rarity || '';
  if (parseInt(rarity) === 2) {
    rarity = "Rare";
  } else if (parseInt(rarity) === 3) {
    rarity = "Epic";
  } else if (parseInt(rarity) === 4) {
    rarity = "Legendary";
  } else {
    rarity = ""; //uncommon
  }


  return `${rarity} ${tier} ${getItemFullName(stringData, itemId)}`.trim().replace(/  +/g, ' ');

}


function getItemDesc2(itemData, stringData, itemId) {
  if (Object.keys(itemData).indexOf(itemId) > -1) {
    let item = itemData[itemId];
    if (Object.keys(item).indexOf("LocaOverride") > -1) {
      itemId = itemData[itemId].LocaOverride;
    }
  }

  return getItemDesc(stringData, itemId);
}

function getItemDesc(stringData, itemId) {
  itemId = "desc_" + itemId
  if (Object.keys(stringData).indexOf(itemId) > -1) return stringData[itemId];

  const _itemId = itemId.replace(/_t\d$/, "_tX")
  const _itemDesc = (Object.keys(stringData).indexOf(_itemId) > -1) ? stringData[_itemId] : "n/a";
  if (_itemDesc !== "n/a") return _itemDesc;

  const __itemId = itemId.replace(/_t\d$/, "_tx")
  return (Object.keys(stringData).indexOf(__itemId) > -1) ? stringData[__itemId] : "n/a";
}

export {
  getItemDesc, getItemFullName, getItemThumbnail, getItemFullName2, getItemDesc2
};
