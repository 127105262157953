<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Group Mail</h2>
        <div>

          <div v-show="error">
            <p>Error: <b>{{error}}</b></p>
          </div>
          <table>
            <tr>
              <td style="vertical-align: top;">
                <h3>Template Editor</h3>
                <ul>
                  <li v-for="(sample, index) in templates" :key="sample" @click="selectTemplate(index)">
                    <a href="#">{{ sample.Name }}</a>
                  </li>
                </ul>
                <div>
                  <w-input v-model="header" outline placeholder="Header"></w-input>
                </div>
                <div>
                  <w-textarea rows="12" cols="100" v-model="body" outline placeholder="Body"/>
                </div>
                <div>
                  <Multiselect
                      v-model="items"
                      mode="tags"
                      :searchable="true"
                      :createTag="false"
                      :options="options"/>
                </div>
              </td>
              <td>
                <h3>Template Preview</h3>
                <div>
                  <p><b>{{header}}</b></p>
                  <p style="width: 450px;text-justify: inter-word;text-align: justify;">
                    <pre>{{body}}</pre>
                  </p>
                  <hr>
                  <div v-for="item in items" :key="item">
                    <div style="clear:both; display: flex;">
                      <v-lazy-image
                          :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/' + toItemThumbnail(item) + '.png'"
                          onerror="this.onerror=null;this.src='/images/onerror.png'"
                          class="item-thumbnail"
                          style="width: 150px"
                      />
                      <div style="width: 100%">
                        <div>
                          <b>{{ toItemFullName(item) }}</b>
                        </div>
                        <div>
                          ID: {{item}}
                        </div>
                        <div>{{ toItemDesc(item) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <h3>JSON - Output</h3>
          <div>
              <div v-if="outputValidator !== true" style="color:red; font-weight: 900;">Invalid JSON !!!!</div>
              <textarea v-model="output" rows="12" cols="150"></textarea>
          </div>

          <h3>Stages</h3>
          <ul>
            <li>
              <b>Nimbus_DEV</b>
              <div>
                <a href="https://developer.playfab.com/en-us/r/t/DD86E/automation/scheduled-tasks/6B94BFDB3FAE0DAE/edit" target="_blank">link</a>
              </div>
            </li>
            <li>
              <b>Nimbus EXTERNAL</b>
              <div>
                <a href="https://developer.playfab.com/en-us/r/t/4F65D/automation/scheduled-tasks/3486D5E79F9DF0E0/edit" target="_blank">link</a>
              </div>
            </li>
            <li>
              <b>Nimbus REVIEW</b>
              <div>
                <a href="https://developer.playfab.com/en-us/r/t/E557B/automation/scheduled-tasks/59E0C49C9ECA9CA3/edit" target="_blank">link</a>
              </div>
            </li>
            <li>
              <b>Nimbus QA</b>
              <div>
                <a href="https://developer.playfab.com/en-us/r/t/2E928/automation/scheduled-tasks/4ACE9A95F05B5869/edit" target="_blank">link</a>
              </div>
            </li>
            <li>
              <b>Nimbus LIVE</b>
              <div>
                <a href="https://developer.playfab.com/en-us/r/t/9BC9D/automation/scheduled-tasks/321D6022373E7543/edit" target="_blank">link</a>
              </div>
            </li>
          </ul>
        </div>

        <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
          {{ error }}
          <w-flex justify-end>
            <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
          </w-flex>
        </w-dialog>

        <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>
      </div>
    </div>
  </w-app>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { ref, watch, onBeforeMount} from "vue";
import { getItemFullName2, getItemDesc2, getItemThumbnail} from '../../hooks/item.js';
import { useStore } from 'vuex';
import VLazyImage from "v-lazy-image";

export default {
  name: "MailTemplate",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  components: {
    Multiselect, VLazyImage
  },
  setup() {
    const store = useStore();
    const itemData = JSON.parse(store.getters["title/itemData"]);
    const stringData = store.getters["stringTable/stringData"];
    const isLoading = ref(false);
    const outputValidator = ref(true);
    const error = ref(null);

    const templates = ref([
      {
        "Name": "Planned Content Update complete",
        "Header": "Update complete",
        "Body": "Greetings Commander, \n\nThe scheduled update is now online. Please check out the official Discord for update details and accept this gift to commend you for your patience. \n\nSee you in space,\nFleet Command"
      },
      {
        "Name": "Planned Tech Maintenance complete",
        "Header": "Maintenance complete",
        "Body": "Greetings Commander, \n\nThe planned maintenance has been completed and all systems are operational again. Please accept this gift to commend you for your patience. \n\nSee you in space,\nFleet Command"
      }, {
        "Name": "Short Notice Downtime complete",
        "Header": "Systems back online",
        "Body": "Greetings Commander, \n\nOur systems experienced some unforeseen technical issues. We have found the source, and all systems are now back online. Please accept this gift for the disruption to your mission. \n\nSee you in space,\nFleet Command"
      }
    ]);

    const header = ref(null);
    const body = ref(null);
    const items = ref([]);
    const options = ref(null);
    const output = ref(null);

    watch([header, body, items], () => {
      const rewards = {};

      items.value.forEach(x => {
        rewards[x] = 1;
      })
      try{
        const res =  {
          "Header": header.value,
          "Body": body.value,
          "Rewards": rewards
        }
        // console.log(res);
        output.value = JSON.stringify(res, null, 2);
      }catch (e){
        error.value = e;
      }

    });

    watch(output, () => {
      try {
        JSON.parse(output.value);
        outputValidator.value = true;
      }catch(e){
        outputValidator.value = false
      }
    });

    function toItemFullName(c) {
      try{
        return getItemFullName2(itemData, stringData, c) ;
      }catch (e){
        return c;
      }
    }

    function toItemDesc(c) {
      try {
        return getItemDesc2(itemData, stringData, c)
      }catch(e){
        return c;
      }
    }

    function toItemThumbnail(c) {
      try {
        return getItemThumbnail(itemData, c)
      }catch(e){
        return c;
      }
    }

    onBeforeMount(() => {

      selectTemplate(0);

      const temp = {};
      Object.keys(stringData).forEach(x => {
        temp[x] = stringData[x];
      })

      options.value = Object.keys(temp).filter(x => !x.endsWith("_desc") && !x.startsWith("desc_"));

      items.value = ['maintenance_pack_1h_mix'];
    });

    const handleError = () => {
      error.value = null;
    };

    const selectTemplate = (idx) => {
      header.value = templates.value[idx].Header;
      body.value = templates.value[idx].Body;
    };

    return {
      output, isLoading, error, header, body, items, options, templates, outputValidator,
      toItemFullName, toItemDesc, handleError, selectTemplate, toItemThumbnail
    }
  }
};
</script>
<style scoped>
pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

p {
  color: darkred;
  margin-left: 5px;
}

td {
  padding-right: 20px;
}

textarea{
  padding: 10px;
  width:90%;
  font-size: 15px;
  background-color: lightgrey;
}
</style>