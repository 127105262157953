<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Dynamic Segment {{ isCreate ? "Create" : "Update" }}</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
          <table class="table table-hover">
            <thead>
              <tr>
                <th colspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <tr valign="middle">
                <th class="text-center">Description</th>
                <td>
                  <w-input
                    outline
                    data-gramm="false"
                    v-model.trim="cronDescription"
                    :validators="[validators.required]"
                  ></w-input>
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Cron Pattern</th>
                <td>
                  <w-input
                    outline
                    v-model.trim="cronPattern"
                    :validators="[validators.required]"
                  ></w-input>
                  Make Cron Pattern like this: 0 0 1 * * ? *
                  <a href="https://crontab.cronhub.io/" target="_blank">
                    Tools</a
                  >
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Script</th>
                <td>
                  <w-textarea
                    outline
                    rows="10"
                    data-gramm="false"
                    v-model.trim="script"
                    :validators="[validators.required]"
                  ></w-textarea>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="text-right">
                  <w-button
                    bg-color="error"
                    shadow
                    @click="deleteDynamicSegment"
                    v-show="!isCreate"
                    v-privilege="PRIVILEGES.DYNAMIC_SEGMENT_WRITE"
                    >delete</w-button
                  >
                  <w-button
                    bg-color="info"
                    shadow
                    type="submit"
                    v-privilege="PRIVILEGES.DYNAMIC_SEGMENT_WRITE"
                  >
                    {{ isCreate ? "Submit" : "Update" }}
                  </w-button>
                </td>
              </tr>
            </tfoot>
          </table>
        </w-form>
      </div>
    </div>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>
  </w-app>
</template>

<script>
import { fetching, ROLES } from "../../config/context";
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { PRIVILEGES } from "../../config/context";
export default {
  name: "Dynamic Segment Form",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup: function() {
    const route = useRoute();
    const router = useRouter();

    const uuid = ref(null);
    const isCreate = ref(false);

    const name = ref("");
    const cronDescription = ref("");

    const script = ref("");
    const cronPattern = ref("");

    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const validators = {
      required: (value) => !!value || "This field is required",
    };

    let apiUri = "/api/dynamic_segment";

    const deleteDynamicSegment = () => {
      if (
        confirm(
          'Are you sure?\nDo you really want to delete "' + name.value + '"?'
        )
      ) {
        isLoading.value = true;

        fetching(apiUri + "/" + uuid.value, {
          method: "DELETE",
        })
          .then(() => {
            router.push("/dynamic_segment"); //todo: magic string
          })
          .catch((e) => {
            error.value =
              e.message || "Failed to delete the Dynamic Segment, try later.";
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    const submitForm = () => {
      if (!formIsValid.value) {
        return;
      }

      isLoading.value = true;

      let method = "POST";
      const body = {
        name: name.value,
        cronDescription: cronDescription.value,
        script: script.value,
        cronPattern: cronPattern.value,
      };

      if (!isCreate.value) {
        method = "PUT";
        body.id = uuid.value;
      }

      fetching(apiUri, {
        method: method,
        body: body,
      })
        .then(() => {
          router.push("/dynamic_segment"); //todo: magic string
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to create the DynamicSegment, try later.";

          isLoading.value = false;
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const handleError = () => {
      error.value = null;
    };

    onBeforeMount(() => {
      if (route.path.endsWith("new")) {
        isCreate.value = true;
        return;
      }

      uuid.value = route.path.split("/").reverse()[0];
      isLoading.value = true;
      fetching(apiUri + "/" + uuid.value)
        .then((data) => {
          name.value = data.name;
          cronDescription.value = data.cronDescription;
          script.value = data.script;
          cronPattern.value = data.cronPattern;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to get the DynamicSegment, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const MakeCronJob = () => {
      if (
        confirm(
          'Are you sure?\nDo you really want to Make Cron Job "' +
            name.value +
            '"?'
        )
      ) {
        isLoading.value = true;

        fetching(apiUri + "/MakeCronJob?dynamicSegmentId=" + uuid.value, {
          method: "POST",
        })
          .then(() => {
            router.push("/dynamic_segment"); //todo: magic string
          })
          .catch((e) => {
            console.log(e);
            error.value =
              e.message ||
              "Failed to Make Cron Job the Dynamic Segment, try later.";
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    return {
      uuid,
      MakeCronJob,
      cronDescription,
      formIsValid,
      script,
      isLoading,
      error,
      validators,
      isCreate,
      submitForm,
      handleError,
      deleteDynamicSegment,
      cronPattern,
      PRIVILEGES,
      ROLES,
    };
  },
};
</script>
