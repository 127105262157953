<template>
  <w-app>
    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div>
      Sorting by:
      <w-radios
          v-model="sortBy"
          :items="[{ value: 'Date', label: 'Date' }, { value: 'Header', label: 'Title' }]"
          inline
      />
      <span id="SortButtonGroup">
        <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
        <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
      </span>
    </div>
    <div v-if="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <div v-show="displayedItems.length == 0">
      There are <b>no items</b> in this inventory.
    </div>
    <ul>
      <post-item
          v-for="item in displayedItems"
          :key="item.PostId"
          :header="item.Header"
          :body="item.Body"
          :items="item.Items"
          :rewards="item.Rewards"
          :date="item.Date"
          :state="item.State"
          :getItemThumbnail="getItemThumbnail"
      />
    </ul>
  </w-app>
</template>
<script>
import {ref, toRefs, onBeforeMount} from 'vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import PostItem from "./PostItem.vue";

export default {
  components: {
    PostItem
  },
  props: ['items', 'getItemThumbnail'],
  setup(props) {
    const { items } = toRefs(props);
    const sortBy = ref('Date');
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['Header', 'Body']
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        sortBy
    );

    onBeforeMount(()=>{
      sorting.value = 'desc'
    })

    return {
      enteredSearchTerm,
      updateSearch,
      displayedItems,
      sorting,
      sortBy,
      sort,
      availableItems
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
div.w-app {
  min-height: 10px ;
}

#SortButtonGroup {
  margin-left: 10px;
}

.w-radios {
  margin-left: 10px;
}
</style>
