<template>
  <w-app>
    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div>
      Sorting by Created:
      <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
      <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
    </div>
    <div v-show="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <div v-show="displayedItems.length == 0">
      There are <b>no history.</b>
    </div>
    <ul>
      <bans-item
                  v-for="item in displayedItems"
                  :key="item.BanId"
                  :id="item.BanId"
                  :active="item.Active"
                  :created="item.Created"
                  :expires="item.Expires"
                  :iPAddress="item.IPAddress"
                  :reason="item.Reason"
                  @revoke-ban="$emit('revoke-ban', $event)"
              ></bans-item>

    </ul>
  </w-app>
</template>

<script>
import { toRefs, onBeforeMount } from 'vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import BansItem from "./BansItem.vue";

export default {
  components: {
    BansItem
  },
  emits: ['revoke-ban'],
  props: ['items'],
  setup(props) {
    const { items } = toRefs(props);
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['Reason']
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        'Created'
    );

    onBeforeMount(()=>{
      sorting.value = 'desc';
    })

    return {
      enteredSearchTerm,
      updateSearch,
      displayedItems,
      sorting,
      sort,availableItems
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
