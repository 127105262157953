import { ref, computed } from 'vue';

export default function useFilter(items, filterProp) {
  const fields = ref(null);

  const filteredItems = computed(function() {
    if (!fields.value) {
      return items.value;
    }

    return items.value.filter(x => {
      return fields.value.includes(x[filterProp]+ "");
    })
  });

  return {
    filteredItems,
    filterFields: fields
  };
}
