<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <w-flex wrap class="text-left">
          <div class="xs4 pa1">
            <h2>Player Clan List</h2>
          </div>
        </w-flex>
        <w-flex>



        </w-flex>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Id</th>
                <th>Member Id</th>
                <th>MemberName</th>
                <th>Role</th>
                <th>State</th>
                <th>JoiunDate</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.rowKey" scope="row" :class="(item.status) ? '' : 'inactive'"
                valign="middle">
                <td class="text-center col-md-1">{{ index + 1 }}</td>
                <td>{{ (item.id) }}</td>
                <td><a :href='"/players/" + item.memberId'>{{ (item.memberId) }}</a> </td>
                <td>{{ (item.MemberName) }}</td>

                <td>
                  <MemberChangeRole :playeridInput="item.memberId" :leaderIdInput="leaderId"
                    :CurrentRoleIdInput="item.role" :ClanIdInput="uuid">
                  </MemberChangeRole>
                </td>
                <td>
                  <MemberChangeState :playeridInput="item.memberId" :leaderIdInput="leaderId"
                    :CurrentStateIdInput="item.state" :ClanIdInput="uuid">
                  </MemberChangeState>
                  
                </td>
                <td>{{ (item.joinedAt) }}</td>
                <td>
                  <w-button  class="px4"   bg-color="error" @click="OpenCloseDialogRemoveFromClan(item.id, item.id )">
                    Remove
                  </w-button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" class="text-left">
                  <AddMemeberToClan :ClanIdInput="clanId" :LeaderIdInput="leaderId"></AddMemeberToClan>
                </td>
              </tr>

            </tfoot>
          </table>

          <div class="col">
            <a :href='"/players/" + leaderId'>Clan LeaderId: {{ (leaderId) }}</a>
          </div>


        </div>
      </div>
    </div>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>


    <w-dialog v-model="dialogshowRemoveFromClan" title="Remove Player from clan" persistent :width="550">
      Are you sure to remove {{ playerName }} from this clan

      <template #actions>
        <div class="spacer" />
        <w-button @click="AllianceMemberKick(playerId)" bg-color="error" shadow>
          Kick Member
        </w-button>
        <w-button @click="dialogshowRemoveFromClan = false" bg-color="success">
          close
        </w-button>
      </template>
    </w-dialog>

  </w-app>

  <w-dialog id="centerAlignDialog" v-model="isLoadingMemberKick" title="Loading..." persistent :width="400">
    <div class="spinner-border text-primary" />
  </w-dialog>

  
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";
import { useRoute, useRouter } from 'vue-router';
import AddMemeberToClan from "../../components/clan/AddMemeberToClan.vue";
import MemberChangeRole from "../../components/clan/MemberChangeRole.vue";
import MemberChangeState from "../../components/clan/MemberChangeState.vue";

export default {
  name: "Clan",
  components: {
    AddMemeberToClan,
    MemberChangeRole,
    MemberChangeState
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    radioItems: [
      { label: 'Normal Memeber', value: 1 },
      { label: 'Applied Memeber', value: 2 }
    ]
  }),
  setup() {
    const isLoadingMemberKick = ref(false);
    const error = ref(null);
    const items = ref([]);
    const responsdata = ref();
    const route = useRoute();
    const dialogshowRemoveFromClan = ref(false);
    const playerId = ref("");
    const playerfabMasterId = ref("");
    const playerName = ref(false);
    const router = useRouter();
    const uuid = route.path.split("/").reverse()[0];

    const pfMasterId = ref("");
    const pfEntityId = ref("");
    const leaderId = ref("");
    const playfabId = ref("");
    const clanId = ref("");

    const DataLoad = () => {
      fetching("/api/Alliance/AllianceMembersList/" + uuid).then(data => {
        items.value = data.members;
      }).catch(e => {
        error.value = e.message || 'Failed to load the Clans, try later.';
      }).finally(() => {
        isLoadingMemberKick.value = false;
      });
    }
    DataLoad();
    onBeforeMount(() => {

    });


    const body = {
      "ClanId": uuid,
      "playerId": ""
    }

    fetching('/api/Alliance/AllianceGetbyId', { method: "POST", body: body }).then(data => {
      leaderId.value = data.alliance.leaderId;
      pfMasterId.value = data.alliance.pfMasterId;
      pfEntityId.value = data.alliance.pfEntityId;
      playfabId.value = data.alliance.leaderId;
      clanId.value = data.alliance.id;
    }).catch(e => {
      error.value = e.message || 'Failed to get the Clan, try later.';
    }).finally(() => {
      isLoadingMemberKick.value = false;
    });

    const handleError = () => {
      error.value = null;
    };

    const OpenCloseDialogRemoveFromClan = (id, name) => {
      dialogshowRemoveFromClan.value = !(dialogshowRemoveFromClan.value);
      playerId.value = id;
      playerName.value = name;
    };

    const AllianceMemberKick = (memberId) => {
      const body = {
        "leaderId": memberId,
        "memberId": memberId
      }
      console.log(body);
      isLoadingMemberKick.value = true;
      fetching('/api/Alliance/AllianceMemberKick', {
        method: "POST",
        body: body
      }).then(data => {
        isLoadingMemberKick.value = true;
        responsdata.value = data;
        error.value = responsdata.value.errorMessage || 'clan member removed successfully';
        isLoadingMemberKick.value = false;
        router.push('/clans/clansmemberList/' + uuid)
        OpenCloseDialogRemoveFromClan();
      }).catch(e => {
        error.value = e.message || 'Failed to create the Clan, try later.';
        isLoadingMemberKick.value = false;
      }).finally(() => {
        isLoadingMemberKick.value = false;
      });
    }

    return {
      items,  error, PRIVILEGES, dialogshowRemoveFromClan, AllianceMemberKick, handleError, uuid,
      OpenCloseDialogRemoveFromClan, playerId, playerName, playerfabMasterId, clanId,isLoadingMemberKick,
      pfMasterId, pfEntityId, leaderId, playfabId, DataLoad
    };
  },
};
</script>