<template>
  <w-app>
    <h1>Security</h1>
    <hr />
  </w-app>
</template>

<script>
export default {
  name: "Security",
};
</script>

<style scoped></style>
