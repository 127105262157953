<template>
  <w-select class="py1 pa3" :items="ClanRole.items" v-model="CurrentRoleId" @item-click="setNewRole(CurrentRoleId)">
  </w-select>

  <w-dialog v-model="dialogshowChangeClanRole" title="Change ClanMember Role" persistent :width="550">
    Are you sure to change clan role for PlayerId: <b>{{ playerIdInChangeRole }}</b>
    and leaderId <b>{{ leaderIdInChangeRole }} </b> to New role

    <template #actions>
      <div class="spacer" />
      <w-button @click="ApplyMemberChangeRole(playerIdInChangeRole, leaderIdInChangeRole, newRoleIdInChangeRole)"
        bg-color="error" shadow>
        Apply change clan member role
      </w-button>
      <w-button @click="dialogshowChangeClanRole = false" bg-color="success">
        close
      </w-button>


      <w-dialog id="centerAlignDialog" v-model="isLoadingMemberChange" title="Loading..." persistent :width="400">
        <div class="spinner-border text-primary" />
      </w-dialog>

      
    </template>
  </w-dialog>
</template>

<script>
import { ref } from 'vue';
import { fetching } from "../../config/context";

export default {
  name: "MemberChangeRole",
  props:
    ["playeridInput", "leaderIdInput", "CurrentRoleIdInput", "ClanIdInput"]
  ,
  setup: function (props) {
    
    const isLoadingMemberChange = ref(false);
    const error = ref(null);

    const playerIdInChangeRole = ref("");
    const leaderIdInChangeRole = ref("");
    const CurrentRoleId = ref("");
    const CurrentRoleName = ref("");
    const playerName = ref("");
    const newRoleId = ref("");
    const ClanId = ref("");

    const newRoleIdInChangeRole = ref("");
    const isCreate = ref(false);
    const dialogshowChangeClanRole = ref(false);
    const responsdata = ref();


    const OpenCloseDialogChangeClanRole = () => {
      dialogshowChangeClanRole.value = !(dialogshowChangeClanRole.value);
      playerIdInChangeRole.value = props.playeridInput;
      leaderIdInChangeRole.value = props.leaderIdInput;
      CurrentRoleId.value = props.CurrentRoleIdInput;
    };

    const setNewRole = (id) => {
      newRoleIdInChangeRole.value = id;
      OpenCloseDialogChangeClanRole();
    }


    const ClanRole = {
      items: [
        { label: 'None', value: 0 },
        { label: 'Leader', value: 1 },
        { label: 'Deputy', value: 2 },
        { label: 'Member', value: 3 },
      ]
    }

    const setValue = () => {
      CurrentRoleId.value = props.CurrentRoleIdInput;
      playerIdInChangeRole.value = props.playeridInput;
      leaderIdInChangeRole.value = props.leaderIdInput;
      CurrentRoleName.value = ClanRole.items[CurrentRoleId.value].label;
      ClanId.value = props.ClanIdInput;
    }
    setValue();

    const ApplyMemberChangeRole = () => {
      isLoadingMemberChange.value = true;
      const body = {
        "leaderId": leaderIdInChangeRole.value,
        "memberId": playerIdInChangeRole.value,
        "clanRoleId": newRoleIdInChangeRole.value
      }
    
      let api = '/api/Alliance/AllianceMemberRole';
      if (newRoleIdInChangeRole.value == 1) 
        api= '/api/Alliance/AllianceMemberPromoteLeader';
      
      fetching(api, {
        method: "POST",
        body: body
      }).then(data => {
        responsdata.value = data;
        error.value = responsdata.value.errorMessage || 'clan member removed successfully';
        isLoadingMemberChange.value = false;
        dialogshowChangeClanRole.value = false;
        CurrentRoleId.value = newRoleIdInChangeRole.value;
      }).catch(e => {
        error.value = e.message || 'Failed to create the Clan, try later.';
        isLoadingMemberChange.value = false;
      }).finally(() => {
        isLoadingMemberChange.value = false;
      });
    }

    return {
      isCreate, OpenCloseDialogChangeClanRole, ApplyMemberChangeRole, dialogshowChangeClanRole, playerIdInChangeRole,isLoadingMemberChange,
      ClanRole, CurrentRoleId, playerName, newRoleId, setNewRole, newRoleIdInChangeRole, CurrentRoleName, leaderIdInChangeRole
    }
  }
};
</script>


