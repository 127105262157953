import { createStore } from 'vuex';
import titleModule from './modules/title/index.js';
import authModule from './modules/auth/index.js';
import stringTableModule from './modules/stringTable/index.js';

const store = createStore({
    modules: {
        auth: authModule,
        title: titleModule,
        stringTable: stringTableModule
    }
});

export default store;