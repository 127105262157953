<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
<!--        <h2>User List</h2>-->
<!--        <w-form @submit.prevent="submitForm" v-model="formIsValid">-->
<!--        <div style="margin-top: 5px; alignment: right">-->
<!--          <w-input class="mt3" label="PlayFab ID" v-model.trim="playfabId"-->
<!--                   :validators="[validators.required]"></w-input>-->
<!--          <w-button type="submit" shadow>See</w-button>-->
<!--        </div>-->
<!--        <div style="margin-top: 5px;">-->
<!--          <w-button type="submit" shadow>See</w-button>-->
<!--        </div>-->
<!--        </w-form>-->

<!--        <h2>Recently searched players</h2>-->

        <h2>Search Players</h2>
        <div>
          <w-input class="mt3" label="Search (Player ID, Display Name)" v-model.trim="searchTerm" outline></w-input>
        </div>

        <div align="center" style="margin: 20px" v-show="isLoading">
          <h3>Loading ...</h3>
          <div class="spinner-border text-primary"/>
        </div>

        <div style="margin-top: 20px" v-show="items?.results">
          <div>Total: <b>{{ Number(items?.totalCount).toLocaleString() }}</b> players (Up to <b>2</b> minutes delayed data)</div>
          <div>Page: {{items?.currentPage}} / {{ Number(items?.totalPageCount).toLocaleString() }}</div>
          <ag-grid-vue :style="'width: 100%; height: ' + innerHeight + 'px'"
                       class="ag-theme-alpine"
                       :rowData="items?.results"
                       :singleClickEdit="false"
                       :columnDefs="columnDefs"
                       :defaultColDef="defaultColDef"
                       :pagination="true"
                       rowSelection="single"
                       :enableCellTextSelection="true"
                       :onRowSelected="onRowSelected"
          >
          </ag-grid-vue>
        </div>

<!--        <div v-show="items?.results" style="padding-top: 10px">-->
<!--          <nav aria-label="Page navigation ">-->
<!--            <ul class="pagination justify-content-center">-->
<!--              &lt;!&ndash; first &ndash;&gt;-->
<!--              <li class="page-item" :class="(items?.currentPage !== 1)? '': 'disabled'">-->
<!--                <a class="page-link" href="#" @click="goTo(1)">-->
<!--                  <span class="mdi mdi-skip-backward"></span>-->
<!--                </a>-->
<!--              </li>-->

<!--              &lt;!&ndash; prev &ndash;&gt;-->
<!--              <li class="page-item" :class="(startPage > 10)? '': 'disabled'">-->
<!--                <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click="goTo(startPage - 1)">-->
<!--                  <span class="mdi mdi-skip-previous"></span>-->
<!--                </a>-->
<!--              </li>-->

<!--              &lt;!&ndash; (1 .... n) pages &ndash;&gt;-->
<!--              <li class="page-item" v-for="i in range(startPage, endPage)" :key="i"-->
<!--                  :class="(i == items?.currentPage)? 'active' : '' ">-->
<!--                <a class="page-link" href="#"  @click="goTo(i)">{{ i }}</a>-->
<!--              </li>-->

<!--              &lt;!&ndash; next &ndash;&gt;-->
<!--              <li class="page-item" :class="(endPage < items?.totalPageCount)? '': 'disabled'">-->
<!--                <a class="page-link" href="#" @click="goTo(endPage + 1)"><span class="mdi mdi-skip-next"></span></a>-->
<!--              </li>-->

<!--              &lt;!&ndash; last &ndash;&gt;-->
<!--              <li class="page-item" :class="(endPage !== items?.totalPageCount)? '': 'disabled'">-->
<!--                <a class="page-link" href="#" @click="goTo(items?.totalPageCount)">-->
<!--                  <span class="mdi mdi-skip-forward"></span>-->
<!--                </a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </nav>-->
<!--        </div>-->
      </div>
    </div>
  </w-app>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import {reactive, ref, onBeforeMount, watch} from "vue";
import {useRouter} from 'vue-router'
import {fetching} from "../../config/context";
import moment from 'moment';
export default {
  name: "PlayerList",
  components: {
    AgGridVue
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const router = useRouter();
    const startPage = ref(0);
    const endPage = ref(0);
    const items = ref(null);
    const playfabId = ref("402517DCA10D278D");

    const formIsValid = ref((playfabId.value != null));
    const isLoading = ref(false);
    const error = ref(null);
    const searchTerm = ref(null);
    const page = ref(1);
    const innerHeight = ref(window.innerHeight - 275);

    const defaultColDef = ref({
      sortable: true,
      resizable: true
    });

    const columnDefs = reactive([
      { headerName: 'Player ID', field: 'playerId', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Display Name', field: 'displayName', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Created', field: 'created', sortable: true, filter: 'agDateColumnFilter' },
      { headerName: 'Created (Ago)', field: 'createdAgo', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Last Login', field: 'lastLogin', sortable: true, filter: 'agDateColumnFilter' },
      { headerName: 'Last Login (Ago)', field: 'lastLoginAgo', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'Location', field: 'location', sortable: true, filter: 'agTextColumnFilter' },
      { headerName: 'VTD', field: 'valuesToDate', sortable: true, filter: 'agTextColumnFilter' },
    ]);


    const submitForm = () => {
      // console.log("formIsValid:"+formIsValid.value);
      if (!formIsValid.value) {
        return
      }
      router.push("/players/" + playfabId.value); //todo: magic string

    }
    const validators =  {
      required: value => !!value || 'This field is required',
    }

    onBeforeMount(() => {
      searchUser('*');
    });

    watch(searchTerm, function(newValue) {

      setTimeout(() => {
        if (newValue === searchTerm.value) {
          searchUser(newValue);
          page.value = 1;
        }
      }, 300);
    });

    const searchUser = function (query) {

      if(query === undefined) query = "*";

      items.value = null;
      isLoading.value = true;
      let api = "/api/players?";
      if (query !== "*"){
        api += "&search=" + query
      } else{
        api = "/api/players/cached?";
      }

      if (page.value !== ""){
        api += "&page=" + page.value
      }

      api += "&pageSize=2147483647";// + Number.MAX_SAFE_INTEGER;

      fetching(api).then( data  => {
        items.value = data;

        startPage.value = parseInt(Math.floor((data.currentPage -1) / 10) + "1");
        endPage.value = Math.ceil(data.currentPage / 10) * 10;

        if (data.totalPageCount < endPage.value) {
          endPage.value = Math.min(endPage.value, data.totalPageCount);
        }

        data.results.map(x => x.location = x.continentCode + " / " + x.countryCode + " / "  + x.city);
        data.results.map(x => x.valuesToDate = (x.valuesToDate.length == 2) ? "" : x.valuesToDate/100);

        data.results.map(x => x.lastLoginAgo = moment(x.lastLogin).fromNow());
        data.results.map(x => x.createdAgo = moment(x.created).fromNow());

      }).catch(e => {
        error.value = e.message || 'Failed to load the list of Players, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i);

    const goTo = function (p){
      page.value = p;
      searchUser();
    }

    const onRowSelected  = function (e){
      // router.push(`/players/${e.data.playerId}`);
      window.open(`/players/${e.data.playerId}`);
    }

    return {
      playfabId, searchTerm, items, page, columnDefs, innerHeight, defaultColDef,
      formIsValid, isLoading, error, validators, startPage, endPage,
      submitForm, searchUser, goTo, range, onRowSelected
    }
  }
};
</script>
