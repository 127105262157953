<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="SelectSegment"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Select Segment
    </button>
    <ul class="dropdown-menu">
      <li
        class="list-group-item"
        v-for="item in DynamicSegmentItem"
        :key="item.id"
      >
        <button class="dropdown-item" @click="SetSegmnet(item.id)">
          {{ item.cronDescription }}
        </button>
      </li>
    </ul>
  </div>
  <div class="row pa4">
    {{ SegmentName }}
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { fetching } from "../../config/context";
import {
  ActivePlayerArrayList,
  progress,
  totalExecutionCount,
} from "../../store/modules/active-players/active-users.js";

export default {
  name: "PlayerInSegment",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const SegmentId = ref(0);
    const SegmentName = ref("");
    const CurrentPlayerId = ref(0);
    const DynamicSegmentItem = ref();
    const SegmentByName = ref("");

    const isLoading = ref(false);
    const error = ref(null);

    const innerHeight = ref(window.innerHeight - 275);

    function SetSegmnet(id) {
      SegmentId.value = id;
      SegmentName.value = DynamicSegmentItem.value.find(
        (x) => x.id === id
      ).cronDescription;
      LoadSegmentByNameAPI();
      searchUser("");
    }

    const validators = {
      required: (value) => !!value || "This field is required",
    };

    onBeforeMount(() => {
      searchUser("");
      LoadSegmentByNameAPI();
    });

    const LoadSegmentByNameAPI = function() {
      const api = `/api/dynamic_segment/SegmentByName?SegmentByName=${SegmentByName.value}`;

      fetching(api)
        .then((data) => {
          DynamicSegmentItem.value = data;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the list of Players, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const searchUser = function(serchText) {
      if (serchText === undefined) serchText = "";

      ActivePlayerArrayList.value = null;
      isLoading.value = true;

      const api = `/api/dynamic_segment/PlayerList/${SegmentId.value}?serchText=${serchText}&pageNumber=0&pageSize=1000000`;

      fetching(api)
        .then((data) => {
          ActivePlayerArrayList.value = data.results.map(
            (player) => player.playerId
          );
          totalExecutionCount.value = ActivePlayerArrayList.value.length;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the list of Players, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      innerHeight,
      isLoading,
      error,
      validators,
      searchUser,
      SegmentId,
      DynamicSegmentItem,
      SegmentByName,
      SetSegmnet,
      SegmentName,
      CurrentPlayerId,
      progress,
      totalExecutionCount,
    };
  },
};
</script>
<style scoped>
li {
  display: inline-block;
  margin: 0 1px;
}

textarea {
  resize: none;
  border: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -10;
}
</style>
