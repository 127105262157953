<template>
  <w-app>
      <div>
        <h5>Events Guide</h5>
        <ul>
          <li>
            <a href="https://stratospheregames.atlassian.net/l/cp/UyprbgW0" target="_blank">Confluence</a>
          </li>
          <li>
            <a href="https://stratospheregames-my.sharepoint.com/:x:/g/personal/ian_kapischke_stratosphere-games_com/EaiCjeLjcy5CmKIWo_s9tk0BNMMUFXX3my4cBt1ZOKwNyQ?e=KP81Dt" target="_blank">Google Sheets</a>
          </li>
        </ul>
        <hr>
      </div>


      <div>
        <h5>Events Overview</h5>
        <div class="grid">
          <div v-for="[event, number] in Object.entries(events).sort(([,a],[,b]) => b - a)" :key="event">
            <b>{{event}}</b> : {{numberFormatting(number)}}
          </div>
        </div>
      </div>
      <hr>

      <div>
        Total : <b>{{numberFormatting(totalCount)}}</b> events ( <b>{{currentPage}}</b> / {{totalPageSize}} pages)
      </div>
      <table class="table table-bordered table-hover table-striped">
        <thead class="thead-dark">
          <tr class="table-active">
            <th scope="col" class="text-center">#</th>
            <th v-for="key in columns" :key="key" scope="col" class="text-center">{{key}}</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in items" :key="item.EVENTID" valign="middle">
          <th scope="row">{{ numberFormatting((totalCount - (currentPage - 1) * pageSize) - index) }}</th>
          <td v-for="key in columns" :key="key" scope="row">
            <a href="javascript:void(0);" @click="popupDetail(item)" :alt="item.id" v-if="key === 'EVENTNAME'">{{item[key]}}</a>
            <span v-else>{{item[key]}}</span>
          </td>
        </tr>
        <tr v-show="items.length === 0">
          <td :colspan="columns.length + 1" class="text-center">No Events</td>
        </tr>
        </tbody>
      </table>

      <div v-show="items" style="padding-top: 10px">
        <nav aria-label="Page navigation ">
          <ul class="pagination justify-content-center">
            <!-- first -->
            <li class="page-item" :class="(currentPage !== 1)? '': 'disabled'">
              <a class="page-link" href="#" @click="goTo(1)">
                <span class="mdi mdi-skip-backward"></span>
              </a>
            </li>

            <!-- prev -->
            <li class="page-item" :class="(startPage > 10)? '': 'disabled'">
              <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click="goTo(startPage - 1)">
                <span class="mdi mdi-skip-previous"></span>
              </a>
            </li>

            <!-- (1 .... n) pages -->
            <li class="page-item" v-for="i in range(startPage, endPage)" :key="i"
                :class="(i === currentPage)? 'active' : '' ">
              <a class="page-link" href="#"  @click="goTo(i)">{{ i }}</a>
            </li>

            <!-- next -->
            <li class="page-item" :class="(endPage < totalPageSize)? '': 'disabled'">
              <a class="page-link" href="#" @click="goTo(endPage + 1)"><span class="mdi mdi-skip-next"></span></a>
            </li>

            <!-- last -->
            <li class="page-item" :class="(endPage !== totalPageSize)? '': 'disabled'">
              <a class="page-link" href="#" @click="goTo(totalPageSize)">
                <span class="mdi mdi-skip-forward"></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

    <w-dialog
        v-model="popupJson"
        transition="bounce"
        :fullscreen="true"
    >
      <div class="row" style="margin-top: 60px">
        <w-flex justify-end>
          <w-button bg-color="error" @click="closePopup" shadow>Close</w-button>
        </w-flex>
      </div>

      <div class="row">
        <json-viewer
            :value="popupJson"
            :expand-depth=10
        />
      </div>
      <div class="row">
        <w-flex justify-end>
          <w-button bg-color="error" @click="closePopup" shadow>Close</w-button>
        </w-flex>
      </div>
    </w-dialog>
  </w-app>

  <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting... (It takes a quite some time.)" persistent :width="400">
    <div class="spinner-border text-primary"/>
  </w-dialog>

  <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
    {{ error }}
    <w-flex justify-end>
      <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
    </w-flex>
  </w-dialog>
</template>
<script>
import {ref, onBeforeMount} from 'vue';
import {deltaDNAUrl} from "../../config/context";

export default {
  components: {
  },
  props: [
    'playerId', "getItemFullName"
  ],
  setup(prop) {
    const error = ref(null);
    const isLoading = ref(false);
    const columns = ["EVENTNAME","PLATFORM","ITEMNAME","TRANSACTIONNAME","TRANSACTIONTYPE","SDKVERSION","GEARSCORE","DEVICETIMESTAMP"];

    const startPage = ref(0);
    const endPage = ref(0);

    const header = ref([]);
    const events = ref({});
    const items = ref([]);
    const currentPage = ref(0);
    const rowsSize = ref(0);
    const pageSize = ref(0);
    const totalCount = ref(0);
    const totalPageSize = ref(0);

    const popupJson = ref(null);

    const getDeltaDNAItems = (page) => {
      isLoading.value = true;

      const init = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      };

      fetch(deltaDNAUrl(prop.playerId, page), init).then( async response  => {
        const data = await response.json();
        if (data.error !== undefined) {
          error.value = data.error;
          return;
        }

        items.value = data.items;
        events.value = data.events;
        currentPage.value = parseInt(data.page);
        rowsSize.value = parseInt(data.rowsSize);
        totalCount.value = parseInt(data.totalCount);
        totalPageSize.value = parseInt(data.totalPageSize);
        pageSize.value = parseInt(data.pageSize);

        startPage.value = parseInt(Math.floor((data.page -1) / 10) + "1");
        endPage.value = Math.ceil(data.page / 10) * 10;

        if (data.items.length > 0) {
          header.value = Object.keys(data.items[0]);
        }

        if (data.totalPageSize < endPage.value) {
          endPage.value = Math.min(endPage.value, data.totalPageSize);
        }

      }).catch(e => {
        console.log(e);
        error.value = e.message || 'Failed to load the item from deltaDNA, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    };

    onBeforeMount(() => {
      getDeltaDNAItems(1);
    });

    const nf = Intl.NumberFormat();
    const numberFormatting = (x) => {
      return nf.format(x);
    }

    const goTo = function (p){
      getDeltaDNAItems(p);
    }

    const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i);

    const handleError = () => {
      error.value = null;
    }

    const closePopup = () => {
      popupJson.value = null;
    }


    function popupDetail(json){
      popupJson.value = json;
    }

    return {
      isLoading, error, items, events, currentPage, rowsSize, totalCount, totalPageSize, pageSize,
      startPage, endPage, header, columns, popupJson,
      handleError, goTo, range, numberFormatting, popupDetail, closePopup
    };
  }
};
</script>
<style>
.grid {
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
}
</style>