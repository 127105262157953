<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Groups {{(isCreate)? "Create": "Update"}}</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
          <table class="table table-hover">
            <thead>
            <tr>
              <th colspan="2"></th>
            </tr>
            </thead>
            <tbody>
            <tr valign="middle">
              <th class="text-center">Name</th>
              <td>
                <w-input class="mt3" label="Name" outline static-label v-model.trim="name"
                         :validators="[validators.required]"/>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Description</th>
              <td>
                <w-textarea outline data-gramm="false" v-model.trim="desc" :validators="[validators.required]"></w-textarea>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Player List</th>
              <td>
                  <w-textarea outline v-model="rawPlayers" data-gramm="false" :validators="[validators.required, validators.max_1000_player]" :rows="numberOfPlayers"/>
                  <span class="xs4" v-show="filteredPlayers">
                    Selected <b>{{filteredPlayers?.length}}</b> playeres
                  </span>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center"><small>Delimiter</small></th>
              <td>
                <w-input v-model="delimiter" outline size="1"></w-input>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="2" class="text-right">
                <w-button bg-color="error" shadow @click="deleteGroup" v-privilege="PRIVILEGES.GROUP_WRITE" v-show="!isCreate">delete</w-button>
                <w-button bg-color="info" shadow type="submit" v-privilege="PRIVILEGES.GROUP_WRITE">
                  {{(isCreate)? "Submit": "Update"}}
                </w-button>
              </td>
            </tr>
            </tfoot>
          </table>
        </w-form>
      </div>
    </div>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

  </w-app>
</template>

<script>
import {fetching, ROLES} from "../../config/context";
import {ref, onBeforeMount, watch} from "vue";
import {useRoute, useRouter} from 'vue-router'
import {PRIVILEGES} from "../../config/context";
export default {
  name: "GroupsForm",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup: function () {
    const route = useRoute();
    const router = useRouter();

    const uuid = ref(null);
    const isCreate = ref(false);

    const name = ref("");
    const desc = ref("");

    const rawPlayers = ref(null);
    const filteredPlayers = ref(null);
    const numberOfPlayers = ref(0);

    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const delimiter = ref('\n');

    const validators = {
      required: value => !!value || 'This field is required',
      max_1000_player: value => (value.split(delimiter.value).filter(x => x.trim() !== "").length <= 2000) || 'Groups can be created with a maximum of 2,000 people',
    }

    let apiUri = '/api/group/players';

    const deleteGroup = () => {

      if(confirm('Are you sure?\nDo you really want to delete "' + name.value + '"?')){
        isLoading.value = true;

        fetching(apiUri + '/' + uuid.value, {
          method: 'DELETE',
        }).then( () => {
          router.push("/groups"); //todo: magic string
        }).catch(e => {
          error.value = e.message || 'Failed to delete the Group, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
      }
    }

    const submitForm = () => {
      if (!formIsValid.value) {
        return
      }

      isLoading.value = true;

      let method = "POST";
      const body = {
        "name": name.value,
        "desc": desc.value,
        "players": filteredPlayers.value
      }

      if (!isCreate.value) {
        method = "PUT";
        body.id = uuid.value;
      }

      fetching(apiUri, {
        method: method,
        body: body
      }).then(() => {
        router.push("/groups"); //todo: magic string
      }).catch(e => {
        error.value = e.message || 'Failed to create the Group, try later.';
        isLoading.value = false;
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const handleError = () => {
      error.value = null;
    };

    watch([rawPlayers, delimiter], function () {
      if (delimiter.value === "") {
        delimiter.value = "\n";
      }
      setTimeout(() => {
        filteredPlayers.value = rawPlayers.value.split(delimiter.value).filter(x => x.trim() !== "")
        numberOfPlayers.value = filteredPlayers.value.length;
      }, 300);
    });

    onBeforeMount(() => {

      if (route.path.endsWith("new")) {
        isCreate.value = true;
        return;
      }

      uuid.value = route.path.split("/").reverse()[0];

      isLoading.value = true;
      fetching(apiUri + "/" + uuid.value).then(data => {
        name.value = data.name;
        desc.value = data.desc;
        rawPlayers.value = data.players.toString().replaceAll(",", delimiter.value);

        numberOfPlayers.value =  data.players.length + 1;
      }).catch(e => {
        error.value = e.message || 'Failed to get the Group, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });

    return {
      uuid, name, desc, formIsValid, filteredPlayers, rawPlayers, numberOfPlayers,
      isLoading, error, validators, isCreate, delimiter,
      submitForm, handleError, deleteGroup,
      PRIVILEGES, ROLES
    }
  }
};
</script>
