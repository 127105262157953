import {fetching} from "../../../config/context";

export default {
    async downloadIfNotExist(context) {
        if ("stringData" in localStorage) {
            await context.dispatch("load")
        } else {
            await context.dispatch("download", context)
        }
    },
    async download(context) {
        await fetching('/api/stringTable').then(data => {
            if (Object.keys(data).includes("StatusCode")){
                return;
            }

            const dictData = {};
            data.forEach(x => dictData[x.UniqueId] = x.Text)

            localStorage.setItem("stringData", JSON.stringify(dictData));
            context.commit('setStringData', dictData);
        }).catch(e => {
            const error = new Error(
                e.message || 'Failed to get the string data.'
            );
            throw error;
        });
    },

    load(context) {
        const stringData = JSON.parse(localStorage.getItem("stringData"));
        context.commit("setStringData", stringData)
    }
};
