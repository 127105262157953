<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>WebView/Menu Banner List</h2>
        <div class="text-left">
          It will take up to <b>10 minutes</b> to update the contents from CDN
        </div>

        <div style="margin-top: 20px" v-show="items">
          <span class="inactivated">&nbsp;</span> Inactivated
          <br />
          <span class="outdated" style="width: 100px">&nbsp;</span> Outdated
          <div style="justify-content: space-between; display: flex">
            <div>
              <w-radios
                v-model="itemLanguageRadio"
                :items="itemLanguageClasses"
                inline
                color="blue"
              >
                <template #item="{ item }">
                  <span> {{ item.label }}</span>
                </template>
              </w-radios>
            </div>
            <div>
              <w-button
                bg-color="info"
                :onclick="goLive"
                :disabled="itemLanguageRadio === ''"
                >Go to see the current banner</w-button
              >
            </div>
          </div>

          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th style="width: 100px">Image</th>
                <th>Name</th>
                <th>Start</th>
                <th>End</th>
                <th>Status</th>
                <th>Priority</th>
                <th>Language</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in filteredItems" :key="item.id">
                <tr
                  scope="row"
                  :class="[
                    item.active ? '' : 'inactivated',
                    item.start < Date.now() && item.end < Date.now()
                      ? 'outdated'
                      : '',
                  ]"
                  valign="middle"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td scope="row">
                    <img :src="item.url" style="width: 200px" />
                  </td>
                  <td scope="row">
                    <a href="#" @click="editBanner(item.id)">{{
                      item.title
                    }}</a>
                  </td>
                  <td class="text-center">
                    {{ new Date(item.start).toLocaleString() }}
                    <br />
                    <span v-ago="new Date(item.start)" />
                  </td>
                  <td class="text-center">
                    {{ new Date(item.end).toLocaleString() }}
                    <br />
                    <span v-ago="new Date(item.end)" />
                  </td>
                  <td
                    class="text-center"
                    :class="item.active ? 'activeText' : 'inactivatedText'"
                  >
                    {{ item.active }}
                  </td>
                  <td class="text-center">{{ item.priority }}</td>
                  <td class="text-center">
                    {{
                      item.language === sharedLanguage ? "All" : item.language
                    }}
                  </td>
                  <td class="text-center">
                    <w-button
                      bg-color="error"
                      shadow
                      @click="deleteBanner(item)"
                      v-privilege="PRIVILEGES.WEBVIEW_WRITE"
                      >delete</w-button
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8" class="text-right">
                  <w-button
                    route="/webview/banner/form/new"
                    shadow
                    v-privilege="PRIVILEGES.WEBVIEW_WRITE"
                    >Add New WebView Banner</w-button
                  >
                </td>
                <td colspan="9" class="text-right">
                  <w-button
                    route="/webview/menu-banner/form/new"
                    shadow
                    v-privilege="PRIVILEGES.WEBVIEW_WRITE"
                    >Add New Menu Banner</w-button
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount, watch } from "vue";
import { fetching } from "../../config/context";
import { useRouter } from "vue-router";
import { PRIVILEGES } from "../../config/context";
import useFilter from "../../hooks/filter";

export default {
  name: "BannerList",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const sharedLanguage = "**";
    const router = useRouter();
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);

    const itemLanguageRadio = ref("");
    const itemLanguageClasses = ref(null);

    onBeforeMount(() => {
      fetching("/api/webview/banner")
        .then((data) => {
          items.value = data;

          resetLanguageFields();
          updateCategoryClasses();
        })
        .catch((e) => {
          error.value = e.message || "Failed to load the data, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const updateCategoryClasses = () => {
      const key = "language";
      const groupByLanguaeCount = items.value.groupBy(key);
      const groupByKeys = Object.keys(groupByLanguaeCount);
      const sortedKeys = [...groupByKeys].sort(
        (a, b) => groupByLanguaeCount[b] - groupByLanguaeCount[a]
      );
      const radioItems = sortedKeys.map((x) => {
        const name = x === sharedLanguage ? "Common" : x;
        return { label: name + " (" + groupByLanguaeCount[x] + ")", value: x };
      });
      radioItems.unshift({
        label: `All ${key} (${items.value.length})`,
        value: "",
      });
      itemLanguageClasses.value = radioItems;
    };

    const { filteredItems, filterFields } = useFilter(items, "language");

    const resetLanguageFields = () => {
      const groupByLanguage = items.value.groupBy("language");
      filterFields.value = Object.keys(groupByLanguage);
    };

    watch(itemLanguageRadio, function() {
      if (itemLanguageRadio.value === "") {
        resetLanguageFields();
        return;
      }
      filterFields.value = [itemLanguageRadio.value, sharedLanguage];
    });

    const deleteBanner = (item) => {
      if (
        confirm(
          'Are you sure?\nDo you really want to delete "' + item.title + '"?'
        )
      ) {
        isLoading.value = true;

        fetching("/api/webview/banner?idx=" + item.id, {
          method: "DELETE",
        })
          .then(() => {
            let obj = JSON.parse(JSON.stringify(items));
            items.value = obj._rawValue.filter(function(i) {
              return i.id != item.id;
            });
          })
          .catch((e) => {
            error.value = e.message || "Failed to delete the data, try later.";
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    const editBanner = async (uuid) => {
      await router.push("/webview/banner/form/" + uuid);
    };

    const handleError = () => {
      error.value = null;
    };

    const goLive = () => {
      const stage = window.location.hostname
        .split(".")[0]
        .replace("hw-", "")
        .replace("localhost", "dev");
      const url = `https://nimbus-webview-banner-${stage}.azureedge.net/index.html`;
      // window.open(url + '?_dummy='+ Math.random())
      window.open(
        `${url}?_dummy=${Math.random()}&lang=${itemLanguageRadio.value}`
      );
    };
    return {
      items,
      isLoading,
      error,
      PRIVILEGES,
      itemLanguageClasses,
      itemLanguageRadio,
      filteredItems,
      goLive,
      handleError,
      editBanner,
      deleteBanner,
      sharedLanguage,
    };
  },
};
</script>
<style scoped>
.inactivated {
  background-color: lightpink !important;
}

.outdated {
  background-color: lightsalmon;
}

td.activeText {
  color: green;
}
td.inactivatedText {
  color: orangered;
}
</style>
