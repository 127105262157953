<template>
  <w-breadcrumbs :items="breadcrumbs" />
  <w-app>
    <header>
      <w-flex wrap gap="2">
        <w-input
          type="text"
          v-model="ItemName"
          placeholder="Name..."
          class="w-input"
        />
        <w-input
          type="text"
          v-model="ItemId"
          placeholder="Id..."
          class="w-input"
        />

        <w-input
          type="text"
          v-model="ItemDescription"
          placeholder="Description..."
          class="w-input"
        />

        <w-input
          type="text"
          v-model="ItemClass"
          placeholder="Class..."
          class="w-input"
        />
        <w-flex justify="left">
          <w-button class="ma1" width="100px" @click="PreviosPage()"
            >Previous Page
          </w-button>
          <w-button class="ma1" width="100px" @click="NextPage()"
            >Next Page
          </w-button>
          <w-select
            :items="itemsPerPageList"
            v-model="itemsPerPage"
            width="20px"
          ></w-select>
        </w-flex>
      </w-flex>
    </header>
    <w-flex grow>
      <main class="grow">
        <div class="container">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Item Id</th>
                <th>Item Name</th>
                <th>Item Class</th>
                <th>Consumable</th>
                <th>Tags</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item">
                <td>{{ item.ItemId }}</td>
                <td>{{ item.ItemName }}</td>
                <td>{{ item.ItemClass }}</td>
                <td>
                  {{ item.Consumable == null ? "Durable" : "Consumable" }}
                </td>
                <td>
                  <w-flex wrap>
                    <div v-for="tag in item.Tags" :key="tag">
                      <w-tag> {{ tag }}</w-tag>
                    </div>
                  </w-flex>
                </td>
                <td>
                  <w-tooltip lg top align-right color="white" bg-color="blue">
                    <template #activator="{ on }">
                      <w-button
                        v-on="on"
                        @click="ShowDiscription = true"
                        class="ma1"
                        color="primary"
                        text
                        md
                        >{{ item.shortDescription }}</w-button
                      >
                    </template>
                    <span>{{ item.ItemDescription }}</span>
                  </w-tooltip>
                </td>
                <td>
                  <w-button
                    :disabled="item.Grantable"
                    icon="wi-check"
                    @click="AddToItemSelect(item.ItemId)"
                  >
                  </w-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
      <aside>
        <w-button
          class="ma1"
          bg-color="success"
          @click="submit"
          v-privilege="PRIVILEGES.PLAYER_WRITE"
          >Give Item To player(s)</w-button
        >
        <w-checkbox class="pa2" v-model="ShowMessageConfig">
          With message
        </w-checkbox>

        <div v-show="ShowMessageConfig">
          <w-input
            v-model="MessageTitle"
            placeholder="Message Title"
            class="pa1"
          />
          <w-textarea
            placeholder="Message Body"
            outline
            v-model="MessageBody"
          />
          <w-input v-model="Reson" placeholder="Reason" class="pa1" />
        </div>

        <div class="xs2 pa1">
          <div v-for="(value, key) in selectItems" :key="key">
            <w-tag class="mr4 pa1" round lg>
              <b class="pa1">{{ value }} </b>
              <div class="spacer"></div>
              <w-button
                class="ma1"
                v-show="ShowMessageConfig"
                color="primary"
                text
                xs
                @click="ShowChangeCountDialogFunc(key)"
                >{{ selectItemsCount[key] }}</w-button
              >

              <w-icon class="pa2" color="red" @click="RemoveItemSelect(key)"
                >wi-cross</w-icon
              >
            </w-tag>
          </div>
        </div>

        <PlayerSearch></PlayerSearch>

        <w-textarea
          class="pa1"
          placeholder="Player Ids Separated by Comma"
          outline
          v-model="PlayerIdSepratedByComma"
        />

        <PlayerInSegment></PlayerInSegment>

        <w-tag class="mr4 pa1" round lg @click="showActivePlayerDialog = true">
          {{ totalExecutionCount }} Players
        </w-tag>
      </aside>
    </w-flex>
    <footer>
      nothing
    </footer>

    <w-dialog
      v-model="errorNotification"
      title="Player Id with error"
      transition="bounce"
      :width="850"
    >
      {{ errorNotification }}

      <w-flex wrap>
        <div v-for="errorUser in errorUsers" :key="errorUser">
          <a :href="'/players/' + value" target="_blank">{{ errorUser }}</a
          >,
        </div>
      </w-flex>

      <w-flex justify-end>
        <w-button bg-color="error" @click="errorNotification = null" shadow
          >Close</w-button
        >
      </w-flex>
    </w-dialog>

    <w-dialog
      v-model="isSending"
      :title="
        'Giving the items ... (' +
          executionCount +
          '/' +
          totalExecutionCount +
          ')'
      "
      persistent
      :width="400"
      align="center"
    >
      <div>
        <w-progress
          class="mt-4"
          v-model="progress"
          size="1.5em"
          outline
          round
          stripes
        >
          {{ progress }}%
        </w-progress>
      </div>
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      v-model="showChangeCountDialog"
      title="Change the amount of items"
      persistent
      :width="300"
    >
      Change amount
      <input
        class="ml2"
        v-model="selectItemsCount[active_item_index]"
        type="number"
        step="1"
        min="0"
      />

      <template #actions>
        <div class="spacer" />
        <w-button
          class="mr2"
          @click="showChangeCountDialog = false"
          bg-color="error"
        >
          OK
        </w-button>
      </template>
    </w-dialog>

    <w-dialog
      v-model="showActivePlayerDialog"
      title="Active Player"
      transition="bounce"
      :width="850"
    >
      <div>
        <w-flex wrap>
          <div v-for="value in ActivePlayerArrayList" :key="value">
            <a :href="'/players/' + value" target="_blank">{{ value }}</a
            >,
          </div>
        </w-flex>
      </div>

      <w-flex justify-end>
        <w-button
          bg-color="error"
          @click="showActivePlayerDialog = false"
          shadow
          >Close</w-button
        >
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { fetching } from "../../config/context";
import PlayerSearch from "../../components/player/PlayerSearch.vue";
import { useRoute } from "vue-router";
import { PRIVILEGES } from "../../config/context";
import { getItemThumbnail } from "../../hooks/item.js";
import PlayerInSegment from "../../components/player/PlayerInSegment.vue";
import {
  ActivePlayerArrayList,
  executionCount,
  totalExecutionCount,
  progress,
  MakePrograss,
} from "../../store/modules/active-players/active-users.js";

export default {
  name: "GiveItemToPlayer",
  components: { PlayerSearch, PlayerInSegment },

  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const errorUsers = ref([]);
    const PlayerIdSepratedByComma = ref("");

    const items = ref([]);
    const itemClasses = ref([]);
    const error = ref("");
    const ItemClass = ref("");
    const ItemName = ref("");
    const ItemDescription = ref("");
    const ItemId = ref("");

    const showNotification = ref("");
    const errorNotification = ref("");
    const MessageTitle = ref("");
    const MessageBody = ref("");
    const Reson = ref("");
    const ShowMessageConfig = ref(false);
    const ShowDiscription = ref(false);

    const ItemPerPageList = ref([]);
    const isLoading = ref(true);
    const isSending = ref(false);
    const showActivePlayerDialog = ref(false);

    const currentPage = ref(1);
    const itemsPerPage = ref(50);
    const totalItems = ref(100);
    const selectItems = ref([]);
    const selectItemsCount = ref([]);
    const active_item_index = ref(-1);
    const route = useRoute();
    const showChangeCountDialog = ref(false);

    const itemsPerPageList = ref([
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "25", value: 25 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ]);

    onMounted(() => {
      if (route.query.playerId)
        ActivePlayerArrayList.value[0] = route.query.playerId;
    });

    const ShowChangeCountDialogFunc = (index) => {
      showChangeCountDialog.value = true;
      active_item_index.value = index;
    };

    const handleError = () => {
      error.value = "";
    };
    const AddToItemSelect = (item) => {
      selectItems.value.push(item);
      selectItemsCount.value.push(1);
    };

    const RemoveItemSelect = (index) => {
      selectItems.value.splice(index, 1);
      selectItemsCount.value.splice(index, 1);
    };

    const NextPage = () => {
      currentPage.value = currentPage.value + 1;
      if (currentPage.value >= totalItems.value / itemsPerPage.value) {
        currentPage.value = currentPage.value - 1;
      }
      LoadcatalogItemsV2();
    };

    const PreviosPage = () => {
      currentPage.value = currentPage.value - 1;
      if (currentPage.value <= 0) {
        currentPage.value = 1;
      }
      LoadcatalogItemsV2();
    };

    const LoadcatalogItemsV2 = () => {
      fetching(
        `/api/playfab/catalogItemsV2?page=${currentPage.value}&pageSize=${itemsPerPage.value}&ItemId=${ItemId.value}&ItemDescription=${ItemDescription.value}&ItemName=${ItemName.value}&ItemClass=${ItemClass.value}`,
        {
          method: "GET",
        }
      )
        .then((data) => {
          totalItems.value = data.TotalCount;
          items.value = data.Items;
          items.value = data.Items.map((item) => ({
            ...item,
            shortDescription: item.ItemDescription.substring(0, 15) + "...", // New field
          }));
        })
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const LoadCatalogItemClass = () => {
      fetching("/api/playfab/CatalogItemClass", {
        method: "GET",
      })
        .then((data) => {
          itemClasses.value = data;
        })
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    watch(ItemClass, function(NewItemClass) {
      setTimeout(() => {
        if (NewItemClass === ItemClass.value) {
          LoadcatalogItemsV2();
          currentPage.value = 1;
        }
      }, 300);
    });
    watch(ItemId, function(NewItemId) {
      setTimeout(() => {
        if (NewItemId === ItemId.value) {
          LoadcatalogItemsV2();
          currentPage.value = 1;
        }
      }, 300);
    });
    watch(ItemName, function(NewItemName) {
      setTimeout(() => {
        if (NewItemName === ItemName.value) {
          LoadcatalogItemsV2();
          currentPage.value = 1;
        }
      }, 300);
    });

    watch(ItemDescription, function(NewItemDescription) {
      setTimeout(() => {
        if (NewItemDescription === ItemDescription.value) {
          LoadcatalogItemsV2();
          currentPage.value = 1;
        }
      }, 300);
    });

    watch(PlayerIdSepratedByComma, function(newValue) {
      setTimeout(() => {
        if (newValue === PlayerIdSepratedByComma.value) {
          ActivePlayerArrayList.value = PlayerIdSepratedByComma.value.split(
            ","
          );
        }
      }, 30);
    });

    watch(ShowMessageConfig, function(newValue) {
      {
        if (newValue === false) {
          MessageTitle.value = "";
          MessageBody.value = "";
          Reson.value = "";
        }
      }
    });

    const changePage = (page) => {
      this.currentPage = page;
    };

    const validation = () => {
      error.value = "";
      if (ActivePlayerArrayList.value.length === 0) {
        error.value = "Please enter player id";
      }

      if (selectItems.value.length === 0) {
        error.value = "Please select item";
      }

      if (ShowMessageConfig.value === true && Reson.value === "") {
        error.value = "Reson is required.";
      }
      if (ShowMessageConfig.value === true && MessageBody.value === "") {
        error.value = "Message body is required.";
      }

      if (ShowMessageConfig.value === true && MessageTitle.value === "") {
        error.value = "Message Title is required.";
      }

      if (error.value !== "") {
        return false;
      } else return true;
    };

    const submit = () => {
      if (validation() == true) {
        const player = [...ActivePlayerArrayList.value];
        const rewards = [...selectItems.value];

        player.forEach((playerId) => {
          try {
            isSending.value = true;
            executionCount.value = 0;
            totalExecutionCount.value = ActivePlayerArrayList.value.length;

            let body = {
              header: MessageTitle.value,
              body: MessageBody.value,
              itemIds: rewards,
              reason: Reson.value,
            };

            let api = "/api/profile/" + playerId + "/items/grant";

            if (ShowMessageConfig.value === true) {
              api = "/api/profile/" + playerId + "/mailbox/rewards";
              body = {
                header: MessageTitle.value,
                body: MessageBody.value,
                rewards: {},
                reason: Reson.value,
              };
              rewards.forEach((item, index) => {
                body["rewards"][item] = selectItemsCount.value[index];
              });
            }

            fetching(api, { method: "POST", body: body })
              .then(() => {
                window.scrollTo(0, 0);
              })
              .catch(() => {
                errorUsers.value.push(playerId);
              })
              .finally(() => {
                executionCount.value = parseInt(executionCount.value) + 1;
                MakePrograss();
                if (
                  ActivePlayerArrayList.value.length ===
                  parseInt(executionCount.value)
                ) {
                  isSending.value = false;

                  if (errorUsers.value.length === 0) {
                    showNotification.value = "Success!";
                  } else {
                    errorNotification.value =
                      errorUsers.value.length + " Error!";
                  }
                }
              });
          } catch (e) {
            // TODO:
            error.value = e.message || "Failed to make the list of items.";
          }
        });
      }
    };

    LoadcatalogItemsV2();
    LoadCatalogItemClass();

    return {
      items,
      itemClasses,
      isLoading,
      getItemThumbnail,
      error,
      LoadcatalogItemsV2,
      currentPage,
      itemsPerPage,
      totalItems,
      changePage,
      ItemId,
      ItemClass,
      ItemName,
      ItemDescription,
      ItemPerPageList,
      itemsPerPageList,
      selectItems,
      AddToItemSelect,
      RemoveItemSelect,
      PlayerIdSepratedByComma,
      ActivePlayerArrayList,
      ShowMessageConfig,
      MessageTitle,
      MessageBody,
      Reson,
      submit,
      isSending,
      executionCount,
      totalExecutionCount,

      handleError,
      PRIVILEGES,
      NextPage,
      PreviosPage,
      ShowDiscription,
      ShowChangeCountDialogFunc,
      showChangeCountDialog,
      selectItemsCount,
      active_item_index,
      progress,
      showActivePlayerDialog,
      errorUsers,
      errorNotification,
      showNotification,
    };
  },
};
</script>

<style scoped>
.w-app {
  padding: 4px;
}
header,
footer,
aside,
main {
  margin: 4px;
  padding: 12px;

  color: #666;
  border: 1px solid rgba(0, 0, 0, 0.07);
}
header,
footer {
  min-height: 60px;
}
</style>
