<template>
  <w-app>
    <Nav/>
    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Authenticating..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <div class="d-flex justify-content-center">
      <w-card style="width: 640px; padding: 20px; margin-top:100px" shadow>
        <h2>Please enter your password.</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">

          <div>
            <w-input
                label="Password"
                v-model.trim="password"
                :type="isPassword ? 'password' : 'text'"
                :inner-icon-right="isPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                @click:inner-icon-right="isPassword = !isPassword"
                :validators="[validators.required, validators.min_8_length]">
            </w-input>
          </div>
          <div>
            <w-input
                label="Confirm Password"
                v-model.trim="rePassword"
                :type="isRePassword ? 'password' : 'text'"
                :inner-icon-right="isRePassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                @click:inner-icon-right="isRePassword = !isRePassword"
                :validators="[validators.required, validators.min_8_length]">
            </w-input>
          </div>
          <div class="text-right">
              <w-button type="submit" lg shadow> Next </w-button>
          </div>
        </w-form>
      </w-card>
    </div>
  </w-app>
</template>

<script>
import Nav from "@/components/Nav.vue";
import {ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {fetching} from "../../config/context";
import {useCookie} from 'vue-cookie-next'

export default {
  name: "SetPassword",
  components : {
    Nav
  },
  setup() {
    const cookie = useCookie();
    const router = useRouter();
    const route = useRoute();

    const formIsValid = ref(false);

    const password = ref(null);
    const isPassword = ref(true);

    const rePassword = ref(null);
    const isRePassword = ref(true);

    const isLoading = ref(false);
    const error = ref(null);

    let validators =  {
      required: value => !!value || 'This field is required',
      min_8_length: value => (value.length >= 8) || 'This field must be at least 8 characters long.'
    }

    const submitForm = async () => {

      if (password.value !== rePassword.value){
        return;
      }
      if (!formIsValid.value) {
        return;
      }

      isLoading.value = true;

      fetching('/api/password/save', {
        method: 'POST',
        body: {
          token: route.query.token,
          password: password.value,
        }
      }).then( response => {
        cookie.setCookie('mfa', {
          token: response.token,
          QR: response.QR
        });
        // cookie.setCookie('token', response.token);
        // cookie.setCookie('secret', response.secret);
        // cookie.setCookie('QR', response.QR);

        router.push('/public/mfaSave');

      }).catch(err => {
        error.value = err.message || 'Failed to save the password, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const handleError = () => {
      error.value = null;
    };

    return {
      formIsValid, password, isPassword, rePassword, isRePassword, isLoading, error, validators,
      submitForm, handleError
    }
  }
};
</script>

<style scoped>
form div {
  padding-block-start: 10px;
}

</style>