<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <h1>Server Status</h1>
  </w-app>
</template>
<script>
export default {
  name: "Index",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
button.w-button {
  margin: 5px;
}
</style>
