import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            blueprintData: null,
            colorData: null,
            combatVariables: null,
            combatVariables_QDB: null,
            conditionalStatModData: null,
            conditionalStatModData_QDB: null,
            dialogSequenceData: null,
            econModData: null,
            economyConfig: null,
            effectAreas: null,
            effectAreas_QDB: null,
            enemySetData: null,
            externalModuleData: null,
            externalModuleData_QDB: null,
            factionData: null,
            factionEffectsList: null,
            globalVariables: null,
            internalModuleData: null,
            itemData: null,
            kiithData: null,
            layoutData: null,
            levelData: null,
            lootData: null,
            miningData: null,
            missionData: null,
            missionStepActions: null,
            missionStepActions_QDB: null,
            missionSteps: null,
            missionSteps_QDB: null,
            missionTemplateData: null,
            officerData: null,
            officerData_QDB: null,
            officerEffectsData: null,
            officerNameData: null,
            officerPortraitData: null,
            officerSlotConfig: null,
            officerSlotConfig_QDB: null,
            officerStatModData: null,
            penaltyData: null,
            premiumShopData: null,
            profileVersionData: null,
            pushNotifications: null,
            questData: null,
            questLineData: null,
            questTemplateData: null,
            researchData: null,
            rewardData: null,
            sellData: null,
            shopData: null,
            skillData: null,
            skillData_QDB: null,
            starSystemConfigData: null,
            starSystemData: null,
            statModData: null,
            statModData_QDB: null,
            status: null,
            stringData: null,
            uIHightlightData: null,
            unitData: null,
            unitData_QDB: null,
            utilitySystemData: null,
            utilitySystemData_QDB: null,
            valueBasedStatMod: null,
            valueBasedStatMod_QDB: null,
            versionControl: null,
            weaponEffects: null,
            weaponEffects_QDB: null,
            weaponSpecialEffects: null,
            weaponSpecialEffects_QDB: null,
            weaponSystemData: null,
            weaponSystemData_QDB: null
        };
    },
    mutations,
    actions,
    getters
};