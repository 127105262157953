<template>
  <w-card title="Clan Info" title-class="primary-dark1--bg">
    <w-flex row class="text-lef">
      <div class="xs4 pa2 box sh-4">ClanId: <a :href="'/clans/edit/' + ClanId" target="_blank">{{ ClanId }} </a>
      </div>
      <div class="xs3 pa2 box sh-4">MasterId : <a :href='"/players/" + pfMasterId' target="_blank">{{ pfMasterId }} </a>
      </div>
      <div class="xs3 pa2 box sh-4">EntityId : <a :href='"/players/" + pfEntityId' target="_blank">{{ pfEntityId }} </a>
      </div>
      <div class="xs2 pa2 box sh-4"><a :href='"/clans/clansmemberList/" + ClanId' target="_blank">Member Count:{{
        memberCount }}</a>
      </div>
    </w-flex>
  </w-card>
</template>

<script >
import { fetching, ROLES } from "../../config/context";
import { ref } from "vue";
import { PRIVILEGES } from "../../config/context";
export default {
  name: "Clan",
  props:
    ["playerid"]
  ,
  setup: function (props) {
    const isCreate = ref(false);
    const ClanId = ref("");
    const pfMasterId = ref("");
    const pfEntityId = ref("");
    const acronym = ref("");
    const name = ref("");
    const iconId = ref("");
    const description = ref("");
    const leaderId = ref("");
    const leaderNickname = ref("");
    const purpose = ref(0);
    const language = ref(0);
    const memberCount = ref(0);
    const territoryCount = ref("");
    const state = ref(0);
    const rank = ref(0);
    const xp = ref(0);
    const createdAt = ref("");
    const playfabId = ref("");
    const isLoading = ref(false);
    const error = ref(null);

    isLoading.value = true;

    const body = {
      "ClanId": "",
      "playerId": props.playerid
    }

    fetching('/api/Alliance/AllianceGetbyId', { method: "POST", body: body }).then(data => {
      acronym.value = data.alliance.data.acronym;
      ClanId.value = data.alliance.id;
      name.value = data.alliance.data.name;
      description.value = data.alliance.data.description;
      iconId.value = data.alliance.data.iconId;
      leaderId.value = data.alliance.leaderId;
      pfMasterId.value = data.alliance.pfMasterId;
      pfEntityId.value = data.alliance.pfEntityId;
      memberCount.value = data.alliance.memberCount;
      purpose.value = data.alliance.purpose;
      language.value = data.alliance.language;
      territoryCount.value = data.alliance.territoryCount;
      state.value = data.alliance.state;
      rank.value = data.alliance.rank;
      xp.value = data.alliance.xp;
      createdAt.value = data.alliance.createdAt;
      playfabId.value = data.alliance.leaderId;
    }).catch(e => {
      error.value = e.message || 'Failed to get the Clan, try later.';
    }).finally(() => {
      isLoading.value = false;
    });

    return {
      ClanId, acronym, name, description, memberCount, playfabId, iconId, pfMasterId,
      pfEntityId, leaderId, leaderNickname, purpose, language, territoryCount, state,
      rank, xp, createdAt, isLoading, error, isCreate,
      PRIVILEGES, ROLES
    }
  }
};
</script>


