<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3" />
    <div class="container-fluid">
      <div class="row">
        <h2>Player Detail</h2>
        <table class="table table-hover">
          <thead>
            <tr>
              <th colspan="6"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="col-md-2">PlayerId</th>
              <td class="col-md-2">
                <a :href="getPlayfabLink('overview')" target="_blank">{{
                  uuid
                }}</a>
              </td>
              <th>Created</th>
              <td>{{ profile?.titleInfo?.Created?.substring(0, 10) }}</td>
              <th>Platform</th>
              <td>
                <div
                  v-if="
                    profile?.platformInfo &&
                      Object.keys(profile?.platformInfo).length > 0
                  "
                >
                  {{
                    !profile ? "&nbsp;" : Object.keys(profile.platformInfo)[0]
                  }}
                </div>
                <div v-else>n/a</div>
              </td>
            </tr>
            <tr>
              <th>Display Name</th>
              <td>{{ profile?.displayName }}</td>
              <th>Last Login</th>
              <td v-ago="profile?.titleInfo?.LastLogin"></td>
              <th>Platform Id</th>
              <td>
                <div
                  class="crop"
                  v-if="
                    profile != null &&
                      profile?.hasOwnProperty('platformInfo') &&
                      Object.keys(profile?.platformInfo).length > 0
                  "
                >
                  {{
                    profile?.platformInfo[Object.keys(profile.platformInfo)[0]]
                  }}
                </div>
                <div v-else>n/a</div>
              </td>
            </tr>
            <tr>
              <th>Kiith</th>
              <td>{{ profile?.kiith }}</td>
              <th>VTD</th>
              <td>{{ numberFormatting(profile?.valuesToDate) }}</td>
              <th>Ban Status</th>
              <td>
                <a
                  href="#"
                  @click="openBanDialog()"
                  :class="profile?.titleInfo?.isBanned ? 'active' : ''"
                  id="banStatus"
                  >{{ profile?.titleInfo?.isBanned ? "O (Blocked)" : "X" }}</a
                >
              </td>
            </tr>
            <tr>
              <th>Flagship</th>
              <td>
                {{ flagship }}
              </td>
              <th>Credit(CR)</th>
              <td>{{ numberFormatting(profile?.credit) }}</td>
              <th>Chat Mute</th>
              <td>
                <a
                  href="#"
                  :class="profile?.VivoxState === 'Banned' ? 'active' : ''"
                  id="muteStatus"
                  @click="openChatDialog"
                  >{{
                    profile?.VivoxState
                      ? chatStateConvertor(profile?.VivoxState)
                      : "Unmuted"
                  }}</a
                >
              </td>
            </tr>
            <tr>
              <th>Last Station</th>
              <td>
                <span v-show="profile?.objects?.PlayerInfo?.Fleet?.System">
                  <a
                    href="#"
                    id="StarSystem"
                    @click="starSystemDialog.show = true"
                  >
                    {{
                      getLastStation(
                        profile?.objects?.PlayerInfo?.Fleet?.System
                      )
                    }}</a
                  >
                </span>
              </td>
              <th>Exp</th>
              <td>{{ numberFormatting(profile?.playerXP) }}</td>
              <th>Security Level</th>
              <td>
                <a href="#" @click="openSecurityDialog">{{
                  profile?.SecurityLevel
                    ? profile?.SecurityLevel
                    : "NormalPlayer"
                }}</a>
              </td>
            </tr>
            <tr>
              <th>Alliance</th>
              <td>
                <span v-if="profile?.alliance"
                  >{{ profile?.alliance?.data?.name }} ({{
                    profile?.alliance?.data?.acronym
                  }})</span
                >
                <span v-else>N/A</span>
              </td>
              <th>Level</th>
              <td>
                {{ parseInt(profile?.playerXP / playerXPLevelAmount) }}
              </td>
              <th colspan="2"></th>
            </tr>
            <tr>
              <th>Segments</th>
              <td>
                <span v-for="(item, index) in profile?.segments" :key="index">
                  <w-tag outline shadow color="primary">
                    <a
                      :href="
                        '/segments/' + item.Id + '/players?name=' + item.Name
                      "
                      >{{ item.Name }}</a
                    >
                  </w-tag>
                </span>
              </td>
              <th colspan="4" class="text-right">
                <w-button
                  bg-color="success"
                  @click="goToGiveItem"
                  v-privilege="PRIVILEGES.PLAYER_WRITE"
                  >Give Item</w-button
                >
              </th>
            </tr>
          </tbody>
          <tfoot />
        </table>
      </div>
      <div class="row">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              class="nav-link "
              :class="index === 0 ? 'active' : index === 6 ? 'disabled' : ''"
              v-for="(t, index) in tabs"
              data-bs-toggle="tab"
              :key="t"
              :id="'nav-tab-' + index"
              :data-bs-target="'#nav-menu-tab-' + index"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              @click="onClickTab(t)"
            >
              {{ t }}
            </button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade"
            :class="index === 0 ? 'show active' : ''"
            v-for="(t, index) in tabs"
            :key="t"
            :id="'nav-menu-tab-' + index"
            role="tabpanel"
            :aria-labelledby="'nav-tab-' + index"
          >
            <br />
            <div v-if="index === tabs.indexOf('Characters')">
              <characters-list
                :items="Characters"
                :playfabId="uuid"
                :getItemFullName="toItemFullName"
                :getItemDesc="toItemDesc"
                :getItemThumbnail="toItemThumbnail"
                :currentFlagship="flagship"
                @set-flagship="setFlagship"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Inventory')">
              <inventory-list
                :items="Inventory"
                :getItemThumbnail="toItemThumbnail"
                @revoke-item="revokeItem"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Virtual Currency')">
              <virtual-currency-list
                :items="VirtualCurrency"
                :playfabId="uuid"
                :getItemThumbnail="toItemThumbnail"
                @change-currency="changeCurrency"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Purchases')">
              <w-button
                class="ma1"
                bg-color="success"
                :route="getPlayfabLink('purchases')"
                target="_blank"
              >
                Go to PlayFab
                <w-icon class="ml2" sm>mdi mdi-open-in-new</w-icon>
              </w-button>
            </div>
            <div v-else-if="index === tabs.indexOf('Stash')">
              <inventory-list
                :items="Stash"
                :getItemThumbnail="toItemThumbnail"
                @revoke-item="revokeItem"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Login History')">
              <w-button
                class="ma1"
                bg-color="warning"
                :route="getPlayfabLink('logins')"
                target="_blank"
              >
                Go to PlayFab
                <w-icon class="ml2" sm>mdi mdi-open-in-new</w-icon>
              </w-button>
            </div>
            <div v-else-if="index === tabs.indexOf('Ban History')">
              <bans-list :items="BanHistory" @revoke-ban="revokeBan" />
              For mare information.
              <w-button
                class="ma1"
                bg-color="secondary"
                :route="getPlayfabLink('bans')"
                target="_blank"
              >
                Go to PlayFab
              </w-button>
            </div>
            <div v-else-if="index === tabs.indexOf('Audit History')">
              <audit-list :items="AuditHistory"></audit-list>
            </div>
            <div v-else-if="index === tabs.indexOf('Quest Log')">
              <QuestLogList
                :QuestLog="QuestLog"
                :QuestLinesLog="QuestLinesLog"
                :getItemFullName="toItemFullName"
                :getItemDesc="toItemDesc"
                :getItemThumbnail="toItemThumbnail"
                @change-quest-log="changeQuestLog"
                v-if="QuestLog !== null"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Quest')">
              <QuestList
                :QuestLog="QuestLog"
                :QuestLinesLog="QuestLinesLog"
                :getItemFullName="toItemFullName"
                :getItemDesc="toItemDesc"
                :getItemThumbnail="toItemThumbnail"
                @change-quest="changeQuest"
                v-if="QuestLog !== null"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Reset')">
              <w-button
                class="ma1"
                bg-color="info"
                :route="getPlayfabLink('overview')"
                target="_blank"
              >
                Go to PlayFab
              </w-button>
            </div>
            <div v-else-if="index === tabs.indexOf('Mailbox')">
              <post-list :items="Mailbox" :getItemThumbnail="toItemThumbnail" />
            </div>
            <div v-else-if="index === tabs.indexOf('Fabrication')">
              <fabrication-list
                :items="Fabrication"
                :getTimerTypeName="getTimerTypeName"
                :getTimerStatusName="getTimerStatusName"
                :getItemFullName="toItemFullName"
                :getItemThumbnail="toItemThumbnail"
                @change-fabrication="changeFabrication"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('DeltaDNA')">
              <deltaDNA-list
                v-if="deltaDNAState === true"
                :playerId="uuid"
                :getItemFullName="toItemFullName"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Support History')">
              <support-list
                :playerId="uuid"
                :items="SupportHistory"
                @change-support-history="changeSupportHistory"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Chat History')">
              <chat-history
                v-if="chatHistoryState === true"
                :playerId="uuid"
                :playerName="profile?.displayName"
              />
            </div>
            <div v-else-if="index === tabs.indexOf('Clan')">
              <Clan :playerid="uuid" />
            </div>
            <div v-else>TBD - {{ t }}</div>
          </div>
        </div>
      </div>
    </div>
    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="chatDialog.show"
      :width="500"
      title-class="primary-light1--bg white"
    >
      <template #title>
        <w-icon class="mr2">mdi mdi-tune</w-icon>
        Will you change the Chat Status for "{{
          profile?.displayName || "Player"
        }}" to?
      </template>

      <div class="w-flex mt6 no-grow">
        <h4 class="mr2">Chat Mute:</h4>
        <w-radios
          v-model="chatDialog.selection"
          :items="[
            { value: 'Banned', label: 'Mute' },
            { value: 'None', label: 'Unmute' },
          ]"
        />
      </div>

      <template #actions>
        <div class="spacer" />
        <w-button
          bg-color="success"
          @click="changeChatState"
          v-privilege="PRIVILEGES.PLAYER_WRITE"
          >Save</w-button
        >
        <w-button bg-color="error" @click="chatDialog.show = false"
          >Close</w-button
        >
      </template>
    </w-dialog>

    <w-dialog
      v-model="starSystemDialog.show"
      title-class="primary-light1--bg white"
    >
      <template #title>
        <w-icon class="mr2">mdi mdi-tune</w-icon>
        Changing Player's station in Star System
      </template>

      <template #default>
        <w-flex
          v-for="faction in new Set(
            Object.values(starSystemDataItems).map((x) => x.Faction)
          )"
          :key="faction"
          wrap
          class=" pa1"
        >
          <div
            class="xs12 md12 lg12 xl12 pa1"
            style="margin-left: 0; padding-left: 0"
          >
            <h4 style="margin: 0;">{{ faction }}</h4>
            <w-divider />
          </div>
          <div
            class="xs2 md2 lg2 xl2 "
            v-for="(item, index) in Object.values(starSystemDataItems).filter(
              (x) => x.Faction === faction
            )"
            :key="index"
          >
            <w-radio
              v-model="starSystemDialog.selection"
              :return-value="item.location"
              checked
              ><b>{{ item.Name }}</b></w-radio
            >
            <br />
            <span class="starSystem">Tier:</span> {{ item.Tier }}
            <span class="starSystem">/</span>
            <span class="starSystem">Jump D.:</span> {{ item.JumpDifficulty }}
          </div>
          <hr />
        </w-flex>
      </template>

      <template #actions>
        <div class="spacer" />
        <w-button
          bg-color="success"
          @click="changeStarSystemLocation"
          v-privilege="PRIVILEGES.PLAYER_WRITE"
          >Save</w-button
        >
        <w-button bg-color="error" @click="starSystemDialog.show = false"
          >Close</w-button
        >
      </template>
    </w-dialog>

    <w-dialog
      v-model="securityDialog.show"
      :width="500"
      title-class="primary-light1--bg white"
    >
      <template #title>
        <w-icon class="mr2">mdi mdi-tune</w-icon>
        Will you change the security level for "{{
          profile?.displayName || "Player"
        }}" to?
      </template>

      <div class="w-flex mt6 no-grow">
        <h4 class="mr2">Security Level:</h4>
        <w-radios
          v-model="securityDialog.selection"
          :items="[
            { value: '', label: 'Normal Player' },
            { value: 'QA', label: 'QA' },
            { value: 'Admin', label: 'Admin' },
          ]"
        />
      </div>

      <template #actions>
        <div class="spacer" />
        <w-button
          bg-color="success"
          @click="changeSecurityLevel"
          v-privilege="PRIVILEGES.PLAYER_WRITE"
          >Save</w-button
        >
        <w-button bg-color="error" @click="securityDialog.show = false"
          >Close</w-button
        >
      </template>
    </w-dialog>

    <w-form @submit.prevent="submitBanDialogForm" v-model="formIsValid">
      <w-dialog
        v-model="banDialog.show"
        :width="500"
        title-class="primary-light1--bg white"
      >
        <template #title>
          <w-icon class="mr2">mdi mdi-tune</w-icon>
          Add Ban "{{ profile?.displayName }}"?
        </template>

        <div class=" mt6 no-grow">
          <w-input
            class="mt3"
            label="Reason *"
            v-model.trim="banDialog.reason"
            :validators="[validators.required]"
          />
          <w-checkbox
            class="mt3"
            label="Ban permanently"
            v-model="banDialog.permanent"
          />
          <w-input
            class="mt3"
            label="Duration in hours *"
            v-model.number="banDialog.duration"
            min="1"
            type="number"
            :validators="[validators.required]"
            :disabled="banDialog.permanent"
          />
          <w-input
            class="mt3"
            label="IP address (May affect multiple players.)"
            v-model.trim="banDialog.ip"
          />
          <div id="error" v-show="banDialog.error">{{ banDialog.error }}</div>
        </div>

        <template #actions>
          <div class="spacer" />
          <w-button
            bg-color="success"
            type="submit"
            v-privilege="PRIVILEGES.PLAYER_WRITE"
            >Save</w-button
          >
          <w-button bg-color="error" @click="banDialog.show = false"
            >Close</w-button
          >
        </template>
      </w-dialog>
    </w-form>
  </w-app>
</template>

<script>
import {
  getItemFullName2,
  getItemDesc2,
  getItemThumbnail,
} from "../../hooks/item.js";
import { fetching, PRIVILEGES } from "../../config/context";
import { ref, reactive, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import VirtualCurrencyList from "../../components/virtualCurrency/VirtualCurrencyList.vue";
import CharactersList from "../../components/characters/CharactersList.vue";
import SupportList from "../../components/support/SupportList.vue";
import InventoryList from "../../components/inventory/InventoryList.vue";
import BansList from "../../components/bans/BansList.vue";
import AuditList from "../../components/audit/AuditList.vue";
import PostList from "../../components/mailbox/PostList.vue";
import FabricationList from "../../components/fabrication/FabricationList.vue";
import DeltaDNAList from "../../components/deltaDNA/DeltaDNAList";
import QuestLogList from "../../components/Quest/QuestLogList";
import QuestList from "../../components/Quest/QuestList";
import ChatHistory from "../../components/chat/ChatHistory";
import Clan from "../../components/clan/Clan.vue";

export default {
  name: "PlayerView",
  components: {
    DeltaDNAList,
    VirtualCurrencyList,
    CharactersList,
    InventoryList,
    BansList,
    AuditList,
    PostList,
    FabricationList,
    QuestLogList,
    QuestList,
    SupportList,
    ChatHistory,
    Clan,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const stringData = store.getters["stringTable/stringData"];
    const itemData = JSON.parse(store.getters["title/itemData"]);
    const starSystemData = JSON.parse(store.getters["title/starSystemData"]);

    const profile = ref(null);
    const starSystemDataItems = ref(null);
    const isLoading = ref(true);
    const error = ref(null);
    const uuid = ref(null);
    const formIsValid = ref(null);

    const validators = {
      required: (value) => !!value || "This field is required",
      // number: value => (/^-?\d+$/.test(value)) || 'This field should be number',
      validate_ip: (value) =>
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          value
        ) || "The ip address must be valid.",
    };

    // do not change 'let' in front
    let Characters = ref(null);
    let Inventory = ref(null);
    let Purchases = ref(null);
    let Stash = ref(null);
    let VirtualCurrency = ref(null);
    let BanHistory = ref(null);
    let AuditHistory = ref(null);
    let Mailbox = ref(null);
    let Fabrication = ref(null);
    let deltaDNAState = ref(false);
    let chatHistoryState = ref(false);
    let flagship = ref(null);
    let QuestLog = ref(null);
    let QuestLinesLog = ref(null);
    let SupportHistory = ref(null);

    const mailboxId = ref(null);
    const stashId = ref(null);

    const banDialog = reactive({
      ip: "",
      reason: "",
      duration: null,
      permanent: false,
      show: false,
    });

    const securityDialog = reactive({
      selection: "",
      show: false,
    });

    const chatDialog = reactive({
      selection: "None",
      show: false,
    });

    const starSystemDialog = reactive({
      selection: profile.value?.objects?.PlayerInfo?.Fleet?.System,
      //fullscreen: true,
      show: false,
    });

    const tabs = ref([
      "Characters",
      "Inventory",
      "Virtual Currency",
      "Purchases",
      "Stash",
      "Mailbox",
      "Research",
      "Quest",
      "Quest Log",
      "Fabrication",
      "Login History",
      "Ban History",
      "Audit History",
      "DeltaDNA",
      "Reset",
      "Support History",
      "Chat History",
      "Clan",
    ]);

    const getPlayfabLink = (x) => {
      if (!profile.value) return;

      let link = "https://developer.playfab.com/en-US/";
      if (x === "overview") {
        return (
          link + profile.value.titleid + "/players/" + uuid.value + "/overview"
        );
      } else if (x === "purchases") {
        return (
          link + profile.value.titleid + "/players/" + uuid.value + "/purchases"
        );
      } else if (x === "logins") {
        return (
          link +
          "r/t/" +
          profile.value.titleid +
          "/players/" +
          uuid.value +
          "/logins"
        );
      } else if (x === "bans") {
        return (
          link + profile.value.titleid + "/players/" + uuid.value + "/bans"
        );
      }

      return "#";
    };
    const playerXPLevelAmount = ref(null);
    const handleError = () => {
      error.value = null;
      router.go(-1);
    };

    const numberFormatting = (x) => {
      return Intl.NumberFormat().format(x);
    };

    function _setMailbox(data) {
      const temp = [];

      Object.keys(data).forEach((x) => {
        const post = JSON.parse(data[x].Value);
        post["PostId"] = x;

        if ("Items" in post) {
          const items = [];

          post["Items"].forEach((x) => {
            // items.push(toItemFullName({ItemId: x}));
            items.push({
              id: x,
              name: toItemFullName({ ItemId: x }),
            });
          });
          post["Items"] = items;
        }

        if ("Rewards" in post) {
          const rewards = [];
          //
          Object.entries(post["Rewards"]).forEach(([k, v]) => {
            rewards.push({
              id: k,
              name: toItemFullName({ ItemId: k }),
              value: v,
            });
          });
          post["Rewards"] = rewards;
        }
        temp.push(post);
      });
      Mailbox.value = temp;
    }

    function _setCharacters(data) {
      mailboxId.value = data.find(
        (c) => c.CharacterType === "mailbox"
      ).CharacterId;
      stashId.value = data.find((c) => c.CharacterType === "stash").CharacterId;

      data.map(
        (c) =>
          (c.CharacterFullName = toItemFullName({ ItemId: c.CharacterName }))
      );
      data.map(
        (c) => (c.CharacterDesc = toItemDesc({ ItemId: c.CharacterName }))
      );

      data = data.filter(
        (c) => c.CharacterType !== "stash" && c.CharacterType !== "mailbox"
      );
      Characters.value = data;
    }

    function toItemThumbnail(c) {
      return getItemThumbnail(itemData, c.ItemId || c);
    }

    function toItemFullName(c) {
      return getItemFullName2(itemData, stringData, c.ItemId);
    }

    function toItemDesc(c) {
      return getItemDesc2(itemData, stringData, c.ItemId);
    }

    const getTimerStatusName = (status) => {
      switch (status) {
        case "P":
          return "Processing";
        case "F":
          return "Finished";
        case "C":
          return "Canceled";
        case "D":
          return "Deleted";
        case "S":
          return "Suspended";
        default:
          return "Collecting";
      }
    };

    const getTimerTypeName = (type) => {
      const types = [
        "None",
        "Crafting",
        "Refine",
        "Research",
        "Upgrade",
        "AutoMining",
        "Refining",
      ];
      return types[type] || "n/a";
    };

    function _setFabrication(data) {
      const timerList = data["timerList"];
      const factoryList = data["factoryList"];
      timerList.map((c) => {
        const keys = Object.keys(c.parameter);
        if (keys.indexOf("BlueprintId") > -1) {
          c.__ItemFullName = toItemFullName({
            ItemId: c.parameter.BlueprintId,
          });
        }
      });

      timerList.map((c) => (c.__ItemId = c.parameter.BlueprintId));
      timerList.map((c) => (c.__TimerTypeName = getTimerTypeName(c.type)));
      timerList.map(
        (c) => (c.__TimerStatusName = getTimerStatusName(c.status))
      );
      timerList.map(
        (c) =>
          (c.factory = toItemFullName({
            ItemId: factoryList[c.contextId] || "",
          }))
      );

      Fabrication.value = timerList.sort((a, b) => {
        return b.createdDt > a.createdDt ? 1 : -1;
      });
    }

    function _setStash(data) {
      data.map((c) => (c.ItemFullName = toItemFullName(c)));
      data.map((c) => (c.ItemDesc = toItemDesc(c)));
      Stash.value = data;
    }

    function _setVirtualCurrencyAndInventory(data) {
      const output = [];

      // XXX: be shorter
      Object.keys(data.VirtualCurrency).forEach((k) => {
        output.push({
          key: k,
          value: data.VirtualCurrency[k],
          desc: toItemDesc({ ItemId: k }),
          name: toItemFullName({ ItemId: k }),
        });
      });
      VirtualCurrency.value = output;

      data.Inventory.map((c) => (c.ItemFullName = toItemFullName(c)));
      data.Inventory.map((c) => (c.ItemDesc = toItemDesc(c)));
      Inventory.value = data.Inventory;
    }

    const onClickTab = (e) => {
      isLoading.value = true;

      let endpoint = "/api/profile/" + uuid.value;
      if (e === "Characters") {
        endpoint += "/characters";
      } else if (e === "Ban History") {
        endpoint += "/bans";
      } else if (e === "Inventory") {
        endpoint += "/inventoryAndCurrency";
      } else if (e === "Virtual Currency") {
        endpoint += "/inventoryAndCurrency";
      } else if (e === "Audit History") {
        endpoint += "/audit?size=2000";
      } else if (e === "Stash") {
        endpoint += "/characters/" + stashId.value + "/inventory";
      } else if (e === "Mailbox") {
        endpoint += "/characters/" + mailboxId.value + "/data";
      } else if (e === "Fabrication") {
        endpoint += "/timer";
      } else if (e === "DeltaDNA") {
        isLoading.value = false;
        deltaDNAState.value = true;
        return;
      } else if (e === "Quest Log") {
        isLoading.value = false;
        return;
      } else if (e === "Quest") {
        isLoading.value = false;
        return;
      } else if (e === "Support History") {
        endpoint += "/comments";
      } else if (e === "Chat History") {
        isLoading.value = false;
        chatHistoryState.value = true;
        return;
      } else if (e === "Clan") {
        isLoading.value = false;
        chatHistoryState.value = true;
        return;
      } else {
        isLoading.value = false;
        return;
      }

      let dummyData = eval(e.replace(" ", ""));
      if (dummyData.value != null) {
        isLoading.value = false;
        return; // it will not be computed, when the data exists.
      }

      fetching(endpoint)
        .then((data) => {
          if (e === "Inventory" || e === "Virtual Currency") {
            _setVirtualCurrencyAndInventory(data);
          } else if (e === "Characters") {
            _setCharacters(data);
          } else if (e === "Mailbox") {
            _setMailbox(data);
          } else if (e === "Stash") {
            _setStash(data);
          } else if (e === "Fabrication") {
            _setFabrication(data);
          } else {
            dummyData.value = data;
          }
        })
        .catch((e) => {
          console.error(e);
          error.value = e.message || "Failed to get the User, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    function getPlayerProfile(playerId) {
      isLoading.value = true;

      fetching("/api/profile/" + playerId)
        .then((data) => {
          profile.value = data;

          if (profile.value.SecurityLevel !== undefined)
            securityDialog.selection = profile.value.SecurityLevel;

          if (profile.value.VivoxState !== undefined)
            chatDialog.selection = profile.value.VivoxState;

          // Flagship
          const flagshipId = data.objects?.FleetLoadouts?.Loadouts.find(
            (x) => x.UniqueId === data.objects?.PlayerInfo?.Fleet?.UniqueId
          ).UniqueId;
          if (flagshipId !== undefined) {
            flagship.value = `${
              Characters.value.find((c) => c.CharacterId === flagshipId)
                .CharacterFullName
            } (${flagshipId})`;
          }

          document.title = `${data.displayName || "n/a"} - ${uuid.value}`;

          QuestLog.value = profile.value?.playerData?.QuestLog?.Quests;
          QuestLinesLog.value = profile.value?.playerData?.QuestLinesLog?.Lines;

          starSystemDialog.selection =
            profile.value?.objects?.PlayerInfo?.Fleet?.System;
        })
        .catch((e) => {
          console.error(e);
          if (e.message === "Sequence contains no matching element") {
            error.value = "The user may do not exist.";
            return;
          }
          error.value = e.message || "Failed to get the User, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    onBeforeMount(() => {
      starSystemDataItems.value = Object.entries(starSystemData)
        .map(([k, v]) => {
          v.location = k;
          return v;
        })
        .sort((x, y) => {
          if (x.Faction !== y.Faction) {
            return x.Faction > y.Faction ? 1 : -1;
          } else if (x.Tier !== y.Tier) {
            return x.Tier > y.Tier ? 1 : -1;
          } else if (x.JumpDifficulty !== y.JumpDifficulty) {
            return x.JumpDifficulty > y.JumpDifficulty ? 1 : -1;
          } else {
            return x.Name > y.Name ? 1 : -1;
          }
        });

      const globalVariables = JSON.parse(
        store.getters["title/globalVariables"]
      );
      playerXPLevelAmount.value = globalVariables.PlayerXPLevelAmount.Value;

      uuid.value = route.path.split("/").reverse()[0];
      getPlayerProfile(uuid.value);

      onClickTab("Characters");
    });

    const changeCurrency = () => {
      VirtualCurrency.value = null;
      onClickTab("Virtual Currency");
    };

    const changeSupportHistory = () => {
      SupportHistory.value = null;
      onClickTab("Support History");
    };

    const changeSecurityLevel = () => {
      isLoading.value = true;
      securityDialog.show = false;

      if (securityDialog.selection === profile.value.SecurityLevel) {
        isLoading.value = false;
        return;
      }

      fetching(
        "/api/profile/" +
          uuid.value +
          "/securityLevel?value=" +
          securityDialog.selection,
        { method: "PUT" }
      )
        .then(() => {
          profile.value.SecurityLevel = securityDialog.selection;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to change the Security Level, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const chatStateConvertor = (state) => {
      if (state === "None") {
        return "Unmuted";
      }

      return "Muted";
    };

    const changeStarSystemLocation = () => {
      isLoading.value = true;
      starSystemDialog.show = false;

      const coordinate = starSystemDialog.selection;
      if (coordinate === undefined) {
        isLoading.value = false;
        error.value = "error";
        return;
      }

      if (
        starSystemDialog.selection ===
        profile.value?.objects?.PlayerInfo?.Fleet?.System
      ) {
        isLoading.value = false;
        return;
      }

      fetching(`/api/profile/${uuid.value}/star/system/${coordinate}`, {
        method: "PUT",
      })
        .then(() => {
          profile.value.objects.PlayerInfo.Fleet.System = coordinate;
        })
        .catch((e) => {
          error.value =
            e.message ||
            "Failed to change the location of Star System, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const changeChatState = () => {
      isLoading.value = true;
      chatDialog.show = false;

      if (chatDialog.selection === profile.value.VivoxState) {
        isLoading.value = false;
        return;
      }

      const willMute = chatDialog.selection === "Banned";
      fetching(
        "/api/profile/" + uuid.value + "/vivox/state?willMute=" + willMute,
        { method: "PUT" }
      )
        .then(() => {
          profile.value.VivoxState = chatDialog.selection;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to change the state of Chat Mute, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const submitBanDialogForm = () => {
      if (!formIsValid.value) {
        return;
      }

      banDialog.error = null;
      isLoading.value = true;
      banDialog.show = false;

      const body = {
        reason: banDialog.reason,
        durationInHours: banDialog.duration,
        ipAddress: banDialog.ip,
      };

      if (banDialog.permanent === true) {
        body.durationInHours = null;
      }

      if (
        banDialog.ip !== "" &&
        validators.validate_ip(banDialog.ip) !== true
      ) {
        banDialog.error = validators.validate_ip(banDialog.ip);
        return;
      }

      Promise.all([
        fetching("/api/profile/" + uuid.value + "/bans", {
          method: "POST",
          body: body,
        }),
        fetching(
          "/api/profile/" + uuid.value + "/bans/CommunicatePlayerBanned",
          { method: "PUT", body: body }
        ),
      ])
        .then(() => {
          profile.value.titleInfo.isBanned = true;

          // reload ban history
          BanHistory.value = null;
          onClickTab("Ban History");
        })
        .catch((e) => {
          error.value = e.message || "Failed to ban the user, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const revokeItem = (instanceId) => {
      if (!confirm("Please confirm?")) {
        return;
      }

      isLoading.value = true;

      fetching("/api/profile/" + uuid.value + "/inventory/" + instanceId, {
        method: "DELETE",
      })
        .then(() => {
          // reload Inventory
          Inventory.value = null;
          onClickTab("Inventory");
        })
        .catch((e) => {
          error.value = e.message || "Failed to revoke the item, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const changeFabrication = async () => {
      Fabrication.value = null;
      onClickTab("Fabrication");
    };

    const changeQuestLog = async () => {
      await getPlayerProfile(uuid.value);
      onClickTab("Quest Log");
    };

    const changeQuest = async () => {
      await getPlayerProfile(uuid.value);
      onClickTab("Quest");
    };

    const revokeBan = (banId) => {
      if (!confirm("Please confirm?")) {
        return;
      }

      isLoading.value = true;
      fetching("/api/profile/" + uuid.value + "/bans/" + banId, {
        method: "DELETE",
      })
        .then(() => {
          profile.value.titleInfo.isBanned = false;

          // reload ban history
          BanHistory.value = null;
          onClickTab("Ban History");
        })
        .catch((e) => {
          error.value = e.message || "Failed to revoke the user, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const goToGiveItem = () => {
      // TODO : change to new
      router.push("/give-item-to-player?playerId=" + uuid.value);
    };

    const getLastStation = (lastLogin) => {
      if (lastLogin === undefined) {
        return;
      }

      const stationId = lastLogin.split("_")[0];
      const stationInfo = starSystemData[stationId];
      return `${stationInfo?.Name || lastLogin} - ${stationInfo?.Faction}`;
    };

    const setFlagship = async (flagshipId) => {
      // alert(id)
      flagshipId;
      // uuid.value = route.path.split("/").reverse()[0];

      isLoading.value = true;
      fetching(`/api/profile/${uuid.value}/ship/flagship/${flagshipId}`, {
        method: "PUT",
      })
        .then(async () => {
          await getPlayerProfile(uuid.value);
          onClickTab("Characters");
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to change the flagship, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const openBanDialog = () => {
      banDialog.ip = "";
      banDialog.reason = "";
      banDialog.duration = null;
      banDialog.permanent = false;
      banDialog.show = true;
    };

    const openSecurityDialog = () => {
      securityDialog.selection = profile.value.SecurityLevel;
      securityDialog.show = true;
    };
    const openChatDialog = () => {
      chatDialog.selection = profile?.value.VivoxState;
      chatDialog.show = true;
    };

    return {
      isLoading,
      error,
      profile,
      uuid,
      playerXPLevelAmount,
      tabs,
      Characters,
      Inventory,
      Purchases,
      Stash,
      flagship,
      chatDialog,
      starSystemDialog,
      QuestLinesLog,
      banDialog,
      securityDialog,
      validators,
      formIsValid,
      VirtualCurrency,
      BanHistory,
      AuditHistory,
      Mailbox,
      Fabrication,
      deltaDNAState,
      chatHistoryState,
      QuestLog,
      starSystemDataItems,
      SupportHistory,
      handleError,
      numberFormatting,
      onClickTab,
      getPlayfabLink,
      changeSecurityLevel,
      submitBanDialogForm,
      changeChatState,
      chatStateConvertor,
      changeStarSystemLocation,
      revokeBan,
      revokeItem,
      changeCurrency,
      toItemFullName,
      toItemDesc,
      goToGiveItem,
      toItemThumbnail,
      PRIVILEGES,
      getLastStation,
      changeQuest,
      changeQuestLog,
      changeSupportHistory,
      setFlagship,
      getTimerTypeName,
      getTimerStatusName,
      changeFabrication,
      openBanDialog,
      openSecurityDialog,
      openChatDialog,
    };
  },
};
</script>
<style scoped>
.crop {
  width: 120px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#banStatus.active,
#muteStatus.active {
  color: red;
  font-weight: bold;
}

#error {
  color: orangered;
}

div.w-app {
  /*min-height: 10px ;*/
}

span.starSystem {
  color: darkgray;
}
</style>
