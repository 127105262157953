<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <PlayerInSegment />
  </w-app>
</template>
<script>
import PlayerInSegment from "../../components/player/PlayerInSegment.vue";

PlayerInSegment;
export default {
  name: "BatchItemSend",
  components: {
    PlayerInSegment,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
button.w-button {
  margin: 5px;
}
</style>
