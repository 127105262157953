<template>
  <header
    class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
    :class="isLive() ? 'bg-production-dark' : ''"
  >
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
      <img src="/logo.svg" width="150" />
    </a>
    <button
      class="navbar-toggler position-absolute d-md-none collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <router-link
          to="/activityLog"
          class="p-2 text-white text-decoration-none"
        >
          {{ user }}
        </router-link>

        &nbsp;
        <button class="btn btn-outline-secondary" @click="openDrawer = true">
          Change stage
        </button>

        &nbsp;
        <button
          v-if="isAuthenticated"
          class="btn btn-outline-secondary"
          @click="logout"
        >
          Sign out
        </button>
      </li>
    </ul>
    <w-drawer v-model="openDrawer" :width="'20em'">
      <div class="openDrawer">
        <h2>Go to stages</h2>
        <ul>
          <li>
            <a href="https://hw-dev.stratosphere-games.com/" target="_blank"
              >DEV</a
            >
          </li>
          <li>
            <a href="https://hw-alpha.stratosphere-games.com/" target="_blank"
              >Alpha</a
            >
          </li>
          <li>
            <a href="https://hw-beta.stratosphere-games.com/" target="_blank"
              >Beta</a
            >
          </li>
          <li>
            <a href="https://hw-qa.stratosphere-games.com/" target="_blank"
              >QA</a
            >
          </li>
          <li>
            <a href="https://hw-review.stratosphere-games.com/" target="_blank"
              >Review</a
            >
          </li>
          <li>
            <a href="https://hw-preview.stratosphere-games.com/" target="_blank"
              >Preview</a
            >
          </li>
          <li>
            <a
              href="https://hw-external.stratosphere-games.com/"
              target="_blank"
              >External</a
            >
          </li>
          <li>
            <a href="https://hw-ptr.stratosphere-games.com/" target="_blank"
              >Ptr</a
            >
          </li>
          <li>
            <a href="https://hw-live.stratosphere-games.com/" target="_blank"
              >Live</a
            >
          </li>
        </ul>
      </div>
    </w-drawer>
  </header>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Nav",
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.getters.name);

    const isAuthenticated = computed(() => store.getters.isAuthenticated);
    const didAutoLogout = computed(() => store.getters.didAutoLogout);

    const openDrawer = ref(false);
    const logout = () => {
      store.dispatch("logout");
      router.replace("/login");
    };

    watch(didAutoLogout, (curValue, oldValue) => {
      if (curValue && curValue !== oldValue) {
        router.replace("/login");
      }
    });

    const isLive = () => {
      return (
        location.href.indexOf("https://hw-live.stratosphere-games.com/") > -1
      );
    };

    return {
      user,
      isAuthenticated,
      logout,
      isLive,
      openDrawer,
    };
  },
};
</script>
<style scoped>
ul {
  list-style: none;
  /*margin: 0;*/
  padding: 0;
}

.openDrawer {
  padding: 1rem;
}
</style>
