<template>
  <w-app>
    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div>
      Sorting by:
      <w-radios
          v-model="sortBy"
          :items="[{ value: 'name', label: 'Name' }, { value: 'value', label: 'Amount' }, { value: 'key', label: 'Key' }]"
          inline
      />
      <span id="SortButtonGroup">
        <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
        <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
      </span>
    </div>
    <div v-show="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <ul>
      <virtual-currency-item
                  v-for="item in displayedItems"
                  :key="item.key"
                  :amount="item.value"
                  :currency="item.key"
                  :desc="item.desc"
                  :name="item.name"
                  :getItemThumbnail="getItemThumbnail"
                  @change-currency="changeCurrency"
              />
    </ul>

    <w-form @submit.prevent="virtualCurrencyDialogForm" v-model="formIsValid">
      <w-dialog
          v-model="virtualCurrencyDialog.show"
          :width="500"
          title-class="primary-light1--bg white">
        <template #title>
          <w-icon class="mr2">mdi mdi-tune</w-icon>
          Change the Currency - <b> {{virtualCurrencyDialog.currencyName}}</b>
        </template>
        <div class="text-center">
          <img :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/' + getItemThumbnail(virtualCurrencyDialog.currencyId) + '.png'" style="width: 100px">
        </div>
        <span>{{virtualCurrencyDialog.currencyDesc}}</span>
        <div class=" mt6 no-grow">
          <div style="text-align: right;">
          <span class="calc">
            {{virtualCurrencyDialog.currencyId}}: {{numberFormatting(virtualCurrencyDialog.currencyAmount)}} => {{numberFormatting(virtualCurrencyDialog.newAmount)}}
            <span :class="(diff.startsWith('-'))? 'minus': 'plus'">({{ diff }})</span>
          </span>
          </div>
          <w-input class="mt3 text-right"
                   label="Amount : "
                   v-model.number="virtualCurrencyDialog.newAmount"
                   min="1"
                   label-position="left"
                   bg-color="blue-light5"
                   type="number"
                   tile
                   :validators="[validators.required, validators.number]"
          />
        </div>

        <template #actions>
          <div class="spacer" />
          <w-button bg-color="success" type="submit" v-privilege="PRIVILEGES.PLAYER_WRITE">Save</w-button>
          <w-button bg-color="error" @click="virtualCurrencyDialog.show = false">Close</w-button>
        </template>

      </w-dialog>
    </w-form>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Change ..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>
    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import {fetching} from "../../config/context";
import { ref, reactive, toRefs, onBeforeMount, computed } from 'vue';
import VirtualCurrencyItem from './VirtualCurrencyItem.vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import {PRIVILEGES} from "../../config/context";
export default {
  components: {
    VirtualCurrencyItem
  },
  props: ['items', "playfabId", "getItemThumbnail"],
  emits: ['change-currency'],
  methods: {
    changeCurrency  (currencyId)  {

      this.virtualCurrencyDialog.currencyId = currencyId;
      this.virtualCurrencyDialog.currencyName = this.items.filter(c => c.key == currencyId)[0].name;
      this.virtualCurrencyDialog.currencyDesc = this.items.filter(c => c.key == currencyId)[0].desc;
      this.virtualCurrencyDialog.currencyAmount = this.items.filter(c => c.key == currencyId)[0].value;
      this.virtualCurrencyDialog.newAmount = this.virtualCurrencyDialog.currencyAmount;
      this.virtualCurrencyDialog.show = true;
    }

  },
  setup(props, context) {
    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const sortBy = ref('name');

    const virtualCurrencyDialog = reactive({
      currencyId: '',
      currencyAmount: 0,
      newAmount: 0,
      show: false
    });

    const diff = computed(function () {
      const gap = virtualCurrencyDialog.newAmount - virtualCurrencyDialog.currencyAmount

      if (gap > 0){
        return "+" + numberFormatting(gap);
      }
      return numberFormatting(gap);
    });

    const { items } = toRefs(props);
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['key', 'value', "name", "desc"]
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        sortBy
    );

    const validators =  {
      required: value => !!value || 'This field is required',
      number: value => (/^-?\d+$/.test(value)) || 'This field should be number',
    }

    onBeforeMount(()=>{
      sorting.value = 'asc'
    })

    const virtualCurrencyDialogForm = () => {

      if (!formIsValid.value) return;
      if(!confirm(`Are you sure that "${diff.value}" for this player?`)) return;

      isLoading.value = true;
      virtualCurrencyDialog.show = false;

      // Nothing changed
      if (diff.value === "0"){
        isLoading.value = false;
      }

      console.log(typeof diff.value);
      console.log(diff.value);

      let api = "/api/profile/" + props.playfabId + "/virtual-currency/" + virtualCurrencyDialog.currencyId + ((diff.value.startsWith("+"))? "/add": "/subtract");
      api += "?amount=" + Math.abs(virtualCurrencyDialog.newAmount - virtualCurrencyDialog.currencyAmount);

      fetching(api, { "method" : 'PUT' })
          .then(()=>{
            // profile.value.titleInfo.isBanned = true;

            // reload ban history
            // BanHistory.value = null;
            // onClickTab("Ban History");
          }).catch(e => {
            error.value = e.message || 'Failed to ban the user, try later.';

          }).finally(() => {

            context.emit('change-currency');
            isLoading.value = false;
          });
    }

    const numberFormatting = (x) => {
      return Intl.NumberFormat().format(x);
    }

    const handleError = () => {
      error.value = null;
    }

    return {
      isLoading, enteredSearchTerm, updateSearch, error, sortBy,
      displayedItems, sorting, sort, availableItems, formIsValid, virtualCurrencyDialog, validators, diff,
      virtualCurrencyDialogForm, numberFormatting, handleError,
      PRIVILEGES
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input {
  height: 200px;
  font-size:24px;
}
span {
  text-align: justify;
  text-justify: inter-word;
}

.minus{
  color: orangered;
}

.plus{
  color: dodgerblue !important;
}

.calc {
  font-size: 20px;
}

.w-radios {
  margin-left: 10px;
}

#SortButtonGroup {
  margin-left: 10px;
}
</style>
