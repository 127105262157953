<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Player Support History List</h2>
        <w-flex xl12 wrap>
          <w-input
            label-position="left"
            label="Part of support comment or player Id:"
            v-model.trim="SearchTerm"
          />
          <w-button @click="Search()">Search</w-button>
        </w-flex>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Comments</th>
                <th>Player Id</th>
                <th>Time</th>
                <th>Admin Id</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.rowKey"
                scope="row"
                :class="item.status ? '' : 'inactive'"
                valign="middle"
              >
                <td class="text-center col-md-1">{{ index + 1 }}</td>
                <td>
                  {{ item.comment }}
                </td>
                <td>{{ item.playfabId }}</td>
                <td>{{ item.created }}</td>
                <td>
                  {{ item.adminName }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <!-- <td colspan="6" class="text-right">
                  <w-button
                    route="/player-support-history/new"
                    v-privilege="PRIVILEGES.DYNAMIC_SEGMENT_WRITE"
                    shadow
                    >Create New player Support</w-button
                  >
                </td> -->
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Loading..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);
    const SearchTerm = ref("");

    function Search() {
      fetching("/api/player-support-histories/", {
        searchTerm: SearchTerm.value,
      })
        .then((data) => {
          items.value = data;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the Player Support, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    }

    onBeforeMount(() => {
      Search();
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items,
      isLoading,
      error,
      Search,
      SearchTerm,
      PRIVILEGES,
      handleError,
    };
  },
};
</script>
