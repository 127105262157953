export default {
    setUser(state, payload) {

        state.token =  payload.token;
        state.userId = payload.Email || payload.userId ;
        state.name = payload.name || payload.Name;
        state.privileges = payload.privileges || payload.Privileges;
        state.roles = payload.roles || payload.Roles;
        // state.tokenExpiration = payload.tokenExpiration;


        localStorage.setItem('token', state.token);
        localStorage.setItem('userId', state.userId);

    },
    setAutoLogout(state) {
        state.didAutoLogout = true;
    }
};