<template>
  <w-app>
    <div>
      <h5>Active Log</h5>
      <ul>
        <li v-for="[key, value] of Object.entries(QuestLinesLog)"
             :key="key"
             v-show="QuestLinesLog !== null">
          <b>{{key}}</b>
          <w-button @click="stopQuestLine(key)" bg-color="warning" v-privilege="PRIVILEGES.PLAYER_WRITE">Stop QuestLine</w-button>
          <w-timeline :items="getQuestLines(key, value)"></w-timeline>
        </li>
      </ul>
      <hr>
      <h5>Inactive Log</h5>
      <ul>
        <li v-for="[key, value] of Object.entries(nonQuestLineData)"
            :key="key"
            v-show="QuestLinesLog !== null">
          <b>{{key}}</b>
          <w-button @click="startQuestLine(key)" v-privilege="PRIVILEGES.PLAYER_WRITE">Start QuestLine</w-button>
          <w-timeline :items="getQuestLines(key, value)"></w-timeline>
        </li>
      </ul>
    </div>
  </w-app>

  <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading ..." persistent :width="400">
    <div class="spinner-border text-primary"/>
  </w-dialog>

  <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
    {{ error }}
    <w-flex justify-end>
      <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
    </w-flex>
  </w-dialog>

</template>
<script>
import {ref, onBeforeMount} from 'vue';
import { useStore } from 'vuex';
import {fetching} from "../../config/context";
import {useRoute} from "vue-router";
import {PRIVILEGES} from "../../config/context";

export default {
  components: {
  },
  emits: ['change-quest-log'],
  props: [
    'QuestLinesLog','getItemFullName','getItemDesc','getItemThumbnail'
  ],
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);

    const questLineData = JSON.parse(store.getters["title/questLineData"]);
    const nonQuestLineData = ref(null);
    const error = ref(null);


    onBeforeMount(() => {
      const tempLineData = JSON.parse ( JSON.stringify (questLineData) )//[...questLineData];
      Object.keys(props.QuestLinesLog).forEach(key => {
        delete tempLineData[key];
      })
      nonQuestLineData.value = tempLineData
    });

    const getQuestColor = (value,  idx ) => {
      if (parseInt(value) === parseInt(idx) + 1) {
        return 'green'
      } if (parseInt(value) > parseInt(idx) + 1) {
        return 'grey'
      } else{
        return 'grey'
      }
    }

    const getQuestLines = (x, stateValue) => {
      // console.log(questLineData[x])
      return questLineData[x].QuestIds.split(":").map((x, idx) => ({
        title: `<b>${props.getItemFullName({ItemId: x})} (${x}) </b>`,
        content: props.getItemDesc({ItemId: x}),
        color: getQuestColor(stateValue, idx)
      }));

    }


    const startQuestLine = (lineId) => {
      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/questLine/${lineId}/start`, { "method" : 'PUT' }).then( data => {

        if (data.errorMessage !== ""){
          throw {"message": data.errorMessage};
        }

        context.emit('change-quest-log');
        delete nonQuestLineData.value[lineId];

      }).catch(e => {
        error.value = e.message || 'Failed to start the QuestLine, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const stopQuestLine = (lineId) => {
      if(!confirm(`Are you sure to stop this QuestLine (${lineId})?`)) return;

      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/questLine/${lineId}/stop`, { "method" : 'PUT' }).then(data => {
        if (data.errorMessage !== ""){
          throw {"message": data.errorMessage};
        }
        context.emit('change-quest-log');
      }).catch(e => {
        error.value = e.message || 'Failed to stop the QuestLine, try later.';

      }).finally(() => {
        isLoading.value = false;
      });
    }


    const handleError = () => {
      error.value = null;
    }

    return {
      questLineData, error, isLoading, nonQuestLineData,
      getQuestLines, startQuestLine, stopQuestLine, handleError, PRIVILEGES
    };
  }
};
</script>
<style scoped>
ul {
  list-style: none;
  margin: 1em;
  margin-left: 0.5em !important;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

button {
  float: right;
}

</style>