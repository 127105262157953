const { VUE_APP_OKTA_CLIENT_ID, OKTA_TESTING_DISABLEHTTPSCHECK } = process.env

// const BASENAME = process.env.NODE_ENV === 'production' ? '/okta-hosted-login' : '';
const REDIRECT_URI = `${window.location.origin}/login/callback`;
export default {
  oidc: {
    clientId: VUE_APP_OKTA_CLIENT_ID || '0oai5ueh41Si5BjAe357',
    issuer: 'https://gearboxsoftware.okta.com',
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    }
  },
}
