<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>GM Detail</h2>
        <table class="table table-hover">
          <thead>
          <tr>
            <th colspan="4"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th class="col-md-2">UID</th>
            <td class="col-md-4">{{ item?.userId }}</td>
            <th>Status</th>
            <td id="status" :class="item?.status? 'active': 'inactive'">{{ item?.status }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ item?.email }}</td>
            <th>Roles</th>
            <td>
              <w-tag
                  outline
                  shadow
                  color="primary"
                  :key="index"
                  v-for="(item, index) in item?.roles.split(',')">
                {{item}}
              </w-tag>
            </td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{{ item?.name }}</td>
            <th>Privileges</th>
            <td>
              <span v-for="(item, index) in item?.privileges.split(',')" :key="index">
                <w-tag outline shadow color="primary">
                  {{item}}
                </w-tag>
              </span>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr >
            <td colspan="6" class="text-right">
              <w-button bg-color="info"
                        shadow
                        v-privilege="PRIVILEGES.ADMIN_WRITE"
                        @click="editUser(item?.userId)">
                edit
              </w-button>
              <w-button bg-color="success"
                        shadow
                        v-privilege="PRIVILEGES.ADMIN_WRITE"
                        :onclick="passwordResetting">
                send password reset link
              </w-button>

              <w-button bg-color="error"
                        shadow
                        v-privilege="PRIVILEGES.ADMIN_WRITE"
                        @click="deleteUser(item)">
                delete
              </w-button>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
      <div class="row" >
        <h2>Activity Logs</h2>
        <div align="center" v-show="!activityLogs">
          <div class="spinner-border text-primary"/>
        </div>
        <audit-list :items="activityLogs"/>
      </div>
    </div>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading ..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-notification
        v-model="showNotification"
        success
        plain
        round
        shadow
        absolute
        transition="bounce"
        show
    >
      Success to send the new password.
    </w-notification>
  </w-app>
</template>

<script>
import {fetching} from "../../config/context";
import {ref, onBeforeMount} from "vue";
import {useRoute, useRouter} from 'vue-router'
import AuditList from '../../components/audit/AuditList.vue'
import {PRIVILEGES} from "../../config/context";
export default {
  name: "AdminView",
  components: {
    AuditList
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const item = ref(null);
    const activityLogs = ref(null);

    const status = ref(false);

    const isLoading = ref(false);
    const error = ref(null);
    const showNotification = ref(false);
    const activityLogsLink = ref("");

    const passwordResetting = () => {

      if(confirm('Are you sure?\nDo you want to reset ' + item.value?.name + '\'s password?')){
        isLoading.value = true;
        const uuid = route.path.split("/").reverse()[0];

        const path = document.location.origin + "/public/setPassword";
        fetching('/api/admin/users/resetpassword/' + uuid + "?path=" + path).then( () => {
          showNotification.value = true;
        }).catch(e => {
          error.value = e.message || 'Failed to send the new password, try later.';
        }).finally( () => {
          isLoading.value = false;
        });
      }

    }

    const handleError = () => {
      error.value = null;
    };


    const deleteUser = (item) => {

      if(confirm('Are you sure?\nDo you really want to delete "' + item.name + '"?')){
        isLoading.value = true;

        fetching('/api/admin/users/' + item.userId, {
          method: 'DELETE',
        }).then( () => {
          router.push("/admin")
        }).catch(e => {
          error.value = e.message || 'Failed to delete the User, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
      }
    }

    const editUser = async (uuid) => {
      await router.push("/admin/edit/"+uuid);
    }

    onBeforeMount(() => {

      const uuid = route.path.split("/").reverse()[0];
      activityLogsLink.value = "/activityLog/" + uuid;

      isLoading.value = true;
      fetching('/api/admin/users/' + uuid, ).then( data => {
        item.value = data;
      }).catch(e => {
        error.value = e.message || 'Failed to get the data, try later.';
      }).finally(() => {
        isLoading.value = false;
      });


      let api = '/api/admin/activity/user/'+ uuid +'?size=1000';
      fetching(api).then( data  => {
        activityLogs.value = data;
        // console.table(data);
      }).catch(e => {
        error.value = e.message || 'Failed to get the User, try later.';
      });
    });

    return {
      status, isLoading, error, item, showNotification, activityLogs,
      handleError, passwordResetting, deleteUser, editUser, PRIVILEGES
    }
  }
};
</script>
<style scoped>
#status.active{
  color: green;
  font-weight: bold;
}
#status.inactive{
  color: red;
  font-weight: bold;
}

</style>