<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Banned Player List List</h2>
        <div style="margin-top: 20px">
          <input
            type="text"
            v-model="searchPlayerId"
            placeholder="Search Player"
          />
        </div>

        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Player Id</th>
                <th>time</th>
                <th>endtime</th>
                <th>remaining</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in items" :key="item.id">
                <tr scope="row">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">
                    {{ item.playerId }}
                    <br />
                  </td>
                  <td class="text-center">
                    {{ new Date(item.time).toLocaleString() }}
                  </td>
                  <td class="text-center">
                    {{ new Date(item.endTime).toLocaleString() }}
                  </td>

                  <td class="text-center">
                    <span v-ago="new Date(item.endTime)" />
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount, watch } from "vue";
import { fetching } from "../../config/context";

import { PRIVILEGES } from "../../config/context";

export default {
  name: "PremiumAccountLog",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const searchPlayerId = ref("");
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);

    const itemLanguageRadio = ref("");
    const itemLanguageClasses = ref(null);

    onBeforeMount(() => {
      fetchData();
    });

    function fetchData() {
      fetching(`/api/PremiumAccountLog?aPartOfPlayerId=${searchPlayerId.value}`)
        .then((data) => {
          items.value = data;
        })
        .catch((e) => {
          error.value = e.message || "Failed to load the data, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
    watch(searchPlayerId, function(newValue) {
      setTimeout(() => {
        if (newValue === searchPlayerId.value) {
          fetchData();
        }
      }, 300);
    });

    return {
      items,
      isLoading,
      error,
      fetchData,
      PRIVILEGES,
      itemLanguageClasses,
      itemLanguageRadio,
      searchPlayerId,
    };
  },
};
``;
</script>
<style scoped>
.inactivated {
  background-color: lightpink !important;
}

.outdated {
  background-color: lightsalmon;
}

td.activeText {
  color: green;
}
td.inactivatedText {
  color: orangered;
}
</style>
