<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Shop Management</h2>
        <div>
          <div v-show="items.length > 0">
            Total: <b>{{ items.length }}</b> results
          </div>
        </div>
        <div style="margin-top: 20px" v-show="items">
          <ag-grid-vue
            :style="'width: 100%; height: ' + innerHeight + 'px'"
            :rowClassRules="rowClassRules"
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="items"
            @gridReady="gridReady"
            :singleClickEdit="false"
            rowSelection="multiple"
            undoRedoCellEditing="true"
            @cellValueChanged="cellValueChanged"
          >
          </ag-grid-vue>
          <!--          rowDragManaged="true"-->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <w-button @click="removeItems" bg-color="error">Remove</w-button>
        </div>
        <div class="col text-right">
          <w-button @click="addNew" class="text-right">New</w-button>
          <w-button
            @click="showDiff"
            bg-color="success"
            v-privilege="PRIVILEGES.SHOP_WRITE"
            >Save</w-button
          >
        </div>
      </div>
    </div>
    <w-dialog v-model="isShowDiff" title="Compare ..." :fullscreen="true">
      <div class="row">
        <div class="col">
          <w-button @click="isShowDiff = !isShowDiff" bg-color="error"
            >Close</w-button
          >
        </div>
        <div class="col text-right">
          <w-button
            @click="saveTitleData"
            bg-color="success"
            v-privilege="PRIVILEGES.SHOP_WRITE"
            >Save</w-button
          >
        </div>
      </div>
      <div class="row">
        <json-diff
          :jsonSourceLeft="leftItems"
          :jsonSourceRight="rightItems"
          :showHeading="false"
          v-show="isShowDiff"
        />
      </div>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      :title="isLoading + '...'"
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-notification
      v-model="showNotification"
      error
      plain
      round
      shadow
      transition="bounce"
      show
      :timeout="2000"
      :bottom="true"
      :center="true"
      >{{ showNotification }}</w-notification
    >
  </w-app>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { ref, onBeforeMount } from "vue";
import { fetching } from "../../config/context";
import JsonDiff from "vue-json-diff";
import { PRIVILEGES } from "../../config/context";
export default {
  name: "Shop",
  components: {
    AgGridVue,
    JsonDiff,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const showNotification = ref(null);
    const isLoading = ref(false);
    const isShowDiff = ref(false);
    const error = ref(null);
    const gridApi = ref(null);

    const items = ref([]);
    const initCompareItems = ref([]);
    const leftItems = ref(null);
    const rightItems = ref(null);

    const columnDefs = ref(null);
    const innerHeight = ref(window.innerHeight - 275);

    const ROW_EDITED = "__edited";
    const ROW_DELETED = "__deleted";

    function getUniqueKeys() {
      let keysArray = ["ID"];
      items.value.forEach((x) => {
        keysArray = keysArray.concat(Object.keys(x));
      });

      return keysArray
        .filter((item, pos) => keysArray.indexOf(item) === pos) // Unique keys
        .map((k) => {
          if (k === "ID") {
            return {
              field: k,
              sortable: true,
              filter: true,
              editable: true,
              checkboxSelection: true,
            }; //, rowDrag: true, headerCheckboxSelection: true
          }
          return { field: k, sortable: true, filter: true, editable: true };
        });
    }

    function deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    }

    onBeforeMount(() => {
      isLoading.value = "Loading";

      fetching("/api/playfab/titleData?key=ShopData")
        .then((data) => {
          const shopData = JSON.parse(data.ShopData);
          items.value = deepCopy(
            Object.entries(shopData).map((x) => {
              const obj = x[1];
              obj["ID"] = x[0];
              return obj;
            })
          );

          initCompareItems.value = deepCopy(items.value);
          columnDefs.value = getUniqueKeys();
        })
        .catch((e) => {
          error.value = e.message || "Failed to revoke the item, try later.";
        })
        .finally(() => {
          isLoading.value = null;
        });
    });

    function addNew() {
      initCompareItems.value.unshift({ ID: "" });

      // updating
      const temp = items.value;
      temp.unshift({});
      items.value = [];

      setTimeout(() => {
        items.value = temp;
      }, 1);

      // effect
      const transaction = {
        add: [{}],
        addIndex: 0,
      };
      gridApi.value.applyTransaction(transaction);
    }
    const handleError = () => {
      error.value = null;
    };

    function cellValueChanged(e) {
      Object.keys(e).forEach((x) => {
        console.log(x);
        console.log(e[x]);
      });

      // only allow for new data... should think add __add in case of changing id value as new data.
      if (e.colDef.field === "ID" && e.oldValue !== undefined) {
        showNotification.value = "'ID' does not allow to change.";
        e.api.undoCellEditing();
        return;
      }

      // TODO: should be pretty
      if (
        ["PriceModifiers", "ItemRequirements", "ItemRestrictions"].indexOf(
          e.colDef.field
        ) > -1 &&
        e.newValue.indexOf(",") === -1 &&
        e.newValue.indexOf(",") === -1
      ) {
        showNotification.value = "Invalid Data Format";
        e.api.undoCellEditing();
        return;
      }

      e.data[ROW_EDITED] = true;

      const rows = [e.api.getDisplayedRowAtIndex(e.rowIndex)];
      e.api.redrawRows({ rowNodes: rows });
      e.api.flashCells({
        rowNodes: rows,
        columns: [e.colDef.field],
      });
    }

    const rowClassRules = {
      editedRow: (params) => {
        return params.data[ROW_EDITED];
      },
      errorRow: (params) => {
        return params.data.__error;
      },
    };

    function gridReady(event) {
      gridApi.value = event.api;
    }

    // https://www.ag-grid.com/vue-grid/data-update-transactions/#identifying-rows-for-update-and-remove
    function removeItems() {
      const count = gridApi.value.getSelectedRows().length;
      if (!confirm(count + " items would be removed?")) {
        return;
      }

      gridApi.value.getSelectedRows().forEach((x) => {
        initCompareItems.value.forEach((i) => {
          if (JSON.stringify(x) === JSON.stringify(i)) {
            i[ROW_DELETED] = true;
          }
        });

        items.value = items.value.filter(
          (i) => JSON.stringify(x) !== JSON.stringify(i)
        );
      });

      const transaction = {
        remove: gridApi.value.getSelectedRows(),
      };
      gridApi.value.applyTransaction(transaction);
    }

    function showDiff() {
      // const e = gridApi.value;
      // Object.keys((e)).forEach(x => {
      //   console.log(x)
      //   console.log(e[x])
      // });

      // short debug
      // leftItems.value = shopData;
      // rightItems.value = arrayToDictionary(JSON.parse(JSON.stringify(items.value)));

      const indexes = items.value
        .filter((x) => x[ROW_EDITED])
        .map((x) => items.value.indexOf(x));

      let tmpleftItems = indexes.map((x) => initCompareItems.value[x]); //.filter(x => Object.keys(x).length); // skip for now to be better understanding
      const deletedRow = initCompareItems.value.filter((x) => x[ROW_DELETED]);
      tmpleftItems = tmpleftItems.concat(deletedRow);

      const tmpItem = deepCopy(items.value);
      const tmprightItems = indexes
        .map((x) => tmpItem[x])
        .map((x) => {
          delete x[ROW_EDITED];
          return x;
        });

      rightItems.value = arrayToDictionary(tmprightItems);
      leftItems.value = arrayToDictionary(tmpleftItems);

      isShowDiff.value = true;
    }

    function arrayToDictionary(arr) {
      const tempDict = {};

      deepCopy(arr).forEach((x) => {
        const id = x.ID;
        delete x["ID"];
        delete x[ROW_EDITED];
        tempDict[id] = x;
      });

      return tempDict;
    }

    function saveTitleData() {
      isLoading.value = "Saving";
      isShowDiff.value = !isShowDiff.value;

      const body = {
        key: "ohsang",
        value: JSON.stringify(arrayToDictionary(items.value)),
      };

      fetching("/api/playfab/titleData", { method: "POST", body: body })
        .then((data) => {
          console.log("----------------");
          console.log(data);
        })
        .catch((e) => {
          error.value = e.message || "Failed to revoke the item, try later.";
        })
        .finally(() => {
          isLoading.value = null;
        });
    }

    return {
      items,
      columnDefs,
      isLoading,
      error,
      rowClassRules,
      isShowDiff,
      leftItems,
      rightItems,
      initCompareItems,
      showNotification,
      innerHeight,
      saveTitleData,
      removeItems,
      addNew,
      cellValueChanged,
      gridReady,
      handleError,
      showDiff,
      PRIVILEGES,
    };
  },
};
</script>
<style>
.editedRow {
  background-color: sandybrown !important;
}
.errorRow {
  background-color: lightcoral !important;
}

.wp-json .panel-body {
  padding: 30px 0 !important;
}

.CodeMirror pre {
  font-size: 15px;
}
</style>
<style>
pre span {
  padding: 2px;
}
</style>
<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
