<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>GM List</h2>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
            <tr class="table-active text-center">
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Roles</th>
              <th>Privileges</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="item.userId" scope="row" :class="(item.status)?'': 'inactive'"  valign="middle">
              <td class="text-center">{{index + 1}}</td>
              <td scope="row">
                <a :href="'/admin/view/'+item.userId">{{item.name}}</a>
              </td>
              <td>{{(item.email)}}</td>
              <td class="text-center">
                <w-tag outline shadow v-for="(item, index) in item.roles.split(',')" :key="index">
                  {{item}}
                </w-tag>
              </td>
              <td>{{item.privileges}}</td>
              <td class="text-center" :class="item.status? 'active':'inactive'">{{item.status}}</td>
              <td class="text-center">
                <w-button bg-color="info" shadow @click="editUser(item.userId)" v-privilege="PRIVILEGES.ADMIN_WRITE">edit</w-button>
                <w-button bg-color="error" shadow @click="deleteUser(item)" v-privilege="PRIVILEGES.ADMIN_WRITE">delete</w-button>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr >
              <td colspan="7" class="text-right">
                <w-button route="/admin/new" shadow v-privilege="PRIVILEGES.ADMIN_WRITE">Add New User</w-button>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>


    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import {fetching} from "../../config/context";
import {useRouter} from 'vue-router';
import {PRIVILEGES} from "../../config/context";

export default {
  name: "AdminList",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const router = useRouter();
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);

    onBeforeMount(() => {
      fetching( '/api/admin/users').then( data  => {
        // console.table(data);
        items.value = data;
      }).catch(e => {
        error.value = e.message || 'Failed to load the User, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });


    const deleteUser = (item) => {

      if(confirm('Are you sure?\nDo you really want to delete "' + item.name + '"?')){
        isLoading.value = true;

        fetching('/api/admin/users/' + item.userId, {
          method: 'DELETE',
        }).then( () => {
          let obj = JSON.parse(JSON.stringify(items))
          items.value = obj._rawValue.filter(function(i) { return i.userId != item.userId });

        }).catch(e => {
          error.value = e.message || 'Failed to delete the User, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
      }
    }

    const editUser = async (uuid) => {
      await router.push("/admin/edit/"+uuid);
    }

    const handleError = () => {
      error.value = null;
    };

    return {
      items, isLoading, error,
      deleteUser, handleError, editUser,
      PRIVILEGES
    };
  },
};
</script>
<style scoped>
.inactive {
  background-color: lightgray;
}

td.active{
  color: green;
}
td.inactive{
  color: orangered;
}
</style>
