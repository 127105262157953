<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>List of Invalid Clans</h2>

        <w-flex xl12 wrap>
          <w-button @click="RefreshInvalidClanDatabase()"
            >Refresh Invalid Clan</w-button
          >
          <w-button @click="ResolveIssuesForAllClans()"
            >Resolve all Clan Issues</w-button
          >
        </w-flex>

        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Clan Name</th>
                <th>Issues type</th>
                <th>Action</th>
                <th>IsEmpty</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.rowKey"
                scope="row"
                :class="item.status ? '' : 'inactive'"
                valign="middle"
              >
                <td class="text-center col-md-1">{{ index + 1 }}</td>
                <td>
                  <a target="_blank" :href="'/clans/edit/' + item.id">{{
                    item.clanName
                  }}</a>
                </td>
                <td>
                  <w-flex wrap>
                    <div v-for="message in item.issues" :key="message">
                      <w-tooltip color="white" bg-color="blue">
                        <template #activator="{ on }">
                          <w-tag v-on="on"> {{ message.message }}</w-tag>
                        </template>
                        <w-card shadow bg-color="blue" title="Issue Details">
                          <w-tag bg-color="blue"
                            >Message : {{ message.message }}</w-tag
                          >
                          <w-tag bg-color="blue">
                            Type : {{ message.type }}</w-tag
                          >
                          <w-tag bg-color="blue"
                            >TypeValue : : {{ message.typeValue }}</w-tag
                          >
                        </w-card>
                      </w-tooltip>
                    </div>
                  </w-flex>
                </td>
                <td>
                  <w-button @click="ResolveIssuesForAClan(item.id)" shadow>
                    Solve Issues
                  </w-button>
                </td>
                <td>
                  <w-tag> {{ item.isEmpty }}</w-tag>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" class="text-right"></td>
              </tr>
            </tfoot>
          </table>
          <w-button
            @click="RestoreClanIssueBackupsAllClans()"
            v-privilege="PRIVILEGES.Clan_WRITE"
            shadow
            bg-color="error"
            >Restore Clan Issue Backups</w-button
          >
          <w-button
            @click="ClearClanBackupDatabaseAllClans()"
            v-privilege="PRIVILEGES.Clan_WRITE"
            shadow
            bg-color="error"
            >Clear Clan Backup Database</w-button
          >
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Loading..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";

export default {
  name: "Clan",
  components: {},
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);
    const ClanName = ref("");
    const ClanAcronym = ref("");
    const ShowDiscription = ref(false);
    const clanIds = ref([]);

    const fetch = () => {
      fetching("/api/resolver/GetInvalidClans", { method: "Get" })
        .then((data) => {
          items.value = data.clanIssues;
        })
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const RefreshInvalidClanDatabase = () => {
      isLoading.value = true;
      fetching("/api/resolver/RefreshInvalidClanDatabase", { method: "Get" })
        .then(() => {})
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const ResolveIssuesForAClan = (clanId) => {
      clanIds.value = [clanId];
      ResolveDatabaseClanIssues();
    };

    const ResolveIssuesForAllClans = () => {
      clanIds.value = items.value.map((item) => item.id);
      ResolveDatabaseClanIssues();
    };

    const ResolveDatabaseClanIssues = () => {
      isLoading.value = true;
      const apiBody = [];
      for (let i = 0; i < clanIds.value.length; i++) {
        apiBody.push(clanIds.value[i]);
      }
      fetching("/api/resolver/ResolveDatabaseClanIssues", {
        method: "POST",
        body: apiBody,
      })
        .then(() => {})
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    // -----------------Restore Clan Issue Backups---------------------
    const RestoreClanIssueBackupsAllClans = () => {
      clanIds.value = items.value.map((item) => item.id);
      RestoreClanIssueBackups();
    };

    const RestoreClanIssueBackups = () => {
      isLoading.value = true;
      const apiBody = [];
      for (let i = 0; i < clanIds.value.length; i++) {
        apiBody.push(clanIds.value[i]);
      }
      fetching("/api/resolver/RestoreClanIssueBackups", {
        method: "POST",
        body: apiBody,
      })
        .then(() => {})
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    //-----------------ClearClanBackupDatabase---------------------
    const ClearClanBackupDatabaseAllClans = () => {
      isLoading.value = true;
      fetching("/api/resolver/ClearClanBackupDatabase", {
        method: "get",
      })
        .then(() => {})
        .catch((e) => {
          error.value = e.message || "Failed to load the Clans, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    onBeforeMount(() => {
      fetch();
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items,
      isLoading,
      error,
      PRIVILEGES,
      ClanName,
      ClanAcronym,
      handleError,
      fetch,
      ShowDiscription,
      ResolveDatabaseClanIssues,
      ResolveIssuesForAClan,
      ResolveIssuesForAllClans,
      RefreshInvalidClanDatabase,

      RestoreClanIssueBackups,
      RestoreClanIssueBackupsAllClans,
      ClearClanBackupDatabaseAllClans,
    };
  },
};
</script>
