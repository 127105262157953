<template>
  <w-app>
    <h1>Announcement</h1>
    <hr/>
  </w-app>
</template>

<script>
export default {
  name: "Announcement"
}
</script>

<style scoped>

</style>