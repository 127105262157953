<template>
  <w-app>
    <w-dialog v-model="isLoading" align="center" title="Processing..." transition="bounce" :width="400">
      <w-spinner />
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

  </w-app>
</template>
<script>
import {onBeforeMount, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';

export default {
  name: 'Okta',
  setup() {
    const router = useRouter();
    const store = useStore();
    const error = ref(null);
    const isLoading = ref(false);

    onBeforeMount(async () => {
      isLoading.value = true;
      try {
        const okta_token_storage = localStorage.getItem('okta-token-storage');
        const accessToken = JSON.parse(okta_token_storage).accessToken.accessToken;

        await store.dispatch('loginWithOkta', accessToken);
        await router.replace('/');
      } catch (err) {
        error.value = err.message || 'Failed, try later.';
      } finally {
        isLoading.value = false;
      }
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      error, isLoading, handleError
    }
  }
}
</script>