<template>
  <w-app>
    <div>

      <date-picker v-model="range"
                   mode="dateTime"
                   is-range
                   is24hr
                   :model-config=modelConfig
                   :minute-increment="5">
        <template v-slot="{ inputValue, inputEvents }">
          <div class="flex justify-center items-center">
            Date range:
            <input
                :value="inputValue.start"
                v-on="inputEvents.start"
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            />
            ~
            <input
                :value="inputValue.end"
                v-on="inputEvents.end"
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            />
          </div>
          <div v-show="berlinTime">
            <hr>
            <table class="table table-bordered table-hover">
              <tbody>
              <tr>
                <th>UTC</th>
                <td>{{utcTime}}</td>
              </tr>
              <tr>
                <th>Berlin</th>
                <td>{{berlinTime}}</td>
              </tr>
              <tr>
                <th>US West</th>
                <td>{{usWestTime}}</td>
              </tr>
              <tr>
                <th>US East</th>
                <td>{{usEastTime}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
      </date-picker>
      <div>
        Player ID : <w-radios
          v-model="playerIdRadio"
          :items="playerIdClasses"
          inline
          color="blue">
        <template #item="{ item }">
          <span > {{item.label}}</span>
        </template>
      </w-radios>

      </div>
      <w-button bg-color="success" @click="getChatHistory">Search</w-button>
    </div>
    <hr>
    <div>
      <base-search @search="updateSearch" :search-term="enteredSearchTerm"/>
    </div>
    <div>
      Sorting by Created:
      <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
      <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
    </div>
    <div>
      Total : <b>{{numberFormatting(displayedItems.length)}}</b>
    </div>
    <table class="table table-bordered table-hover table-striped">
      <thead class="thead-dark">
      <tr class="table-active">
        <th scope="col" class="text-center">#</th>
        <th v-for="key in columns" :key="key" scope="col" class="text-center">{{columnName(key)}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in displayedItems" :key="item.id" valign="middle">
        <th scope="row">{{ numberFormatting(index + 1) }}</th>
        <td v-for="key in columns" :key="key" scope="row">
          <span>{{item[key]}}</span>
          <div>
            <span v-if="key === 'writeAt'" v-ago="item['writeAt']"></span>
          </div>
        </td>
      </tr>
      <tr v-show="displayedItems.length === 0">
        <td :colspan="columns.length + 1" class="text-center">No Chats</td>
      </tr>
      </tbody>
    </table>
  </w-app>

  <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading ..." persistent :width="400">
    <div class="spinner-border text-primary"/>
  </w-dialog>

  <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
    {{ error }}
    <w-flex justify-end>
      <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
    </w-flex>
  </w-dialog>
</template>
<script>
import {ref, onBeforeMount, watch} from 'vue';
import {fetching} from "../../config/context";
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import { DatePicker } from 'v-calendar';

export default {
  props: [
    'playerId', 'playerName'
  ],
  components: {
    DatePicker,
  },
  setup(prop) {
    const error = ref(null);
    const isLoading = ref(false);
    const columns = ["playerId", "displayName", "message", "writeAt"];

    const playerIdRadio = ref(prop.playerId);
    const playerIdClasses = ref(null);

    const items = ref([]);

    const inputValue = ref(null);
    const inputEvents = ref(null);
    const locale = ref(null);

    const utcTime = ref(null);
    const berlinTime = ref(null);
    const usWestTime = ref(null);
    const usEastTime = ref(null);

    const range = ref(null);

    const modelConfig = ref({
      type: 'date',
      mask: 'YYYY-MM-DD HH:mm'
    });

    watch(range, () => {
      if (typeof range.value.start === 'string') return;

      utcTime.value =
          range.value.start.toLocaleString('en-US', { timeZone: "utc", hour12: false })
          + "~" +
          range.value.end.toLocaleString('en-US', { timeZone: "utc", hour12: false }) ;
      berlinTime.value =
          range.value.start.toLocaleString('de-DE', { timeZone: "Europe/Berlin", hour12: false })
          + "~" +
          range.value.end.toLocaleString('de-DE', { timeZone: "Europe/Berlin", hour12: false }) ;
      usWestTime.value =
          range.value.start.toLocaleString('en-US', { timeZone: "America/Los_Angeles", hour12: false })
          + "~" +
          range.value.end.toLocaleString('en-us', { timeZone: "America/Los_Angeles", hour12: false }) ;
      usEastTime.value =
          range.value.start.toLocaleString('en-US', { timeZone: "America/New_York", hour12: false })
          + "~" +
          range.value.end.toLocaleString('en-us', { timeZone: "America/New_York", hour12: false }) ;
    });

    const _toDateString = (o) => {
      if(typeof o === 'object') o = o.toISOString();
      return o.substring(0, 16);
    }

    const getChatHistory = () => {
      isLoading.value = true;

      let start = _toDateString(range.value.start);
      let end = _toDateString(range.value.end)

      fetching(`/api/chat/${playerIdRadio.value}/from/${start}/to/${end}`).then( data => {
        items.value = data;
      }).catch(e => {
        console.log(e);
        error.value = e.message || 'Failed to load the item from deltaDNA, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    };

    onBeforeMount(() => {
      playerIdClasses.value = [
        {label: "All", value: ""},
        {label: prop.playerName, value: prop.playerId}
      ];
      sorting.value = 'desc';

      range.value = {
        start: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
        end: new Date().toISOString()
      };

      getChatHistory();
    });

    const nf = Intl.NumberFormat();
    const numberFormatting = (x) => {
      return nf.format(x);
    }

    const columnName = (name) => {
      const dict = {
        "playerId": "Player Id",
        "displayName": "Name",
        "message": "Chat",
        "writeAt": "Date"
      }

      return dict[name];
    }

    const handleError = () => {
      error.value = null;
    }

    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['message']
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        'writeAt'
    );

    return {
      isLoading, error, columns, modelConfig, getChatHistory,
      handleError,  numberFormatting, columnName, playerIdClasses, playerIdRadio,
      inputValue, inputEvents, range, locale, berlinTime, usWestTime, usEastTime, utcTime,
      enteredSearchTerm,
      updateSearch,
      displayedItems,
      sorting,
      sort,
      availableItems
    };
  }
};
</script>