<template>
  <w-button class="px4" @click="showDialogFunc()">
    Player search
  </w-button>

  <w-transition-fade>
    <w-dialog
      v-model="dialogshow"
      :width="'57%'"
      title-class="primary-light1--bg white"
    >
      <template #title>
        <w-icon class="mr2">mdi mdi-account-search</w-icon>
        Search Players
      </template>

      <template #default>
        <textarea
          name="clipboardTextarea"
          id="clipboardTextarea"
          v-on:focus="$event.target.select()"
          :value="SelectedPlayer"
          ref="SelectedPlayersDiv"
        ></textarea>

        <div class="container-fluid">
          <div class="row">
            <div>
              <w-input
                ref="searchBox"
                class="mt3"
                label="Search (Player ID, Display Name)"
                v-model.trim="searchTerm"
                outline
              ></w-input>
            </div>

            <div align="center" style="margin: 20px" v-show="isLoading">
              <h3>Loading ...</h3>
              <div class="spinner-border text-primary" />
            </div>

            <div style="margin-top: 20px" v-show="items?.results">
              <ag-grid-vue
                :style="'width: 100%; height:  250px'"
                class="ag-theme-alpine"
                :rowData="items?.results"
                :singleClickEdit="false"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :pagination="true"
                rowSelection="single"
                :onRowDoubleClicked="rowDoubleClicked"
              >
              </ag-grid-vue>
            </div>
            <div class="ma1 pa1">
              Total:
              <b>{{ Number(items?.totalCount).toLocaleString() }}</b> players
              (Up to <b>2</b> minutes delayed data)
            </div>
          </div>

          <div class="bd1 ma3 pa2">
            <w-flex wrap>
              <div
                v-for="item in SelectedPlayer"
                :key="item.id"
                class="box xs2"
              >
                {{ item }},
              </div>
            </w-flex>
          </div>
        </div>
      </template>

      <template #actions>
        <div class="spacer" />
        <w-button @click="ClearAll()">Clear all </w-button>
        <w-button @click="CopyToClipboard()">Copy To clipboard</w-button>
        <w-button @click="dialogshow = false">Close</w-button>
      </template>
    </w-dialog>
  </w-transition-fade>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref, onBeforeMount, watch } from "vue";
import { useRouter } from "vue-router";
import { fetching } from "../../config/context";
import moment from "moment";

export default {
  name: "PlayerList",

  components: {
    AgGridVue,
  },

  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    CopyToClipboard: function() {
      var myTarget = document.getElementById("clipboardTextarea");
      myTarget.select();
      myTarget.setSelectionRange(0, 99999); //for mobile
      document.execCommand("copy");
    },
  },
  setup() {
    const dialogshow = ref(false);
    const searchBox = ref(null);
    const router = useRouter();
    const startPage = ref(0);
    const endPage = ref(0);
    const items = ref(null);
    const playfabId = ref("402517DCA10D278D");
    const SelectedPlayer = ref([]);

    const formIsValid = ref(playfabId.value != null);
    const isLoading = ref(false);
    const error = ref(null);
    const searchTerm = ref(null);
    const page = ref(1);
    const innerHeight = ref(window.innerHeight - 275);

    const defaultColDef = ref({
      sortable: true,
      resizable: true,
    });

    function showDialogFunc() {
      dialogshow.value = true;
    }

    function ClearAll() {
      SelectedPlayer.value = [];
    }

    const columnDefs = reactive([
      {
        headerName: "PlayerID",
        field: "playerId",
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Name",
        field: "displayName",
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Created",
        field: "createdAgo",
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "LastLogin",
        field: "lastLoginAgo",
        sortable: true,
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Location",
        field: "location",
        sortable: true,
        filter: "agTextColumnFilter",
      },
    ]);

    const playerSelect = (playerId) => {
      SelectedPlayer.value.push(playerId);
    };
    const submitForm = () => {
      if (!formIsValid.value) {
        return;
      }
      router.push("/players/" + playfabId.value); //todo: magic string
    };
    const validators = {
      required: (value) => !!value || "This field is required",
    };

    onBeforeMount(() => {
      searchUser("");
    });

    watch(searchTerm, function(newValue) {
      setTimeout(() => {
        if (newValue === searchTerm.value) {
          searchUser(newValue);
          page.value = 1;
        }
      }, 300);
    });

    const searchUser = function(query) {
      if (query === undefined) query = "*";

      items.value = null;
      isLoading.value = true;
      let api = "/api/players?";
      if (query !== "*") {
        api += "&search=" + query;
      } else {
        api = "/api/players/cached?";
      }

      if (page.value !== "") {
        api += "&page=" + page.value;
      }

      api += "&pageSize=20"; // + Number.MAX_SAFE_INTEGER;

      fetching(api)
        .then((data) => {
          items.value = data;

          startPage.value = parseInt(
            Math.floor((data.currentPage - 1) / 10) + "1"
          );
          endPage.value = Math.ceil(data.currentPage / 10) * 10;

          if (data.totalPageCount < endPage.value) {
            endPage.value = Math.min(endPage.value, data.totalPageCount);
          }

          data.results.map(
            (x) =>
              (x.location =
                x.continentCode + " / " + x.countryCode + " / " + x.city)
          );
          data.results.map(
            (x) =>
              (x.valuesToDate =
                x.valuesToDate.length == 2 ? "" : x.valuesToDate / 100)
          );

          data.results.map(
            (x) => (x.lastLoginAgo = moment(x.lastLogin).fromNow())
          );
          data.results.map((x) => (x.createdAgo = moment(x.created).fromNow()));
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the list of Players, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const range = (start, end) =>
      [...Array(end - start + 1)].map((_, i) => start + i);

    const goTo = function(p) {
      page.value = p;
      searchUser();
    };

    const rowDoubleClicked = function(e) {
      const index = SelectedPlayer.value.findIndex(
        (object) => object === e.data.playerId
      );
      if (index === -1) {
        SelectedPlayer.value.push(e.data.playerId);
      }
    };

    return {
      playfabId,
      searchTerm,
      items,
      page,
      columnDefs,
      innerHeight,
      defaultColDef,
      formIsValid,
      isLoading,
      error,
      validators,
      startPage,
      endPage,
      showDialogFunc,
      searchBox,
      ClearAll,
      submitForm,
      searchUser,
      goTo,
      range,
      rowDoubleClicked,
      dialogshow,
      playerSelect,
      SelectedPlayer,
    };
  },
};
</script>
<style scoped>
li {
  display: inline-block;
  margin: 0 1px;
}

textarea {
  resize: none;
  border: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -10;
}
</style>
