import { ref } from "vue";

const ActivePlayerArrayList = ref(null);
const executionCount = ref(0);
const totalExecutionCount = ref(0);
const progress = ref(0);

function addActivePlayerArrayList(item) {
    ActivePlayerArrayList.value.push(item);
}
function getActivePlayerArrayList() {
    return ActivePlayerArrayList;
}

function ActivePlayerArrayListLen() {
    return ActivePlayerArrayList.value.length;
}


function MakePrograss() {
    progress.value = parseFloat(((executionCount.value + 1) / ActivePlayerArrayListLen()) * 100).toFixed(2);

}

export {
    ActivePlayerArrayList,
    addActivePlayerArrayList,
    getActivePlayerArrayList,
    ActivePlayerArrayListLen,
    executionCount,
    totalExecutionCount,
    MakePrograss,
    progress,

};
