<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Players in <b>{{ segmentName }}</b></h2>
        <div>Total: <b>{{numberFormatting(profilesInSegment)}}</b> players </div>
        <div>Page: {{currentPage}} / {{totalPage}}</div>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
            <tr class="table-active text-center">
              <th>#</th>
              <th>Player Id</th>
              <th>Display Name</th>
              <th>Created</th>
              <th>Last Login</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="item.rowKey" scope="row" :class="(item.status)?'': 'inactive'"  valign="middle">
              <td scope="row" class="text-center">{{ (currentPage - 1) * maxBatchSize + index + 1}}</td>
              <td><a :href='"/players/" + item.PlayerId'>{{(item.PlayerId)}}</a></td>
              <td>{{(item.DisplayName)}}</td>
              <td>{{(item.Created)}}</td>
              <td v-ago="item.LastLogin"></td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="5" valign="middle">
                  <nav aria-label="Page navigation"  v-show="continuationToken">
                    <ul class="pagination justify-content-center">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Prev</a>
                      </li>
                      <li class="page-item"  v-for="i in totalPage" :key="i"
                          :class="(i == currentPage)? 'active' : (i <= currentPage)? '' : 'disabled' ">
                        <a class="page-link" href="#">{{ i }}</a>
                      </li>
                      <li class="page-item" :class="(currentPage < totalPage)? '':'disabled'">
                        <a class="page-link" href="#" @click="goToNext">Next</a>
                      </li>
                    </ul>
                  </nav>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Load..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import {fetching} from "../../config/context";
import {useRoute} from 'vue-router';
export default {
  name:'SegmentsPlayerList',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const segmentName = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const loading = ref(true);
    const uuid = ref(true);
    const items = ref([]);
    const profilesInSegment = ref(0);
    const continuationToken = ref(null);
    const maxBatchSize = ref(100);
    const currentPage = ref(0);
    const totalPage = ref(0);

    const headers = ref([
      { field: 'PlayerId' , sortable: false, filter: false, resizable: true },
      { field: 'DisplayName', sortable: true, filter: false, resizable: true  },
      { field: 'Created', sortable: false, filter: false, resizable: true  },
      { field: 'LastLogin', sortable: false, filter: false, resizable: true  },
    ]);

    function getPlayerInSegments() {
      isLoading.value = true;
      uuid.value = route.path.split("/").reverse()[1];

      let path = '/api/playfab/segments/' + uuid.value + '/players?maxBatchSize=' + maxBatchSize.value;
      if (continuationToken.value !== null) path += '&continuationToken=' + continuationToken.value

      fetching(path).then(data => {
        items.value = data.PlayerProfiles;
        profilesInSegment.value = data.ProfilesInSegment;
        continuationToken.value = data.ContinuationToken;

        currentPage.value += 1;
        totalPage.value = Math.ceil(profilesInSegment.value / maxBatchSize.value);

      }).catch(e => {
        error.value = e.message || 'Failed to load the Players in this Segments, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    onBeforeMount(() => {
      segmentName.value = route.query.name;
      getPlayerInSegments();
    });

    const goToNext = async () => {
      getPlayerInSegments();
    }

    const handleError = () => {
      error.value = null;
    };

    const nf = Intl.NumberFormat();
    const numberFormatting = (x) => {
      return nf.format(x);
    }

    return {
      uuid, profilesInSegment, continuationToken, currentPage, totalPage,
      headers, items, loading, isLoading, error, maxBatchSize, segmentName,
      handleError, goToNext, numberFormatting
    };
  },
};
</script>
<style scoped>
h2 b {
  color: lightcoral;
}
</style>

