<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <h1>Live Sheet</h1>
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>Title Data </h2>
        <div class="w-flex wrap align-center">
          <w-tag v-model="globalVariables">globalVariables</w-tag>
          <w-tag v-model="shopData">shopData</w-tag>
          <w-tag v-model="stringData">stringData</w-tag>

          <w-tag v-model="blueprintData">blueprintData</w-tag>
          <w-tag v-model="colorData">colorData</w-tag>
          <w-tag>combatVariables</w-tag>
          <w-tag>combatVariables_QDB</w-tag>
          <w-tag>conditionalStatModData</w-tag>
          <w-tag>conditionalStatModData_QDB</w-tag>
          <w-tag>dialogSequenceData</w-tag>
          <w-tag>econModData</w-tag>
          <w-tag>economyConfig</w-tag>
          <w-tag>effectAreas</w-tag>
          <w-tag>effectAreas_QDB</w-tag>
          <w-tag>enemySetData</w-tag>
          <w-tag>externalModuleData</w-tag>
          <w-tag>externalModuleData_QDB</w-tag>
          <w-tag>factionData</w-tag>
          <w-tag>factionEffectsList</w-tag>

          <w-tag>internalModuleData</w-tag>
          <w-tag>itemData</w-tag>
          <w-tag>kiithData</w-tag>
          <w-tag>layoutData</w-tag>
          <w-tag>levelData</w-tag>
          <w-tag>lootData</w-tag>
          <w-tag>miningData</w-tag>
          <w-tag>missionData</w-tag>
          <w-tag>missionStepActions</w-tag>
          <w-tag>missionStepActions_QDB</w-tag>
          <w-tag>missionSteps</w-tag>
          <w-tag>missionSteps_QDB</w-tag>
          <w-tag>missionTemplateData</w-tag>
          <w-tag>officerData</w-tag>
          <w-tag>officerData_QDB</w-tag>
          <w-tag>officerEffectsData</w-tag>
          <w-tag>officerNameData</w-tag>
          <w-tag>officerPortraitData</w-tag>
          <w-tag>officerSlotConfig</w-tag>
          <w-tag>officerSlotConfig_QDB</w-tag>
          <w-tag>officerStatModData</w-tag>
          <w-tag>penaltyData</w-tag>
          <w-tag>premiumShopData</w-tag>
          <w-tag>profileVersionData</w-tag>
          <w-tag>pushNotifications</w-tag>
          <w-tag>questData</w-tag>
          <w-tag>questLineData</w-tag>
          <w-tag>questTemplateData</w-tag>
          <w-tag>researchData</w-tag>
          <w-tag>rewardData</w-tag>
          <w-tag>sellData</w-tag>

          <w-tag>skillData</w-tag>
          <w-tag>skillData_QDB</w-tag>
          <w-tag>starSystemConfigData</w-tag>
          <w-tag>starSystemData</w-tag>
          <w-tag>statModData</w-tag>
          <w-tag>statModData_QDB</w-tag>
          <w-tag>status</w-tag>

          <w-tag>uIHightlightData</w-tag>
          <w-tag>unitData</w-tag>
          <w-tag>unitData_QDB</w-tag>
          <w-tag>utilitySystemData</w-tag>
          <w-tag>utilitySystemData_QDB</w-tag>
          <w-tag>valueBasedStatMod</w-tag>
          <w-tag>valueBasedStatMod_QDB</w-tag>
          <w-tag>versionControl</w-tag>
          <w-tag>weaponEffects</w-tag>
          <w-tag>weaponEffects_QDB</w-tag>
          <w-tag>weaponSpecialEffects</w-tag>
          <w-tag>weaponSpecialEffects_QDB</w-tag>
          <w-tag>weaponSystemData</w-tag>
          <w-tag>weaponSystemData_QDB</w-tag>
        </div>
        <div class="ma1">
          <json-viewer :value="renderData"
                       :expand-depth=10
                       copyable
                       boxed
                       sort />
        </div>
        <!--        <div>blueprintData: {{blueprintData.length}}</div>-->
<!--        <div>colorData: {{colorData.length}}</div>-->
<!--        <div>combatVariables: {{combatVariables.length}}</div>-->
<!--        <div>combatVariables_QDB: {{combatVariables_QDB.length}}</div>-->
<!--        <div>conditionalStatModData: {{conditionalStatModData.length}}</div>-->
<!--        <div>conditionalStatModData_QDB: {{conditionalStatModData_QDB.length}}</div>-->
<!--        <div>dialogSequenceData: {{dialogSequenceData.length}}</div>-->
<!--        <div>econModData: {{econModData.length}}</div>-->
<!--        <div>economyConfig: {{economyConfig.length}}</div>-->
<!--        <div>effectAreas: {{effectAreas.length}}</div>-->
<!--        <div>effectAreas_QDB: {{effectAreas_QDB.length}}</div>-->
<!--        <div>enemySetData: {{enemySetData.length}}</div>-->
<!--        <div>externalModuleData: {{externalModuleData.length}}</div>-->
<!--        <div>externalModuleData_QDB: {{externalModuleData_QDB.length}}</div>-->
<!--        <div>factionData: {{factionData.length}}</div>-->
<!--        <div>factionEffectsList: {{factionEffectsList.length}}</div>-->
<!--        <div>globalVariables: {{globalVariables.length}}</div>-->
<!--        <div>internalModuleData: {{internalModuleData.length}}</div>-->
<!--        <div>itemData: {{itemData.length}}</div>-->
<!--        <div>kiithData: {{kiithData.length}}</div>-->
<!--        <div>layoutData: {{layoutData.length}}</div>-->
<!--        <div>levelData: {{levelData.length}}</div>-->
<!--        <div>lootData: {{lootData.length}}</div>-->
<!--        <div>miningData: {{miningData.length}}</div>-->
<!--        <div>missionData: {{missionData.length}}</div>-->
<!--        <div>missionStepActions: {{missionStepActions.length}}</div>-->
<!--        <div>missionStepActions_QDB: {{missionStepActions_QDB.length}}</div>-->
<!--        <div>missionSteps: {{missionSteps.length}}</div>-->
<!--        <div>missionSteps_QDB: {{missionSteps_QDB.length}}</div>-->
<!--        <div>missionTemplateData: {{missionTemplateData.length}}</div>-->
<!--        <div>officerData: {{officerData.length}}</div>-->
<!--        <div>officerData_QDB: {{officerData_QDB.length}}</div>-->
<!--        <div>officerEffectsData: {{officerEffectsData.length}}</div>-->
<!--        <div>officerNameData: {{officerNameData.length}}</div>-->
<!--        <div>officerPortraitData: {{officerPortraitData.length}}</div>-->
<!--        <div>officerSlotConfig: {{officerSlotConfig.length}}</div>-->
<!--        <div>officerSlotConfig_QDB: {{officerSlotConfig_QDB.length}}</div>-->
<!--        <div>officerStatModData: {{officerStatModData.length}}</div>-->
<!--        <div>penaltyData: {{penaltyData.length}}</div>-->
<!--        <div>premiumShopData: {{premiumShopData.length}}</div>-->
<!--        <div>profileVersionData: {{profileVersionData.length}}</div>-->
<!--        <div>pushNotifications: {{pushNotifications.length}}</div>-->
<!--        <div>questData: {{questData.length}}</div>-->
<!--        <div>questLineData: {{questLineData.length}}</div>-->
<!--        <div>questTemplateData: {{questTemplateData.length}}</div>-->
<!--        <div>researchData: {{researchData.length}}</div>-->
<!--        <div>rewardData: {{rewardData.length}}</div>-->
<!--        <div>sellData: {{sellData.length}}</div>-->
<!--        <div>shopData: {{shopData.length}}</div>-->
<!--        <div>skillData: {{skillData.length}}</div>-->
<!--        <div>skillData_QDB: {{skillData_QDB.length}}</div>-->
<!--        <div>starSystemConfigData: {{starSystemConfigData.length}}</div>-->
<!--        <div>starSystemData: {{starSystemData.length}}</div>-->
<!--        <div>statModData: {{statModData.length}}</div>-->
<!--        <div>statModData_QDB: {{statModData_QDB.length}}</div>-->
<!--        <div>status: {{status.length}}</div>-->
<!--        <div>stringData: {{stringData.length}}</div>-->
<!--        <div>uIHightlightData: {{uIHightlightData.length}}</div>-->
<!--        <div>unitData: {{unitData.length}}</div>-->
<!--        <div>unitData_QDB: {{unitData_QDB.length}}</div>-->
<!--        <div>utilitySystemData: {{utilitySystemData.length}}</div>-->
<!--        <div>utilitySystemData_QDB: {{utilitySystemData_QDB.length}}</div>-->
<!--        <div>valueBasedStatMod: {{valueBasedStatMod.length}}</div>-->
<!--        <div>valueBasedStatMod_QDB: {{valueBasedStatMod_QDB.length}}</div>-->
<!--        <div>versionControl: {{versionControl.length}}</div>-->
<!--        <div>weaponEffects: {{weaponEffects.length}}</div>-->
<!--        <div>weaponEffects_QDB: {{weaponEffects_QDB.length}}</div>-->
<!--        <div>weaponSpecialEffects: {{weaponSpecialEffects.length}}</div>-->
<!--        <div>weaponSpecialEffects_QDB: {{weaponSpecialEffects_QDB.length}}</div>-->
<!--        <div>weaponSystemData: {{weaponSystemData.length}}</div>-->
<!--&lt;!&ndash;        <div>weaponSystemData_QDB: {{weaponSystemData_QDB.length}}</div>&ndash;&gt;-->
<!--        versionControl = '{{versionControl}}'-->

      </div>
    </div>
  </w-app>
</template>

<script>
// import VueJsonPretty from 'vue-json-pretty';
// import 'vue-json-pretty/lib/styles.css';
// import 'vue-json-viewer/style.css'
// import JsonViewer from "vue3-json-viewer"

import {useStore} from 'vuex';
import {computed, ref} from 'vue';

export default {
  components : {
    // JsonViewer
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const blueprintData = ref(false);
    const colorData = ref(false);
    const globalVariables = ref(false);
    const stringData = ref(false);
    const shopData = ref(false);
    const store = useStore();

    // console.log(typeof itemData);
    // const viewData = computed() => {
    //   const itemData = JSON.parse(store.getters["title/itemData"]);
    //   return itemData
    // }

    const renderData = computed(() => {
      let endName = "itemData";

      if (blueprintData.value === true){
        endName = "blueprintData";
      } else if (colorData.value === true){
        endName = "colorData";
      } else if (globalVariables.value === true){
        endName = "globalVariables";
      } else if (stringData.value === true){
        endName = "stringData";
      } else if (shopData.value === true){
        endName = "shopData";
      }
    console.log(endName);
      const itemData = JSON.parse(store.getters["title/" + endName]);
      return itemData;
    })

    return {
      renderData, blueprintData, colorData,
      globalVariables, shopData, stringData
    };
  }
}
</script>