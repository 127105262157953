<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <div class="container-fluid">
    </div>
    <Clan :playerid="'47674764F2078AA2'"></Clan>
    <AddMemeberToClan :ClanIdInput="uuid"></AddMemeberToClan>

  </w-app>
</template>

<script>
import Clan from "../../components/clan/Clan.vue";
import AddMemeberToClan from "../../components/clan/AddMemeberToClan.vue";
export default {
  name: "Summary",
  components: {
    Clan,
    AddMemeberToClan
  }
}
</script>

