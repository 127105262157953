<template>
  <w-button class="px4" @click="ShowDialog(true)">
    Invite Memeber to clan
  </w-button>

  <w-dialog v-model="DialogShowing" :width="370" title-class="primary-light1--bg white">
    <template #title>
      <w-icon class="mr2">mdi mdi-odnoklassniki</w-icon>
      Add Player To clan
    </template>

    <w-form @submit.prevent="submitForm">
      <w-input readonly label-position="left" label="Clan Id:" v-model.trim="ClanIdIn" />
      <w-input label-position="left" label="PlayerId:" v-model.trim="playerId" />
      <w-input label-position="left" label="Name :" v-model.trim="name" />
    </w-form>

    <template #actions>
      <div class="spacer" />
      <w-button @click="ShowDialog(false)">Close</w-button>
      <w-button @click="SubmitForm()">Add Player</w-button>
    </template>
  </w-dialog>

  <w-dialog id="centerAlignDialog" v-model="isLoadingAddMember" title="Loading..." persistent :width="400">
    <div class="spinner-border text-primary" />
  </w-dialog>

  <w-dialog v-model="errorAddMember" title="An error occurred" transition="bounce" :width="400">
    {{ errorAddMember }}
    <w-flex justify-end>
      <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
    </w-flex>
  </w-dialog>
</template>

<script>
import { fetching, ROLES } from "../../config/context";
import { ref } from "vue";
import { PRIVILEGES } from "../../config/context";
import { useRouter } from 'vue-router';

export default {
  name: "Clan",
  props:
    ["ClanIdInput", "LeaderIdInput"]
  ,
  setup(props) {
    const isLoadingAddMember = ref(false);
    const DialogShowing = ref(false);
    const playerId = ref("");
    const ClanIdIn = ref("");
    const LeaderIdIn = ref("");
    const name = ref("");
    const errorAddMember = ref(null);
    const responsdata = ref();

    const router = useRouter();

    const handleError = () => {
      errorAddMember.value = null;
    };

    const ShowDialog = (isShow) => {
      ClanIdIn.value = props.ClanIdInput;
      LeaderIdIn.value = props.LeaderIdInput;
      DialogShowing.value = isShow;
    }
    const SubmitForm = () => {

      isLoadingAddMember.value = true;

      let method = "POST";
      const body = {
        "clanId": ClanIdIn.value,
        "memberId": playerId.value,
        "memberName": name.value,
        "playerId": LeaderIdIn.value,

      }

      fetching("/api/Alliance/AllianceMemberInvite", {
        method: method,
        body: body
      }).then(
        data => {
          responsdata.value = data;
          responsdata.value = data;
          errorAddMember.value = responsdata.value.errorMessage || 'Clan member Added successfully To Clan';
          isLoadingAddMember.value = false;
          router.push('/clans/clansmemberList/' + ClanIdIn.value);
          ShowDialog(false);
        }).catch(e => {
          errorAddMember.value = e.message || 'Failed to create the Group, try later.';
          isLoadingAddMember.value = false;
        }).finally(() => {
          isLoadingAddMember.value = false;
        });
    }

    return {
      ClanIdIn, isLoadingAddMember, SubmitForm, playerId, name, LeaderIdIn,
      PRIVILEGES, ROLES, ShowDialog, DialogShowing, errorAddMember, handleError
    }
  }
};
</script>


