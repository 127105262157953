<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>WebView Banner {{ isCreate ? "Create" : "Update" }}</h2>
        <form
          enctype="multipart/form-data"
          novalidate
          v-if="isInitial || isSaving"
        >
          <div class="dropbox">
            <input
              type="file"
              name="file"
              :disabled="isSaving"
              @change="
                filesChange($event.target.name, $event.target.files);
                fileCount = $event.target.files.length;
              "
              accept="image/*"
              class="input-file"
            />
            <p v-if="isInitial">
              Drag your file(s) here to begin<br />
              or click to browse
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files... <br /><br />
              <span class="spinner-border text-primary"></span>
            </p>
          </div>
        </form>
        <div>
          <!--SUCCESS-->
          <div v-if="isSuccess">
            <ul class="list-unstyled">
              <li v-for="item in uploadedFiles" :key="item" class="text-center">
                <img :src="item" class="img-responsive img-thumbnail" />
              </li>
            </ul>
            <div class="text-right">
              <strong
                >Uploaded {{ uploadedFiles.length }} file(s)
                successfully.</strong
              >
              <p>
                <a href="javascript:void(0)" @click="reset()">Upload again</a>
              </p>
            </div>
          </div>
          <!--FAILED-->
          <div v-if="isFailed">
            <h2>Uploaded failed.</h2>
            <p>
              <a href="javascript:void(0)" @click="reset()">Try again</a>
            </p>
            <pre>{{ uploadError }}</pre>
          </div>
        </div>

        <w-form
          @submit.prevent="submitForm"
          v-model="formIsValid"
          :disabled="url === null"
        >
          <table class="table table-hover">
            <thead>
              <tr>
                <th colspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <tr valign="middle">
                <th class="col-md-2 text-center">Title</th>
                <td>
                  <w-input
                    class="mt3"
                    label="Title"
                    outline
                    static-label
                    v-model.trim="title"
                    :validators="[validators.required]"
                  />
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Webview Action</th>
                <td>
                  <w-input
                    class="mt3"
                    label="Webview Action"
                    outline
                    static-label
                    v-model.trim="action"
                    :validators="[
                      validators.required,
                      validators.notOnlyUniAction,
                    ]"
                  />
                  You could consider to use "<em>uniwebview://CloseView</em>" or
                  "<em>uniwebview://none</em>" when there is no link or action
                  when the player is clicking.
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Date range</th>
                <td>
                  <date-picker
                    is-range
                    v-model="range"
                    mode="dateTime"
                    is24hr
                    :minute-increment="10"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="flex justify-center items-center">
                        <input
                          :value="inputValue.start"
                          v-on="inputEvents.start"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />
                        ~
                        <input
                          :value="inputValue.end"
                          v-on="inputEvents.end"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />
                        <w-input
                          label="Repetition per week or day  Sample: 5 day / 2 week"
                          v-model="RecurringPeriodByDay"
                          :validators="[validators.IsRecurringPeriodByDay]"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />

                        <w-input
                          label="Day(s)ofMonths:  Sample 3,5,31"
                          v-model="RecurringPaternDayOfMonth"
                          :validators="[validators.IsRecurringPaternForMonth]"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />
                        <w-input
                          label="Day(s)ofWeeks: Sample 2,5,1"
                          v-model="RecurringPaternDayOfWeek"
                          :validators="[validators.IsRecurringPaternForDay]"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />
                        <w-input
                          label="Hour(s)ofDay: Sample 8,5,20"
                          v-model="RecurringPaternHourOfDay"
                          :validators="[validators.IsRecurringPaternForHour]"
                          class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                        />
                        <br />
                        <b>Seprate Recurring value with , </b>
                      </div>
                      <div v-show="berlinTime">
                        <hr />
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th>UTC</th>
                              <td>{{ utcTime }}</td>
                            </tr>
                            <tr>
                              <th>Berlin</th>
                              <td>{{ berlinTime }}</td>
                            </tr>
                            <tr>
                              <th>US West</th>
                              <td>{{ usWestTime }}</td>
                            </tr>
                            <tr>
                              <th>US East</th>
                              <td>{{ usEastTime }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                  </date-picker>
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Priority</th>
                <td>
                  <w-input
                    class="mt3"
                    label=""
                    outline
                    static-label
                    number
                    v-model="priority"
                    type="number"
                  />
                  * A lower number will be displayed the first
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">
                  Language (<a
                    href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes"
                    target="_blank"
                    >ISO 639-1</a
                  >)
                </th>
                <td>
                  <w-input
                    class="mt3"
                    type="text"
                    label=""
                    outline
                    static-label
                    maxlength="2"
                    v-model="language"
                    placeholder="EN"
                    :validators="[validators.LangRequired]"
                    :disabled="allLang === true"
                  />
                  <w-checkbox
                    v-model="allLang"
                    class="mt-2"
                    bg-color="green-light5"
                    color="green-dark2"
                    >All of the languages</w-checkbox
                  >
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Active Status</th>
                <td>
                  <w-checkbox
                    v-model="active"
                    label-on-left
                    icon
                    class="mt-2"
                  ></w-checkbox>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="text-right">
                  <w-button
                    type="submit"
                    v-privilege="PRIVILEGES.WEBVIEW_WRITE"
                    :disabled="url === null"
                  >
                    {{ isCreate ? "Submit" : "Update" }}
                  </w-button>
                </td>
              </tr>
            </tfoot>
          </table>
        </w-form>
      </div>
    </div>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>
  </w-app>
</template>

<script>
import { baseUrl, fetching, ROLES, PRIVILEGES } from "../../config/context";
import { ref, onBeforeMount, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DatePicker } from "v-calendar";

export default {
  name: "BannerForm",
  components: {
    DatePicker,
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      this.save(formData);
    },
  },

  setup() {
    const STATUS_INITIAL = 0,
      STATUS_SAVING = 1,
      STATUS_SUCCESS = 2,
      STATUS_FAILED = 3;

    const route = useRoute();
    const router = useRouter();

    const title = ref(null);
    const action = ref("uniwebview://");
    const url = ref(null);

    const active = ref(true);
    const allLang = ref(false);
    const language = ref("");
    const priority = ref(200);

    const inputValue = ref(null);
    const inputEvents = ref(null);
    const RecurringPaternDayOfMonth = ref("");
    const RecurringPaternDayOfWeek = ref("");
    const RecurringPaternHourOfDay = ref("");
    const RecurringPeriodByDay = ref("10 day");
    const locale = ref(null);

    const isCreate = ref(false);

    const range = ref({
      start: new Date().setMinutes(0, 0, 0),
      end: new Date().setMinutes(0, 0, 0),
    });

    const currentStatus = ref(STATUS_INITIAL);

    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const uploadedFiles = ref([]);
    const uploadError = ref(null);

    const isInitial = computed(function() {
      return currentStatus.value === STATUS_INITIAL;
    });

    const isSaving = computed(function() {
      return currentStatus.value === STATUS_SAVING;
    });

    const isSuccess = computed(function() {
      return currentStatus.value === STATUS_SUCCESS;
    });

    const isFailed = computed(function() {
      return currentStatus.value === STATUS_FAILED;
    });

    const utcTime = ref(null);
    const berlinTime = ref(null);
    const usWestTime = ref(null);
    const usEastTime = ref(null);

    watch(range, () => {
      utcTime.value =
        convertTimeZone(range.value.start, "Etc/GMT-0") +
        "~" +
        convertTimeZone(range.value.end, "Etc/GMT-0");

      berlinTime.value =
        convertTimeZone(range.value.start, "Europe/Berlin") +
        "~" +
        convertTimeZone(range.value.end, "Europe/Berlin");

      usWestTime.value =
        convertTimeZone(range.value.start, "America/Los_Angeles") +
        "~" +
        convertTimeZone(range.value.end, "America/Los_Angeles");

      usEastTime.value =
        convertTimeZone(range.value.start, "America/New_York") +
        "~" +
        convertTimeZone(range.value.end, "America/New_York");
    });

    const validators = {
      required: (value) => !!value || "This field is required",
      LangRequired: (value) =>
        (allLang.value === false && !!value) || "This field is required",
      notOnlyUniAction: (value) =>
        value !== "uniwebview://" || "This needs more detail action",
      IsRecurringPaternForMonth: (value) =>
        value.length == 0 ||
        /^(?:[1-9]|[12]\d|3[01])(?:,(?:[1-9]|[12]\d|3[01]))*$/.test(value) ||
        "Please enter a series of numbers between 1 and 31, separated by commas. Sample 3,5,31",
      IsRecurringPaternForDay: (value) =>
        value.length == 0 ||
        /^(?:[1-7])(?:,(?:[1-7]))*$/.test(value) ||
        "Please enter a series of numbers between 1 and 7, separated by commas. Sample 2,5,1",
      IsRecurringPaternForHour: (value) =>
        value.length == 0 ||
        /^(?:[1-9]|1\d|2[0-3])(?:,(?:[1-9]|1\d|2[0-3]))*$/.test(value) ||
        "Please enter a series of numbers between 1 and 23, separated by commas. Sample 8,5,20",
      IsRecurringPeriodByDay: (value) =>
        value.length == 0 ||
        /^\d+\s(day|week)$/.test(value) ||
        "Please enter afromat like 10 day or 1 day or 1 week or 2 week ",
    };

    const submitForm = () => {
      if (!formIsValid.value) {
        return;
      }

      isLoading.value = true;

      let apiUri = "/api/webview/banner";
      let method = "POST";

      let uuid = null;
      let body = {
        title: title.value,
        action: action.value,
        url: url.value,
        start: range.value.start,
        end: range.value.end,
        active: active.value,
        priority: priority.value,
        language: language.value.toString().toUpperCase() || "EN",
        RecurringPatternMonth: RecurringPaternDayOfMonth.value,
        RecurringPatternWeek: RecurringPaternDayOfWeek.value,
        RecurringPatternDay: RecurringPaternHourOfDay.value,
        RecurringPeriodByDay: RecurringPeriodByDay.value,
      };

      if (!isCreate.value) {
        method = "PUT";
        uuid = route.path.split("/").reverse()[0];
        body["id"] = uuid;
      }

      if (allLang.value) {
        body["language"] = "**"; // This webview banner is for all languages.
      }

      fetching(apiUri, {
        method: method,
        body: body,
      })
        .then(() => {
          router.push("/webview/banner/list");
        })
        .catch((e) => {
          error.value = e.message || "Failed to create the data, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    function reset() {
      // reset form to initial state
      currentStatus.value = STATUS_INITIAL;
      uploadedFiles.value = [];
      uploadError.value = null;
      url.value = null;
    }

    async function save(formData) {
      currentStatus.value = STATUS_SAVING;

      const headers = {};
      const token = localStorage.getItem("token");
      if (token !== "" && token != null) {
        headers["Authorization"] = "Bearer " + token;
      }

      fetch(baseUrl() + "/api/webview/banner/upload", {
        method: "POST",
        body: formData,
        headers: headers,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          url.value = data.endpoint;
          uploadedFiles.value = [data.endpoint];
          currentStatus.value = STATUS_SUCCESS;
        })
        .catch((e) => {
          uploadError.value = e.message;
          currentStatus.value = STATUS_FAILED;
        });
    }

    const handleError = () => {
      error.value = null;
    };

    onBeforeMount(() => {
      reset();

      if (route.path.endsWith("new")) {
        isCreate.value = true;
        return;
      }

      const uuid = route.path.split("/").reverse()[0];

      isLoading.value = true;
      fetching("/api/webview/banner/" + uuid)
        .then((data) => {
          title.value = data.title;
          action.value = data.action;
          url.value = data.url;
          range.value = {
            start: data.start,
            end: data.end,
          };
          active.value = data.active;
          priority.value = data.priority;
          language.value = data.language;

          uploadedFiles.value = [data.url];
          currentStatus.value = STATUS_SUCCESS;
          RecurringPaternDayOfMonth.value = data.recurringPatternMonth;
          RecurringPaternDayOfWeek.value = data.recurringPatternWeek;
          RecurringPaternHourOfDay.value = data.recurringPatternDay;
          RecurringPeriodByDay.value = data.recurringPeriodByDay;
        })
        .catch((e) => {
          error.value = e.message || "Failed to get the User, try later.";
        })
        .finally(() => {
          isLoading.value = false;
          allLang.value = language.value === "**";
        });
    });
    const convertTimeZone = (date, timeZone) => {
      return new Date(
        (typeof date === "string"
          ? new Date(date)
          : date
        ).toLocaleString("en-US", { timeZone })
      );
    };

    return {
      formIsValid,
      isLoading,
      error,
      validators,
      isCreate,
      submitForm,
      handleError,
      ROLES,
      PRIVILEGES,
      isInitial,
      isSaving,
      isSuccess,
      isFailed,
      reset,
      save,
      currentStatus,
      uploadedFiles,
      uploadError,
      title,
      action,
      url,
      active,
      language,
      priority,
      allLang,
      inputValue,
      inputEvents,
      range,
      locale,
      berlinTime,
      usWestTime,
      usEastTime,
      utcTime,
      convertTimeZone,
      RecurringPaternDayOfMonth,
      RecurringPaternDayOfWeek,
      RecurringPaternHourOfDay,
    };
  },
};
</script>
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

em {
  background-color: lightgrey;
  //font-size: 1.2em;
  padding: 3px 5px;
}
</style>
