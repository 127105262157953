<template>
  <li>
    <h3>
      <name :class="(state == 2)?'strikethrough':''">{{header}}</name> <span v-if="items !== undefined">** Deprecated **</span>
    </h3>

    <div><span>State:</span> {{ getStateName(state) }} </div>
    <div id="body" v-show="body" v-html="body.replaceAll('\n', '<br>')">
    </div>

    <!-- todo: Deprecated!!!-->
    <div v-show="items" style="background-color: #727272">
      <ul>
        <li v-for="item in items" :key="item">
          <div style="display: flex;" class="align-center">
            <v-lazy-image
                :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(item.id) + '.png'"
                onerror="this.onerror=null;this.src='/images/onerror.png'"
                style="width: 80px"
                class="item-thumbnail"
            />
            <div>
              <b>{{item.name}}</b>
              <br>{{item.id}}
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div v-show="rewards">
      <ul>
        <li v-for="reward in rewards" :key="reward">
          <div style="display: flex;">
            <div>
              <v-lazy-image
                  :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(reward.id) + '.png'"
                  onerror="this.onerror=null;this.src='/images/onerror.png'"
                  style="width: 80px"
                  class="item-thumbnail"
              />
            </div>
            <div>
              <b>{{reward.name}}</b>
              <br>{{reward.id}}
            </div>
            <div style="margin-left: auto;" class="bigNumber">
              {{Intl.NumberFormat().format(reward.value)}}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-show="date">
      <span v-ago="date"></span>
    </div>
  </li>
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
  name: "PostItem",
  components: {
    VLazyImage
  },
  props: [
    "header", "body", "items", "rewards", "date", "key", "getItemThumbnail", "state"
  ],
  setup: function () {
    const getStateName = (state) => {
      if (state === 0) {
        return "Received"; // `None` is the original value.
      } else if (state === 1) {
        return "Read";
      } else if (state === 2) {
        return "Claimed";
      }
      return ""
    };
    return {
      getStateName
    };
  }
};
</script>
<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
  list-style: none;
}

li h3 {
  margin: 0;
  color: darkblue;
}

button {
  float: right;
}

span {
  color: darkgray;
}

span {
  color: darkgray;
}

#body {
  padding: 15px;
  margin-top: 5px;
  display: block;
  background: lightgrey;
  color: black;

}

.bigNumber {
  font-size: 2em;
  color: darkred;

  font-variant-numeric: slashed-zero;
  font-variant-position: super;

}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}
</style>