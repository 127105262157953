<template>
  <tr scope="row">
    <td>
      <div class="crop">
        <a href="javascript:void(0);" @click="popupDetail(item)" :alt="item.id">{{item.id}}</a>
      </div>
    </td>
    <td class="text-center">
      <img
          :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(item.__ItemId) + '.png'"
          onerror="this.onerror=null;this.src='/images/onerror.png'"
          style="width: 120px; margin:0px"
          class="item-thumbnail"
      />
    </td>
    <td><b>{{item.__ItemFullName}}</b><br> ({{item.__ItemId}})</td>
    <td class="text-right">{{item.parameter.Amount}}</td>
    <td>
      <b>{{item.factory}}</b>
      <div class="crop">{{item.contextId}}</div>
    </td>
    <td>
      {{(yymmddhhmm(item.createdDt))}} <br>
      <span v-ago="item.createdDt"></span>
    </td>
    <td>
      {{yymmddhhmm(item.startDt)}} <br>
      <span v-ago="item.startDt"></span>
    </td>
    <td>
      {{yymmddhhmm(item.endDt)}}<br>
      <span v-ago="item.endDt"></span>
      <span v-if="item.__TimerStatusName === 'Processing' && (new Date(item.endDt).getTime() > new Date().getTime())">
        <br>
        <span>Delta date:</span> {{deltaTime(item.endDt)}}
      </span>
    </td>
    <td>{{item.__TimerTypeName}}</td>
    <td>{{item.__TimerStatusName}}</td>
    <td>
      <div v-show="['Processing'].includes(item.__TimerStatusName) && (new Date(item.endDt).getTime() > new Date().getTime())">
        <w-button bg-color="success" shadow @click="speedUp(item.id)" v-privilege="PRIVILEGES.PLAYER_WRITE">Speed Up</w-button>
      </div>
      <div v-show="['Suspended', 'Canceled'].includes(item.__TimerStatusName)">
        <w-button bg-color="warning" shadow @click="reActive(item.id)" v-privilege="PRIVILEGES.PLAYER_WRITE">Re-Active</w-button>
      </div>
      <div v-show="!['Finished', 'Canceled', 'Deleted', 'Suspended'].includes(item.__TimerStatusName)  && (new Date(item.endDt).getTime() > new Date().getTime())">
        <w-button bg-color="info" shadow @click="suspend(item.id)" v-privilege="PRIVILEGES.PLAYER_WRITE">Suspend</w-button>
      </div>
      <div v-show="!['Finished', 'Canceled', 'Deleted'].includes(item.__TimerStatusName)">
        <w-button bg-color="error" shadow @click="cancel(item.id)" v-privilege="PRIVILEGES.PLAYER_WRITE">Cancel</w-button>
      </div>
      <!--      return "Deleted";-->
    </td>
  </tr>

</template>

<script>
import moment from 'moment';
import {PRIVILEGES} from "../../config/context";

export default {
  name: "FabricationItem",
  emits: ['timer-speedup', 'timer-cancel', 'timer-re-active', 'timer-suspend'],
  props: [
    "item", "key", "getItemFullName", "fabricationJson", 'getItemThumbnail'
  ],
  setup(props, context) {

    function deltaTime(end) {
      const currentDate = new Date().getTime()
      return moment(end).from(currentDate, true);
    }

    function speedUp(timerId) {
      context.emit('timer-speedup', timerId);
    }

    function cancel(timerId) {
      context.emit('timer-cancel', timerId);
    }

    function reActive(timerId) {
      context.emit('timer-re-active', timerId);
    }

    function suspend(timerId) {
      context.emit('timer-suspend', timerId);
    }

    function popupDetail(json){
      context.emit('update:modelValue', {...json});
    }

    const yymmddhhmm = (d) => {
      return moment(d).format("YYYY-MM-DD HH:mm");
    }

    return {
      deltaTime, speedUp, cancel, reActive, suspend, popupDetail, yymmddhhmm, PRIVILEGES
    }
  }
};
</script>
<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h3 {
  margin: 0;
  color: darkblue;
}

button {
  float: right;
}

span {
  color: darkgray;
}

.crop {
  width:80px;
  height: 20px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

</style>