<template>
  <li>
    <div style="clear:both; display: flex;">
      <v-lazy-image
          :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(currency) + '.png'"
          onerror="this.onerror=null;this.src='/images/onerror.png'"
          class="item-thumbnail"
          style="width: 150px"
      />
      <div style="width: 100%">
        <h4>
          <b>{{ name }}</b> : {{ numberFormatting(amount) }}
          <w-button bg-color="error" shadow  @click="change_currency(currency)" v-privilege="PRIVILEGES.PLAYER_WRITE" class="text-right">Modify</w-button>
        </h4>
        <div>Key: {{ currency }}</div>
        <div>{{ desc }}</div>
      </div>

    </div>
  </li>
</template>

<script>
import {PRIVILEGES} from "../../config/context";
import VLazyImage from "v-lazy-image";

export default {
  props: ['currency', 'amount', "desc", "name", "getItemThumbnail"],
  emits: ['change-currency'],
  components: {
    VLazyImage
  },
  methods: {
     change_currency (currency) {
      console.log(currency)
      this.$emit('change-currency', currency);
    }
  },
  setup(){
    const nf = Intl.NumberFormat();
    const numberFormatting = (x) => {
      return nf.format(x);
    }

    return {
      numberFormatting, PRIVILEGES
    }
  }

};

</script>

<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h4 {
  margin: 0;
  color: darkblue;
}

span {
  color: darkgray;
}

button {
  float: right;
}
</style>