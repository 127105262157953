export default {
    blueprintData(state) {
        return state.blueprintData;
    },
    colorData(state) {
        return state.colorData;
    },
    combatVariables(state) {
        return state.combatVariables;
    },
    combatVariables_QDB(state) {
        return state.combatVariables_QDB;
    },
    conditionalStatModData(state) {
        return state.conditionalStatModData;
    },
    conditionalStatModData_QDB(state) {
        return state.conditionalStatModData_QDB;
    },
    dialogSequenceData(state) {
        return state.dialogSequenceData;
    },
    econModData(state) {
        return state.econModData;
    },
    economyConfig(state) {
        return state.economyConfig;
    },
    effectAreas(state) {
        return state.effectAreas;
    },
    effectAreas_QDB(state) {
        return state.effectAreas_QDB;
    },
    enemySetData(state) {
        return state.enemySetData;
    },
    externalModuleData(state) {
        return state.externalModuleData;
    },
    externalModuleData_QDB(state) {
        return state.externalModuleData_QDB;
    },
    factionData(state) {
        return state.factionData;
    },
    factionEffectsList(state) {
        return state.factionEffectsList;
    },
    globalVariables(state) {
        return state.globalVariables;
    },
    internalModuleData(state) {
        return state.internalModuleData;
    },
    itemData(state) {
        return state.itemData;
    },
    kiithData(state) {
        return state.kiithData;
    },
    layoutData(state) {
        return state.layoutData;
    },
    levelData(state) {
        return state.levelData;
    },
    lootData(state) {
        return state.lootData;
    },
    miningData(state) {
        return state.miningData;
    },
    missionData(state) {
        return state.missionData;
    },
    missionStepActions(state) {
        return state.missionStepActions;
    },
    missionStepActions_QDB(state) {
        return state.missionStepActions_QDB;
    },
    missionSteps(state) {
        return state.missionSteps;
    },
    missionSteps_QDB(state) {
        return state.missionSteps_QDB;
    },
    missionTemplateData(state) {
        return state.missionTemplateData;
    },
    officerData(state) {
        return state.officerData;
    },
    officerData_QDB(state) {
        return state.officerData_QDB;
    },
    officerEffectsData(state) {
        return state.officerEffectsData;
    },
    officerNameData(state) {
        return state.officerNameData;
    },
    officerPortraitData(state) {
        return state.officerPortraitData;
    },
    officerSlotConfig(state) {
        return state.officerSlotConfig;
    },
    officerSlotConfig_QDB(state) {
        return state.officerSlotConfig_QDB;
    },
    officerStatModData(state) {
        return state.officerStatModData;
    },
    penaltyData(state) {
        return state.penaltyData;
    },
    premiumShopData(state) {
        return state.premiumShopData;
    },
    profileVersionData(state) {
        return state.profileVersionData;
    },
    pushNotifications(state) {
        return state.pushNotifications;
    },
    questData(state) {
        return state.questData;
    },
    questLineData(state) {
        return state.questLineData;
    },
    questTemplateData(state) {
        return state.questTemplateData;
    },
    researchData(state) {
        return state.researchData;
    },
    rewardData(state) {
        return state.rewardData;
    },
    sellData(state) {
        return state.sellData;
    },
    shopData(state) {
        return state.shopData;
    },
    skillData(state) {
        return state.skillData;
    },
    skillData_QDB(state) {
        return state.skillData_QDB;
    },
    starSystemConfigData(state) {
        return state.starSystemConfigData;
    },
    starSystemData(state) {
        return state.starSystemData;
    },
    statModData(state) {
        return state.statModData;
    },
    statModData_QDB(state) {
        return state.statModData_QDB;
    },
    status(state) {
        return state.status;
    },
    stringData(state) {
        return state.stringData;
    },
    uIHightlightData(state) {
        return state.uIHightlightData;
    },
    unitData(state) {
        return state.unitData;
    },
    unitData_QDB(state) {
        return state.unitData_QDB;
    },
    utilitySystemData(state) {
        return state.utilitySystemData;
    },
    utilitySystemData_QDB(state) {
        return state.utilitySystemData_QDB;
    },
    valueBasedStatMod(state) {
        return state.valueBasedStatMod;
    },
    valueBasedStatMod_QDB(state) {
        return state.valueBasedStatMod_QDB;
    },
    versionControl(state) {
        return state.versionControl;
    },
    weaponEffects(state) {
        return state.weaponEffects;
    },
    weaponEffects_QDB(state) {
        return state.weaponEffects_QDB;
    },
    weaponSpecialEffects(state) {
        return state.weaponSpecialEffects;
    },
    weaponSpecialEffects_QDB(state) {
        return state.weaponSpecialEffects_QDB;
    },
    weaponSystemData(state) {
        return state.weaponSystemData;
    },
    weaponSystemData_QDB(state) {
        return state.weaponSystemData_QDB;
    }
};