<template>
  <w-app>
    <div>
      <h5>Quest</h5>
      <ul>
        <li v-for="[key, value] of Object.entries(QuestLog)"
             :key="key"
             v-show="QuestLog !== null">
          <div>
            <b>{{getQuestName(key)}}</b> ({{key}}) - {{getQuestState(value)}}
            <span v-if="getQuestState(value) === 'Completed'">
              <w-button bg-color="success" @click="collectReward(key)" v-privilege="PRIVILEGES.PLAYER_WRITE">Collect Reward</w-button>
            </span>
            <span v-else-if="getQuestState(value) === 'Active'">
              <w-button bg-color="info" @click="completeGoal(key)" v-privilege="PRIVILEGES.PLAYER_WRITE">Complete Goal</w-button>
            </span>

            <span v-show="getQuestState(value) !== 'Collected'">
              <w-button bg-color="warning" @click="finishQuest(key)" v-privilege="PRIVILEGES.PLAYER_WRITE">Finish Quest</w-button>
            </span>

            <div v-show="questData[key] !== undefined">
              <div v-show="getQuestElement(key, 'Desc')">
                {{ getQuestElement(key, 'Desc') }}
              </div>
              <div v-show="getQuestElement(key, 'Tier')">
                - Tier: {{ getQuestElement(key, 'Tier') }}
              </div>
              <div v-show="getQuestElement(key, 'Type')">
                - Type: {{ getQuestElement(key, 'Type') }}
              </div>
              <div v-show="getQuestElement(key, 'XPMod')">
                - XPMod: {{ getQuestElement(key, 'XPMod') }}
              </div>
              <div v-show="getQuest(key, value)[0]?.goalParameters">
                - GoalParameters: {{ getQuest(key, value)[0]?.goalParameters }}
                <div style="background: lightgray; margin: 10px">
                  <pre>{{ getGoalParameters(getQuest(key, value)[0]?.goalParameters) }}</pre>
                </div>
              </div>
              <div v-show="getQuest(key, value)[0]?.rewards">
                - Rewards: {{ getQuest(key, value)[0]?.rewards }}
                <div style="background: lightgray; margin: 10px">
                  <span v-for="(r, index) in getQuest(key, value)[0]?.rewards" :key="r">
                    <span v-show="index % 2 === 0">
                      <img :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(r) + '.png'"
                           onerror="this.onerror=null;this.src='/images/onerror.png'"
                           style="width: 100px"
                      />
                     <b>{{toItemFullName(r)}}</b> ({{ r }})</span>
                    <span v-show="index % 2 === 1">: {{r}}<br></span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <w-timeline :items="getQuest(key, value)">
            <template #item="{ item, index }">
              <div > <!-- style="clear:both; display: flex;"-->
                <div>
                  <w-tag :class="'white '+item.color+'-light2--bg'">
                    {{ index }}
                  </w-tag>

                  <div v-show="item.followUps">
                    followUps: {{ getQuestName(item.followUps) }}
                  </div>
                  <div v-show="item.followUpLines">
                    followUpLines: {{ getQuestName(item.followUpLines) }}
                  </div>
                  <div v-show="Object.keys(item).includes('goals')">
                    Goals: {{ item.goals }}
                  </div>
                </div>
              </div>
            </template>
          </w-timeline>

        </li>
      </ul>
    </div>
  </w-app>

  <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading ..." persistent :width="400">
    <div class="spinner-border text-primary"/>
  </w-dialog>

  <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
    {{ error }}
    <w-flex justify-end>
      <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
    </w-flex>
  </w-dialog>

</template>
<script>
import {ref, onBeforeMount} from 'vue';
import { useStore } from 'vuex';
import {fetching} from "../../config/context";
import {useRoute} from "vue-router";
import {PRIVILEGES} from "../../config/context";

export default {
  components: {
  },
  props: [
    'QuestLog','getItemFullName','getItemDesc','getItemThumbnail'
  ],
  emits: ['change-quest'],
  setup(props, context) {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(false);

    // const starSystemData = JSON.parse(store.getters["title/starSystemData"]);

    const questData = JSON.parse(store.getters["title/questData"].slice().replace('"":', '"Desc":')) || [];
    // const questData = JSON.parse(store.getters["title/questData"].replace('"":', '"Desc":'))
    const error = ref(null);


    onBeforeMount(() => {
    });

    const getQuestColor = (value,  idx ) => {
      if (parseInt(value) === parseInt(idx) + 1) {
        return 'green'
      } if (parseInt(value) > parseInt(idx) + 1) {
        return 'grey'
      } else{
        return 'grey'
      }
    }

    const getQuestName = (questId) => {
      const name =  props.getItemFullName({ItemId: questId});
      if (name === 'n/a') return questId;

      return name;

    }

    const collectReward = (questId) => {
      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/quest/${questId}/collect/reward`, { "method" : 'POST' }).then( data => {
        if (data.errorMessage !== ""){
          throw {"message": data.errorMessage};
        }
        context.emit('change-quest');
      }).catch(e => {
        error.value = e.message || 'Failed to collect the reward of quest, try later.';

      }).finally(() => {
        isLoading.value = false;
      });
    }


    const completeGoal = (questId) => {
      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/quest/${questId}/goal/complete`, { "method" : 'PUT' }).then(data => {
        if (data.errorMessage !== ""){
          throw {"message": data.errorMessage};
        }
        context.emit('change-quest');
      }).catch(e => {
        error.value = e.message || 'Failed to complete the goal of quest, try later.';

      }).finally(() => {
        isLoading.value = false;
      });
    }

    const finishQuest = (questId) => {

      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/quest/${questId}/finish`, { "method" : 'POST' }).then( data => {
        if (data.errorMessage !== ""){
          throw {"message": data.errorMessage};
        }
        context.emit('change-quest');
      }).catch(e => {
        error.value = e.message || 'Failed to finish the Quest, try later.';

      }).finally(() => {
        isLoading.value = false;
      });
    }

    const getQuestElement = (key, item_name) => {
      try{
        return questData[key][item_name];
      }catch(e){
        return null;
      }
    }

    function toItemFullName(itemId) {
      const name = props.getItemFullName({ItemId: itemId})
      if (name === 'n/a') {
        return itemId;
      }

      return name;
    }

    const getQuest = (x, val) => {

      const first_index = val.split(":")[0];
      const goals = questData[x]?.Goals?.split(":") || [];
      const goalParameters = questData[x]?.GoalParameters?.split(":") || [];
      const rewards = questData[x]?.Rewards?.split(":") || [];
      const followUps = questData[x]?.FollowUps || '';
      const followUpLines = questData[x]?.FollowUpLines || '';

      return goals.map((goal, ind) => ({
        goal: goal,
        color: getQuestColor(first_index, ind),
        followUps: followUps,
        followUpLines: followUpLines,
        rewards: rewards.flatMap(x => x.split("\n")),
        goals: goals,
        goalParameters: goalParameters//[ind]//?
      }));
    }

    const getQuestState = (state) => {
      const arr = state.split(":");
      const last_index = arr[arr.length - 1];
      return ["None", "Active", "Completed", "Collected", "Expired", "Available", "Inactive"][last_index] ;
    }

    const handleError = () => {
      error.value = null;
    }

    const getGoalParameters = (params) => {
      if (params === undefined) return "";
      return params.map(x => x.split(";").join("\n"))
                        .map(x => x.split("&").join(": "))
                        .join("\n----------------------------\n");
    }

    return {
      questData, error, isLoading, completeGoal, getQuestElement, getQuestName, toItemFullName, getGoalParameters,
      getQuest, getQuestState, collectReward, finishQuest, handleError, PRIVILEGES
    };
  }
};
</script>
<style scoped>
ul {
  list-style: none;
  margin: 1em;
  margin-left: 0.5em !important;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

button {
  float: right;
}

</style>
