const fetching = (api, prop = { method: "GET", body: null }) => {
    api = api.replaceAll("//", "/");

    const headers = {
        'Content-Type': 'application/json; charset=utf-8'
    };

    const token = localStorage.getItem('token');

    if (token !== "" && token != null) {
        headers["Authorization"] = 'Bearer ' + token;
    }

    const init = {
        method: prop.method,
        headers: headers
    };
    if (prop.body !== null && prop.body !== undefined) {
        init["body"] = JSON.stringify(prop.body);
    }


    return fetch(baseUrl() + api, init).then(async response => {
        const data = await response.json();
        if (!response.ok) {
            const error = new Error(data.Message || response.statusText);
            return Promise.reject(error);
        }

        return data;

    });
}

const PRIVILEGES = {
    'PLAYER_WRITE': "player_write",
    'ADMIN_WRITE': "admin_write",
    'SHOP_WRITE': "shop_write",
    'WEBVIEW_WRITE': "webview_write",
    'GROUP_WRITE': "group_write",
    'DYNAMIC_SEGMENT_WRITE': "dynamic_segment_write",
    'Clan_WRITE': "clan_write",
    'MAIL_WRITE': "mail_write", // TODO: might be remove??
    'Player_Support_History_WRITE': "player_support_write",
}

const ROLES = {
    'ROLE_ADMIN': "ADMIN",
    'ROLE_GM': "GM",
    'ROLE_CS': "CS",
    'ROLE_READONLY': "READONLY",
}


function baseUrl() {
    //return process.env.VUE_APP_API_URL || "https://nimbus-gm-api-dev.westus.azurecontainer.io"
    // return process.env.VUE_APP_API_URL || "https://nimbusx-gm-api-live.westus.azurecontainer.io"
    return process.env.VUE_APP_API_URL || "https://127.0.0.1:5001"
}


function deltaDNAUrl(playerId, page) {
    const event = baseUrl().split(".")[0].endsWith("live") ? "events_live" : "events_dev";
    const code = "EvfD_JX1Aua33u_Z5XFfncvPMHoXpMjIcNzQBeR5YpEdAzFuIyGHcg==";
    return `https://func-nimbusx-events-deltadna.azurewebsites.net/api/events?code=${code}&playfabId=${playerId}&event=${event}&page=${page}`
}


export { baseUrl, fetching, deltaDNAUrl, ROLES, PRIVILEGES };