import { createApp } from 'vue';
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import '@mdi/font/css/materialdesignicons.min.css'
import JsonViewer from "vue3-json-viewer";
import moment from 'moment';

import { VueCookieNext } from 'vue-cookie-next';

import router from './router.js';
import store from './store/index.js';

import BaseSearch from './components/ui/BaseSearch.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseDialog from './components/ui/BaseDialog.vue';

//Okta
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import sampleConfig from "./config/config";
import './index.css'

const oktaAuth = new OktaAuth(sampleConfig.oidc)

import App from './App.vue';
const app = createApp(App);

app.component('base-search', BaseSearch);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);

app.use(JsonViewer);
app.use(router);
app.use(store);

app.use(VueCookieNext);
app.use(OktaVue, { oktaAuth });

// https://vuejs.org/guide/reusability/custom-directives.html#function-shorthand
app.directive('ago', {
    beforeMount(el, binding){
        if (binding.value === null || binding.value === "") return ;
        el.innerText = moment(binding.value).fromNow();
    }
});

app.directive('privilege', {
    beforeMount(el, binding) {
        if (!store.getters.privileges.includes(binding.value)){
            el.style.visibility = 'hidden';
            // el.style.background = 'purple';
            // el.style.color = 'red';
        }
    }
})

Array.prototype.groupBy = function(prop) {
    return this.reduce(function(groups, item) {
        const val = item[prop]
        groups[val] = groups[val] || 0
        groups[val] += 1
        return groups
    }, {})
};

// set default config
VueCookieNext.config({ expire: '1d' })

app.mount('#app');

new WaveUI(app, {
    // Some Wave UI options.
})