<template>
  <w-app>
    <!--    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>-->
    <div class="categories" v-show="(itemCategoryClasses || []).length > 1">
      <w-radios
        v-model="itemCategoryRadio"
        :items="itemCategoryClasses"
        inline
        color="blue"
      >
        <template #item="{ item }">
          <span> {{ item.label }}</span>
        </template>
      </w-radios>
    </div>
    <div>
      Sorting by Created:
      <w-button @click="sort('asc')" :outline="sorting != 'asc'"
        >Ascending</w-button
      >
      <w-button @click="sort('desc')" :outline="sorting != 'desc'"
        >Descending</w-button
      >
    </div>
    <div v-show="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr />
    <div v-show="displayedItems.length == 0">There are <b>no history.</b></div>
    <ul>
      <audit-item
        v-for="item in displayedItems"
        :key="item.id"
        :id="item.id"
        :adminId="item.adminId"
        :adminName="item.adminName"
        :category="item.category"
        :headers="item.headers"
        :host="item.host"
        :method="item.method"
        :path="item.path"
        :playerId="item.playerId"
        :query="item.query"
        :remoteIpAddress="item.remoteIpAddress"
        :requestBody="item.requestBody"
        :routeData="item.routeData"
        :timestamp="item.created"
        @revoke-ban="$emit('revoke-ban', $event)"
      />
    </ul>
  </w-app>
</template>

<script>
import { toRefs, onUpdated, ref, watch } from "vue";
import useSearch from "../../hooks/search.js";
import useSort from "../../hooks/sort.js";
import AuditItem from "./AuditItem.vue";
import useFilter from "../../hooks/filter";

export default {
  name: "AuditList",
  components: {
    AuditItem,
  },
  emits: ["revoke-ban"],
  props: ["items"],
  setup(props) {
    const { items } = toRefs(props);
    const itemCategoryRadio = ref("");
    const itemCategoryClasses = ref(null);

    const { filteredItems, filterFields } = useFilter(items, "category");

    const {
      enteredSearchTerm,
      availableItems,
      updateSearch,
    } = useSearch(filteredItems, ["Reason"]);
    const { sorting, displayedItems, sort } = useSort(
      availableItems,
      "Created"
    );

    watch(itemCategoryRadio, function() {
      if (itemCategoryRadio.value === "") {
        const groupByType = items.value.groupBy("category");
        filterFields.value = Object.keys(groupByType);
        return;
      }
      filterFields.value = [itemCategoryRadio.value];
    });

    const updateCategoryClasses = () => {
      const key = "category";
      const groupByItemsCount = items.value.groupBy(key);
      const groupByKeys = Object.keys(groupByItemsCount);
      const sortedKeys = [...groupByKeys].sort(
        (a, b) => groupByItemsCount[b] - groupByItemsCount[a]
      );
      const radioItems = sortedKeys.map((x) => {
        const name = x; //(getName !== undefined)? getName(x): x;
        return { label: name + " (" + groupByItemsCount[x] + ")", value: x };
      });
      radioItems.unshift({
        label: `All ${key} (${items.value.length})`,
        value: "",
      });
      itemCategoryClasses.value = radioItems;
    };

    onUpdated(() => updateCategoryClasses());

    return {
      enteredSearchTerm,
      updateSearch,
      displayedItems,
      sorting,
      sort,
      availableItems,
      itemCategoryClasses,
      itemCategoryRadio,
    };
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
