import { ROLES, PRIVILEGES } from "../../../config/context";

export default {
    userId(state) {
        return state.userId;
    },
    name(state) {
        return state.name;
    },
    token(state) {
        return state.token;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
    privileges(state) {
        // todo
        // return String(state.privileges).split(",").map(e => e.trim());
        if (state.roles.includes(ROLES.ROLE_ADMIN)) {
            return [PRIVILEGES.PLAYER_WRITE, PRIVILEGES.ADMIN_WRITE, PRIVILEGES.SHOP_WRITE, PRIVILEGES.WEBVIEW_WRITE, PRIVILEGES.GROUP_WRITE, PRIVILEGES.MAIL_WRITE, PRIVILEGES.DYNAMIC_SEGMENT_WRITE, PRIVILEGES.Clan_WRITE, PRIVILEGES.Player_Support_History_WRITE]
        } else if (state.roles.includes(ROLES.ROLE_GM)) {
            return [PRIVILEGES.PLAYER_WRITE, PRIVILEGES.SHOP_WRITE, PRIVILEGES.WEBVIEW_WRITE, PRIVILEGES.GROUP_WRITE, PRIVILEGES.MAIL_WRITE, PRIVILEGES.Clan_WRITE, PRIVILEGES.DYNAMIC_SEGMENT_WRITE, PRIVILEGES.Player_Support_History_WRITE];
        } else if (state.roles.includes(ROLES.ROLE_CS)) {
            return [PRIVILEGES.PLAYER_WRITE, PRIVILEGES.GROUP_WRITE, PRIVILEGES.MAIL_WRITE, PRIVILEGES.Player_Support_History_WRITE];//PRIVILEGES.WEBVIEW_WRITE,PRIVILEGES.SHOP_WRITE,
        } else if (state.roles.includes(ROLES.ROLE_READONLY)) {
            return [];
        }
    },
    roles(state) {
        return String(state.roles).split(",").map(e => e.trim());
    },
    didAutoLogout(state) {
        return state.didAutoLogout;
    }
};