import {fetching} from "../../../config/context";

export default {
    async downloadIfNotExist(context) {
        if ("titleData" in localStorage) {
            await context.dispatch("load")
        } else {
            await context.dispatch("download", context)
        }
    },
    async download(context) {
        console.log("loading.......");
        await fetching('/api/playfab/titleData?key=ShopData&key=ItemData&key=GlobalVariables&key=QuestData&key=QuestLineData&key=StarSystemData&key=UnitData').then(data => {
            // {"StatusCode":401,"Message":"Unexpected character encountered while parsing value: �. Path '', line 0, position 0."}
            console.log(typeof data);
            if (Object.keys(data).includes("StatusCode")){
                // console.log(data);
                // alert("StatusCode in titledata")
                return;
            }

            localStorage.setItem("titleData", JSON.stringify(data));
            context.commit('setTitle', data);
        }).catch(e => {
            const error = new Error(
                e.message || 'Failed to get the title data.'
            );
            throw error;
        });
    },

    load(context) {
        const titleData = JSON.parse(localStorage.getItem("titleData"));
        context.commit("setTitle", titleData)
    }
};
