<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <h1>Profile</h1>
    <hr/>
    <div class="container-fluid">
      <div class="row" >
        <w-card>
          <h2>Change Password</h2>

          <w-form @submit.prevent="submitForm" v-model="formIsValid">
            <w-input class="mt3" label="Previous Password" v-model.trim="oldPassword"
                     :type="isOldPassword ? 'password' : 'text'"
                     :inner-icon-right="isOldPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                     @click:inner-icon-right="isOldPassword = !isOldPassword"
                     :validators="[validators.required]"/>
            <w-input class="mt3" label="New Password" v-model.trim="newPassword"
                     :type="isNewPassword ? 'password' : 'text'"
                     :inner-icon-right="isNewPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                     @click:inner-icon-right="isNewPassword = !isNewPassword"
                     @keyup="changePassword"
                     :validators="[validators.required]"/>
            <w-input class="mt3" label="Confirm new Password" v-model.trim="confirmPassword"
                     :type="isConfirmPassword ? 'password' : 'text'"
                     :inner-icon-right="isConfirmPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                     @click:inner-icon-right="isConfirmPassword = !isConfirmPassword"
                     @keyup="changePassword"
                     :validators="[validators.required]"/>
            <div class="red" v-show="!matchPassword">Passwords don’t match.</div>
            <div class="text-right mt6">
              <w-button type="submit">
                Change
              </w-button>
            </div>
          </w-form>

        </w-card>
      </div>
    </div>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Changing..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

  </w-app>
</template>


<script>
import {ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {fetching} from "../config/context";

export default {
  name: "Profile",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    router;
    route;
    store;
    const formIsValid = ref(false);

    const oldPassword = ref(null);
    const isOldPassword = ref(true);
    const newPassword = ref(null);
    const isNewPassword = ref(true);
    const confirmPassword = ref(null);
    const isConfirmPassword = ref(true);

    const matchPassword = ref(true);

    const isLoading = ref(false);
    const error = ref(null);

    let validators =  {
      required: value => !!value || 'This field is required',
    }

    const submitForm = async () => {

      if (!formIsValid.value) {
        return;
      }

      if (newPassword.value !== confirmPassword.value){
        return;
      }

      isLoading.value = true;

      let apiUri = '/api/password/change';

      fetching(apiUri, {
        method: 'POST',
        body: {
          "oldPassword": oldPassword.value,
          "newPassword": newPassword.value
        }
      }).then( () => {
        router.replace("/");
      }).catch(err => {
        error.value = err.message || 'Failed to change the password, try later.';
      }).finally(() => {
        isLoading.value = false;
      });

    }
    const changePassword = () => {
      matchPassword.value = (newPassword.value == confirmPassword.value);
    }

    const handleError = () => {
      error.value = null;
    };

    return {
      formIsValid, isLoading, error, validators, matchPassword,
      oldPassword, isOldPassword, newPassword, isNewPassword, confirmPassword, isConfirmPassword,
      submitForm, handleError, changePassword
    }
  }
};
</script>


