<template>
  <w-app>
    <w-form v-model="valid">
      <div class="col">
        <textarea
          name="commnets"
          cols="200"
          rows="5"
          v-model="comment"
          placeholder="Enter Player support comments here... "
        ></textarea>

        <w-button
          class="ma6"
          @click="submitForm"
          shadow
          v-privilege="PRIVILEGES.PLAYER_WRITE"
          >Save</w-button
        >
      </div>
    </w-form>

    <div v-show="displayedItems.length > 0">
      <base-search
        @search="updateSearch"
        :search-term="enteredSearchTerm"
      ></base-search>
      <div>
        Sorting by Name:
        <w-button @click="sort('asc')" :outline="sorting != 'asc'"
          >Ascending</w-button
        >
        <w-button @click="sort('desc')" :outline="sorting != 'desc'"
          >Descending</w-button
        >
      </div>
      <div>
        Total: <b>{{ displayedItems.length }}</b> results
      </div>
    </div>

    <ul>
      <li v-for="item of displayedItems" :key="item.id">
        <div class="right-float">
          <b
            ><span
              class="right-float"
              v-ago="convertUTCDateToLocalDate(item.created)"/></b
          ><br />
          <span>{{
            convertUTCDateToLocalDate(item.created).toLocaleString()
          }}</span>
        </div>
        <div v-html="item.comment" class="ProseMirror"></div>
        <div>
          <hr />
          <a :href="'/admin/view/' + item.adminId">{{ item.adminName }}</a>
          <w-button
            bg-color="error"
            v-show="item.adminEmail === user"
            class="right-float"
            @click="deleteComment(item.id)"
            >delete</w-button
          >
        </div>
      </li>
    </ul>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>
<script>
import { computed, ref, toRefs } from "vue";
import useSearch from "../../hooks/search.js";
import useSort from "../../hooks/sort.js";
import { fetching } from "../../config/context";
import { useStore } from "vuex";
import { PRIVILEGES } from "../../config/context";

export default {
  props: ["items", "playerId"],
  emits: ["change-support-history"],
  components: {},
  setup(props, context) {
    const comment = ref("");
    const formIsValid = ref(false);
    const isLoading = ref(false);
    const error = ref(null);

    const store = useStore();
    const user = computed(() => store.getters.userId);

    const { items } = toRefs(props);
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
      items,
      ["comment"]
    );
    const { sorting, displayedItems, sort } = useSort(
      availableItems,
      "created"
    );

    const validators = {
      required: (value) => !!value || "This field is required",
    };

    const handleError = () => {
      error.value = null;
    };

    const submitForm = async () => {
      if (comment.value === "") {
        error.value = "No content";
        return;
      }
      isLoading.value = true;

      fetching(`/api/profile/${props.playerId}/comments`, {
        method: "POST",
        body: comment.value,
      })
        .then(() => {
          comment.value = "";
          context.emit("change-support-history");
        })
        .catch((err) => {
          error.value =
            err.message || "Failed to save the support note, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const deleteComment = async (id) => {
      if (!confirm("Do you want to delete this comment?")) {
        return;
      }

      isLoading.value = true;

      fetching(`/api/profile/${props.playerId}/comments/${id}`, {
        method: "DELETE",
      })
        .then(() => {
          context.emit("change-support-history");
        })
        .catch((err) => {
          error.value =
            err.message || "Failed to delete the support note, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const convertUTCDateToLocalDate = (dateString) => {
      const date = new Date(dateString);
      return date; //new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    };

    return {
      enteredSearchTerm,
      displayedItems,
      sorting,
      availableItems,
      comment,
      formIsValid,
      validators,
      isLoading,
      error,

      user,
      PRIVILEGES,
      submitForm,
      sort,
      updateSearch,
      handleError,
      deleteComment,
      convertUTCDateToLocalDate,
    };
  },
};
</script>
<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

.right-float {
  float: right;
}

.table-of-contents {
  /*--tw-border-opacity: 1;*/
  border-color: black;
  border-radius: 0.75rem;
  border-width: 3px;
  list-style-type: none;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: white;
}
button {
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
}
</style>
<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  /* Placeholder (at the top) */
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  /* Placeholder (on every new line) */
  /*.ProseMirror p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }*/

  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }
}
</style>
