<template>
  <section>
    <w-card>
      <h1>Page not found</h1>
      <p>
        This page could not be found.
        <br/>
        <w-button @click="$router.go(-1)">back</w-button>
      </p>
    </w-card>
  </section>
</template>