<template>
  <li>
    <div style="clear:both; display: flex;">
      <div>
        <v-lazy-image
            :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(thumbnail) + '.png'"
            onerror="this.onerror=null;this.src='/images/onerror.png'"
            class="item-thumbnail"
            style="width: 150px"
        />
      </div>
      <div style="width: 100%">
        <h3>
          {{ fullname }}
          <w-button bg-color="error" shadow @click="revokeItem(instanceId)" v-privilege="PRIVILEGES.PLAYER_WRITE" class="text-right">Revoke</w-button>
        </h3>

        <div><span>ID:</span> {{ id }} </div>
        <div><span>instanceId:</span> {{ instanceId }} </div>
        <div><span>purchaseDate:</span> {{ purchaseDate }}</div>
        <div v-show="itemClass"><span>class:</span> {{ itemClass }}</div>
        <div v-show="remainingUses"><span>remainingUses:</span> {{ remainingUses }}</div>
        <div v-show="expiration"><span>expiration:</span> {{ expiration }}</div>
        <div v-show="desc">{{ desc }}</div>
        <json-viewer v-show="customData"
                     :value="(customData==null)? {}: customData"
                     :expand-depth=10
                     copyable
                     boxed
                    />
      </div>
    </div>
  </li>
</template>

<script>
import { ref } from 'vue';
import VLazyImage from "v-lazy-image";
import {PRIVILEGES} from "../../config/context";
export default {
  components: {
    VLazyImage
  },
  props: [
    "id", "instanceId", "fullname", "desc", "purchaseDate",
    "itemClass", "customData", "remainingUses", "expiration", "getItemThumbnail"
  ],
  emits: ['revoke-item'],
  methods: {
    revokeItem(instanceId){
      this.$emit('revoke-item', instanceId);
    }
  },
  setup (props){
    const thumbnail = ref(props.getItemThumbnail(props.id));
    return {PRIVILEGES, thumbnail}
  }
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h3 {
  margin: 0;
  color: darkblue;
}

button {
  float: right;
}

span {
  color: darkgray;
}
</style>