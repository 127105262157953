<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2>GM {{(isCreate)? "Create": "Update"}}</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
        <table class="table table-hover">
          <thead>
            <tr>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody>
            <tr valign="middle">
              <th class="col-md-2 text-center">Email</th>
              <td>
                <w-input class="mt3" label="Email" outline static-label v-model.trim="email"
                         :validators="[validators.required, validators.validate_email]"  :disabled="!isCreate"/>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Name</th>
              <td>
                <w-input class="mt3" label="Name" outline static-label v-model.trim="name"
                         :validators="[validators.required]"/>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Roles</th>
              <td>
                <Multiselect
                    v-model="roles"
                    mode="tags"
                    :searchable="true"
                    :createTag="false"
                    :options="rolesOption"
                />
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Privileges</th>
              <td>
                <w-input class="mt3" label="Privileges" outline static-label v-model.trim="privileges"
                         :validators="[validators.required]"/>
              </td>
            </tr>
            <tr valign="middle">
              <th class="text-center">Active Status</th>
              <td>
                <w-checkbox v-model="status" label-on-left icon class="mt-2"></w-checkbox>
              </td>
            </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2" class="text-right">
              <w-button type="submit" v-privilege="PRIVILEGES.ADMIN_WRITE">
                {{(isCreate)? "Submit": "Update"}}
              </w-button>
            </td>
          </tr>
          </tfoot>
        </table>
        </w-form>
      </div>
    </div>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-notification
        v-model="showNotification"
        success
        plain
        round
        shadow
        absolute
        transition="bounce"
        show
    >
      The user was {{(isCreate)? "created": "updated"}}.
    </w-notification>
  </w-app>
</template>

<script>
import {fetching, ROLES} from "../../config/context";
import {ref, onBeforeMount} from "vue";
import {useRoute, useRouter} from 'vue-router'
import Multiselect from '@vueform/multiselect'
import {PRIVILEGES} from "../../config/context";
export default {
  name: "AdminForm",
  components: {
    Multiselect
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const isCreate = ref(false);
    const updateKey = ref("");
    const name = ref("");
    const email = ref("");
    const roles = ref([]);
    const privileges = ref("");
    const status = ref(false);
    const rolesOption = ref([]);

    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const showNotification = ref(false);

    const activityLogsLink = ref("");


    const validators =  {
      required: value => !!value || 'This field is required',
      min_8_length: value => (value.length >= 4) || 'This field must be at least 8 characters long.',
      validate_email: value => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) || 'The email must be valid.'
    }

    const submitForm = () => {
      console.log("formIsValid:"+formIsValid.value);
      if (!formIsValid.value) {
        return
      }

      isLoading.value = true;

      let apiUri = '/api/admin/users';
      let method = "POST";

      // if it is update user form,
      if (!isCreate.value){
        apiUri += '/' + updateKey.value;
        method = "PUT";
      }else{
        const path = document.location.origin + "/public/setPassword";
        apiUri += "?path=" + path
      }

      fetching(apiUri, {
        method: method,
        body: {
          "name": name.value,
          "email": email.value,
          "roles": roles.value.join(','),
          "privileges": privileges.value,
          "status": status.value
        }
      }).then( () => {
        showNotification.value = true;
        router.push("/admin"); //todo: magic string
      }).catch(e => {
        error.value = e.message || 'Failed to create the User, try later.';
        isLoading.value = false;
      }).finally(() => {
        isLoading.value = false;
      });

    }

    const handleError = () => {
      error.value = null;
    };

    onBeforeMount(() => {
      rolesOption.value = Object.keys(ROLES).map(key => {
        const roleName = ROLES[key];
        return { value: roleName, label: roleName }
      });

      if (route.path.endsWith("new")){
        isCreate.value = true;
        return;
      }

      const uuid = route.path.split("/").reverse()[0];
      activityLogsLink.value = "/activityLog/" + uuid;

      isLoading.value = true;
      fetching('/api/admin/users/' + uuid, ).then( data => {

        email.value = data.email;
        roles.value = data.roles.split(",");
        privileges.value = data.privileges;
        name.value = data.name;
        status.value = data.status;

        updateKey.value = data.userId;

      }).catch(e => {
        error.value = e.message || 'Failed to get the User, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });

    return {
      name, email, roles, privileges, formIsValid,
      status, isLoading, error, validators, showNotification, isCreate, updateKey,
      submitForm, handleError, PRIVILEGES, ROLES, rolesOption
    }
  }
};
</script>
