import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback, navigationGuard } from '@okta/okta-vue'

import store from './store/index.js';

import HelloWorld from "./components/HelloWorld.vue"
import AuthorizationByOkta from "./components/Okta/AuthorizationByOkta"
import NotFound from './views/NotFound.vue';
import NotAllow from './views/NotAllow.vue';
import UserLogin from './views/auth/UserLogin.vue';
import TwoFactor from './views/auth/TwoFactor.vue';
import Index from './views/Index.vue';
import Profile from './views/Profile.vue';
import Layout from './components/Layout.vue';
import LiveSheet from './views/LiveSheet.vue';
import Security from './views/Security.vue';
// import Inventory from './views/Inventory/Inventory.vue';
import GiveItemToPlayer from './views/GiveItemToPlayer/GiveItemToPlayer.vue';
import BatchItemSend from './views/GiveItemToPlayer/BatchItemSend.vue';
import Announcement from './views/Announcement.vue';
import Maintenance from './views/Maintenance.vue';
import Auditing from './views/Auditing.vue';
import Rights from './views/Rights.vue';
import ActivityLog from './views/Profile/ActivityLog.vue';
import PlayerList from './views/Players/PlayerList.vue';
import PlayerView from './views/Players/PlayerView.vue';
import AdminList from './views/AdminManager/AdminList.vue';
import AdminView from './views/AdminManager/AdminView.vue';
import AdminForm from './views/AdminManager/AdminForm.vue';
import Shop from './views/Shop/Shop.vue';
import SetPassword from './views/Public/SetPassword.vue';
import MFASave from './views/Public/MFASave.vue';
import GroupMailEditor from './views/Mail/GroupMailEditor.vue';
import BannedPlayerList from './views/BannedPlayer/BannedPlayerList.vue';
import PremiumAccountLog from './views/PremiumAccountLog/PremiumAccountLog.vue';

import BannerForm from './views/WebViewBanner/BannerForm.vue';
import BannerList from './views/WebViewBanner/BannerList.vue';
import MenuBannerForm from './views/WebViewBanner/MenuBannerForm.vue';

import SegmentsPlayerList from './views/Segments/SegmentsPlayerList.vue'
import SegmentsList from './views/Segments/SegmentsList.vue'
import GroupsList from './views/Groups/GroupsList.vue'
import GroupsForm from './views/Groups/GroupsForm.vue'

import DynamicSegment from './views/DynamicSegment/DynamicSegment.vue'
import DynamicSegmentForm from './views/DynamicSegment/DynamicSegmentForm.vue'
import DynamicSegmentsPlayerList from './views/DynamicSegment/DynamicSegmentsPlayerList.vue'


import ClansList from './views/Clans/ClansList.vue'
import InvalidClans from './views/Clans/InvalidClans.vue'
import ClansForm from './views/Clans/ClansForm.vue'
import ClanSummary from './views/Clans/ClanSummary.vue'
import ClansMemberList from './views/Clans/ClansMemberList.vue'
import PlayerSupportHistory from './views/PlayerSupport/PlayerSupportHistory.vue'
import PlayerSupportHistoryForm from './views/PlayerSupport/PlayerSupportHistoryForm.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/login/callback', component: LoginCallback, meta: { permitAll: true }
        },
        {
            path: '/login/okta', component: AuthorizationByOkta, meta: { requiresAuth: true, permitAll: true },
            beforeEnter: navigationGuard
        },
        { path: '/login', component: UserLogin, meta: { permitAll: true } },
        { path: '/twoFactor', component: TwoFactor, meta: { permitAll: true } },
        { path: '/public/setPassword', component: SetPassword, meta: { permitAll: true } },
        { path: '/public/mfaSave', component: MFASave, meta: { permitAll: true } },

        {
            path: '',
            component: Layout,
            meta: { permitAll: false },
            children: [
                {
                    path: '/index', component: Index, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/mail/editor', component: GroupMailEditor, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/bannedplayerlist', component: BannedPlayerList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/premiumaccountlog', component: PremiumAccountLog, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/hello', component: HelloWorld, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/players', component: PlayerList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/players/:id', component: PlayerView, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/profile', component: Profile, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/liveSheet', component: LiveSheet, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/security', component: Security, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    // this part used inventory  but now is using new giving item to player
                    path: '/inventory', component: GiveItemToPlayer, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    // this part used inventory  but now is using new giving item to player
                    path: '/batch-item-send', component: BatchItemSend, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/give-item-to-player', component: GiveItemToPlayer, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/announcement', component: Announcement, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/rights', component: Rights, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/shop', component: Shop, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/activityLog', component: ActivityLog, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/activityLog/:id', component: ActivityLog, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/maintenance', component: Maintenance, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/auditing', component: Auditing, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/admin', component: AdminList, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/admin/new', component: AdminForm, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/admin/view/:id', component: AdminView, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/admin/edit/:id', component: AdminForm, meta: { roles: ['ADMIN'] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/segments', component: SegmentsList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/segments/:id/players', component: SegmentsPlayerList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/groups', component: GroupsList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/dynamic_segment', component: DynamicSegment, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/player-support-history', component: PlayerSupportHistory, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/player-support-history/new', component: PlayerSupportHistoryForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/player-support-history/edit/:id', component: PlayerSupportHistoryForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },

                {
                    path: '/clans', component: ClansList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/InvalidClans', component: InvalidClans, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/clans/new', component: ClansForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/clans/ClanSummary', component: ClanSummary, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/clans/edit/:id', component: ClansForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/clans/clansmemberList/:id', component: ClansMemberList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/groups/new', component: GroupsForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/groups/edit/:id', component: GroupsForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/dynamic_segment/new', component: DynamicSegmentForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/dynamic_segment/edit/:id', component: DynamicSegmentForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/dynamic_segment/players_list/:id', component: DynamicSegmentsPlayerList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/webview/banner/form/:id', component: BannerForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/webview/menu-banner/form/:id', component: MenuBannerForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/webview/banner/form/new', component: BannerForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/webview/menu-banner/form/new', component: MenuBannerForm, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },
                {
                    path: '/webview/banner/list', component: BannerList, meta: { roles: ['ADMIN', "GM", "CS", "READONLY"] },
                    get props() { return breadcrumbs(this.path) }
                },

                { path: '/notAllow', component: NotAllow }
            ],
            redirect: "/index"
        },
        { path: '/:notFound(.*)', component: NotFound },

    ]
});

function breadcrumbs(path) {
    // console.log(path);
    let items = null;

    if (path === '/index') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Server Status', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/hello') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Players ', route: '/players' },
            { label: 'Players List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/players') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Players ', route: '/players' },
            { label: 'Search players', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/players/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Players ', route: '/players' },
            { label: 'Detail', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/profile') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'My Page ', route: '/Profile' },
            { label: 'Change Password', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/liveSheet') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Live Sheet ', route: '/liveSheet' },
            { label: 'Title Data', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/security') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'security ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/shop') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Shop ', route: '/shop' },
            { label: 'Shop Management', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/inventory') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Inventory', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/give-item-to-player') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Give Item To Player', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/announcement') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Announcement ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/rights') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'It will be better soon. ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/activityLog') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Activity Log', route: '/activityLog' },
            { label: 'List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/activityLog/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Activity Log', route: '/activityLog' },
            { label: '????', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/maintenance') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'It will be better soon. ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/auditing') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'It will be better soon. ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/admin') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'GM Accounts ', route: '/admin' },
            { label: 'List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/admin/new') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'GM Accounts ', route: '/admin' },
            { label: 'Create', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/admin/view/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'GM Accounts ', route: '/admin' },
            { label: 'Detail', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/admin/edit/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'GM Accounts ', route: '/admin' },
            { label: 'Update', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/groups') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Groups', route: '/groups' },
            { label: 'List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/groups/new') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Groups', route: '/groups' },
            { label: 'Create', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/groups/edit/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Groups', route: '/groups' },
            { label: 'Update', route: '/w-breadcrumbs' }
        ]
    }
    else if (path === '/dynamic_segment') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Dynamic Segment', route: '/dynamic_segment' },
            { label: 'List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/dynamic_segment/new') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Dynamic Segment', route: '/dynamic_segment' },
            { label: 'Create', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/dynamic_segment/edit/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Dynamic Segment', route: '/dynamic_segment' },
            { label: 'Update', route: '/w-breadcrumbs' }
        ]
    }
    else if (path === 'dynamic_segment/players_list/:id') {
        items = [
            { label: 'Dynamic Segment', route: '/dynamic_segment' },
            { label: 'Dynamic Segment Player List', route: '/dynamic_segment/players_list/:id' },
            { label: 'Dynamic Segment Player List', route: '/w-breadcrumbs' }
        ]
    }
    else if (path === '/segments') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Segments', route: '/segments' },
            { label: 'Segments List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/segments/:id/players') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Segments', route: '/segments' },
            { label: 'Player in Segments', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/clans') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Clans', route: '/' },
            { label: 'Clans List', route: '/w-breadcrumbs' }
        ]
    }
    else if (path === '/InvalidClans') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Clans', route: '/' },
            { label: 'Clans List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/clans/edit/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Clan members List', route: '/clans' },
            { label: 'Clan members List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/clans/ClanSummary') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'ClanSummary', route: '/clans' },
            { label: 'ClanSummary', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/clans/clansmemberList/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Clan members List', route: '/clans' },
            { label: 'Clan members List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/webview/banner/list') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Webview Banner', route: '/webview/banner/list' },
            { label: 'List', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/webview/banner/form/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Webview Banner', route: '/webview/banner/list' },
            { label: 'Edit', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/webview/banner/form/new') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Webview Banner', route: '/webview/banner/list' },
            { label: 'New', route: '/w-breadcrumbs' }
        ]
    }
    else if (path === '/webview/menu-banner/form/:id') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Menu Banner', route: '/webview/menu-banner/list' },
            { label: 'Edit', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/webview/menu-banner/form/new') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Menu Banner', route: '/webview/menu-banner/list' },
            { label: 'New', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/mail/editor') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Group Mail', route: '/template/mail' },
            { label: 'Editor', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/bannedplayerlist') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Banned Player List', route: '/bannedplayerlist' },
            { label: 'Editor', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/premiumaccountlog') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Premium Account List', route: '/premiumaccountlog' },
            { label: 'Editor', route: '/w-breadcrumbs' }
        ]
    } else if (path === '/batch-item-send') {
        items = [
            { label: 'Home', route: '/' },
            { label: 'Batch Item Send', route: '/batch-item-send' },
            { label: 'Editor', route: '/w-breadcrumbs' }
        ]
    } else {
        items = [
            { label: 'Home', route: '/' },
            { label: 'It will be better soon. ', route: '/' },
            { label: 'Current page', route: '/w-breadcrumbs' }
        ]
    }

    return {
        breadcrumbs: items
    };
}

router.beforeEach(function (to, _, next) {

    if (!to.meta.permitAll && !store.getters.isAuthenticated) {
        next('/login');
        return;
    }

    if (to.meta && to.meta.roles) {
        const array1 = store.getters.roles;
        const array2 = to.meta.roles;
        if (!array1.some(r => array2.includes(r))) {
            next('/notAllow');
            return;
        }
    }

    next();
});

export default router;
