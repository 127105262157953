<template>
  <w-app>
    <Nav/>
    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Authenticating..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <div class="d-flex justify-content-center">
      <w-card style="width: 640px; padding: 20px; margin-top:100px" shadow>
        <h2>Please sign in :)</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
          <div>
            <w-input
                label="E-Mail"
                v-model.trim="email"
                :validators="[validators.required]">
            </w-input>
          </div>
          <div>
            <w-input
                label="Password"
                v-model.trim="password"
                :type="isPassword ? 'password' : 'text'"
                :inner-icon-right="isPassword ? 'mdi mdi-eye-off' : 'mdi mdi-eye'"
                @click:inner-icon-right="isPassword = !isPassword"
                :validators="[validators.required, validators.min_8_length]">
            </w-input>
          </div>
          <div>
            <span>
              <w-button type="submit" lg shadow> Login </w-button>
            </span>
            <span>
              <router-link to="/password" >Can’t access account?</router-link>
            </span>
          </div>
        </w-form>
        <br>
        <div>
          <w-divider class="mx6">OR</w-divider>
        </div>
        <br>
        <w-flex>
          <w-button class="ma1 grow " xl round :onclick="goOkta"><img src="https://www.gearboxsoftware.com/assets/images/gbx_software_logo_60.png" width="140">&nbsp; Login</w-button>
        </w-flex>
      </w-card>
    </div>
  </w-app>
</template>

<script>
import {ref, onBeforeMount} from 'vue';
import {useRouter} from 'vue-router';
import {useCookie} from 'vue-cookie-next'
import {fetching} from "../../config/context";

import Nav from "@/components/Nav.vue";

export default {
  components : {
    Nav
  },
  setup() {
    const cookie = useCookie();
    const router = useRouter();

    const formIsValid = ref(false);

    const email = ref(null);
    const password = ref(null);
    const isPassword = ref(true);

    const isLoading = ref(false);
    const error = ref(null);

    let validators =  {
      required: value => !!value || 'This field is required',
      min_8_length: value => (value.length >= 4) || 'This field must be at least 8 characters long.'
    }

    const submitForm = async () => {

      if (!formIsValid.value) {
        return
      }

      isLoading.value = true;

      if (email.value.toString().endsWith("@gearboxsoftware.com")) {
        await goOkta();
        return;
      }

      fetching('/api/login', {
        method: 'POST',
        body: {
          userId: email.value,
          password: password.value
        }
      }).then( async response => {

        localStorage.setItem("email", email.value);

        cookie.setCookie('mfa', {
          token: response.token
        });

        await router.push('/twoFactor');

      }).catch(err => {
        error.value = err.message || 'Failed to save the password, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const handleError = () => {
      error.value = null;
    };

    const goOkta = async () => {
      await router.push('/login/okta');
    };

    onBeforeMount(() => {
      email.value = localStorage.getItem("email");
    });

    return {
      formIsValid, email, password, isPassword, isLoading, error, validators,
      submitForm, handleError, goOkta
    }
  }
};
</script>
<style scoped>
 form div {
   padding-block-start: 10px;
 }
 div span {
   margin-right: 10px;
 }
</style>
