<template>
  <li>
    <h3 :class="active? 'active' : ''">{{ reason }}
      <w-button bg-color="error" shadow v-show="active" @click="revokeBan(id)" v-privilege="PRIVILEGES.PLAYER_WRITE">Revoke</w-button>
    </h3>
    <div><span>active:</span> {{ active }} </div>
    <div><span>ID:</span> {{ id }} </div>
    <div v-show="iPAddress"><span>IPAddress:</span> {{ iPAddress }} </div>
    <div>
      <span>created:</span> <span v-ago="created">Permanent</span>
    </div>
    <div>
      <span>expires:</span> <span v-ago="expires">Permanent</span>
    </div>
  </li>
</template>

<script>
import {PRIVILEGES} from "../../config/context";

export default {
  props: ["active", "created", "expires", "iPAddress", "reason", "id"],
  emits: ['revoke-ban'],
  methods: {
    revokeBan(id){
      this.$emit('revoke-ban', id);
    }
  },
  setup(){
    return {PRIVILEGES}
  }
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h3 {
  margin: 0;
  color: darkgray;
}

li h3.active{
  color: darkblue;
}

button {
  float: right;
}
span {
  color: darkgray;
}
</style>