<template>
  <w-app>
    <h1>Auditing</h1>
    <hr />
  </w-app>
</template>

<script>
export default {
  name: "Auditing",
};
</script>

<style scoped></style>
