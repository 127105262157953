<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Dynamic Segment List</h2>
        <PlayerSegmentSearch></PlayerSegmentSearch>
        <div style="margin-top: 20px" v-show="items">
          <table class="table table-bordered table-hover ">
            <thead>
              <tr class="table-active text-center">
                <th>#</th>
                <th>Description</th>
                <th>NextRun</th>
                <th>LastRun</th>
                <th>Cron Pattern</th>

                <th>Created At</th>
                <th>Edir</th>
                <th>Players List</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.rowKey"
                scope="row"
                :class="item.status ? '' : 'inactive'"
                valign="middle"
              >
                <td class="text-center col-md-1">{{ index + 1 }}</td>
                <td>{{ item.cronDescription }}</td>
                <td>{{ new Date(item.nextRun ?? "").toLocaleString() }}</td>
                <td>{{ new Date(item.lastRun ?? "").toLocaleString() }}</td>
                <td>{{ item.cronPattern }}</td>
                <td>{{ new Date(item.createdAt ?? "").toLocaleString() }}</td>

                <td>
                  <a :href="'/dynamic_segment/edit/' + item.id">{{
                    item.cronDescription
                  }}</a>
                </td>

                <td>
                  <a :href="'/dynamic_segment/players_list/' + item.id">{{
                    item.cronDescription
                  }}</a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8" class="text-right">
                  <w-button
                    route="/dynamic_segment/new"
                    v-privilege="PRIVILEGES.DYNAMIC_SEGMENT_WRITE"
                    shadow
                    >Create New Dynamic Segment</w-button
                  >
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Loading..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { PRIVILEGES } from "../../config/context";
import { fetching } from "../../config/context";
import PlayerSegmentSearch from "../../components/player/PlayerSegmentSearch.vue";
export default {
  components: { PlayerSegmentSearch },
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const isLoading = ref(true);
    const error = ref(null);
    const items = ref([]);

    onBeforeMount(() => {
      fetching("/api/dynamic_segment/")
        .then((data) => {
          items.value = data;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to load the Dynamic Segments, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items,
      isLoading,
      error,
      PRIVILEGES,
      handleError,
    };
  },
};
</script>
