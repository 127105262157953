<template>
  <router-view/>
<!--  TODO: transition error? -->
<!--  <router-view v-slot="slotProps">-->
<!--    <transition name="route" mode="out-in">-->
<!--      <component :is="slotProps.Component"></component>-->
<!--    </transition>-->
<!--  </router-view>-->
</template>

<style>
.bg-dark{
  background-color: black !important;
}
.bg-production-dark{
  background-color: #0A0F35 !important;
}
#centerAlignDialog{
  text-align: center;
}

.item-thumbnail {
  float: left;
  /*width: 150px;*/
  /*height: 150px;*/
  margin-right: 20px;
  transform:scale(1.0);
  z-index: 9999;

}
.item-thumbnail:hover {
  transform:scale(2.0);
  transition: transform .5s;
  backface-visibility: hidden;
}
</style>

<script>
import {onBeforeMount} from 'vue';
import {useStore} from 'vuex';
export default {
  setup () {
    const store = useStore();
    onBeforeMount(async () => {
      await store.dispatch('tryLogin');
      await store.dispatch('title/downloadIfNotExist');
      await store.dispatch('stringTable/downloadIfNotExist');
    })
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>