<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row">
        <h2>Player Support</h2>
        <w-form @submit.prevent="submitForm" v-model="formIsValid">
          <table class="table table-hover">
            <thead>
              <tr>
                <th colspan="2"></th>
              </tr>
            </thead>
            <tbody>
              <tr valign="middle">
                <th class="text-center">Time</th>
                <td>
                  <w-input
                    class="mt3"
                    label="Time"
                    outline
                    static-label
                    v-model.trim="time"
                    :validators="[validators.required]"
                  />
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Comments</th>
                <td>
                  <w-textarea
                    outline
                    data-gramm="false"
                    v-model.trim="comments"
                    :validators="[validators.required]"
                  ></w-textarea>
                </td>
              </tr>
              <tr valign="middle">
                <th class="text-center">Player Id</th>
                <td>
                  <w-textarea
                    outline
                    data-gramm="false"
                    v-model.trim="playerId"
                    :validators="[validators.required]"
                  ></w-textarea>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="text-right">
                  <w-button
                    bg-color="error"
                    shadow
                    @click="deletePlayerSuportHsitory"
                    v-show="!isCreate"
                    v-privilege="PRIVILEGES.Player_Support_History_WRITE"
                    >delete</w-button
                  >
                  <w-button
                    bg-color="info"
                    shadow
                    type="submit"
                    v-privilege="PRIVILEGES.Player_Support_History_WRITE"
                  >
                    {{ isCreate ? "Submit" : "Update" }}
                  </w-button>
                </td>
              </tr>
            </tfoot>
          </table>
        </w-form>
      </div>
    </div>

    <w-dialog
      v-model="error"
      title="An error occurred"
      transition="bounce"
      :width="400"
    >
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog
      id="centerAlignDialog"
      v-model="isLoading"
      title="Waiting..."
      persistent
      :width="400"
    >
      <div class="spinner-border text-primary" />
    </w-dialog>
  </w-app>
</template>

<script>
import { fetching, ROLES } from "../../config/context";
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { PRIVILEGES } from "../../config/context";
export default {
  name: "Player Support Hsitory Form",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  setup: function() {
    const route = useRoute();
    const router = useRouter();

    const uuid = ref(null);
    const isCreate = ref(false);

    const time = ref("");
    const comments = ref("");

    const playerId = ref("");

    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const validators = {
      required: (value) => !!value || "This field is required",
    };

    let apiUri = "/api/player-support-history";

    const deletePlayerSupportHsitory = () => {
      if (
        confirm(
          'Are you sure?\nDo you really want to delete "' + time.value + '"?'
        )
      ) {
        isLoading.value = true;

        fetching(apiUri + "/" + uuid.value, {
          method: "DELETE",
        })
          .then(() => {
            router.push("/api/player-support-history"); //todo: magic string
          })
          .catch((e) => {
            error.value =
              e.message ||
              "Failed to delete the Player Support History, try later.";
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    const submitForm = () => {
      if (!formIsValid.value) {
        return;
      }

      isLoading.value = true;

      let method = "POST";
      const body = {
        time: time.value,
        comments: comments.value,
        playerId: playerId.value,
      };

      if (!isCreate.value) {
        method = "PUT";
        body.id = uuid.value;
      }

      fetching(apiUri, {
        method: method,
        body: body,
      })
        .then(() => {
          router.push("/player-support-history"); //todo: magic string
        })
        .catch((e) => {
          error.value =
            e.message ||
            "Failed to create the player support history, try later.";

          isLoading.value = false;
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    const handleError = () => {
      error.value = null;
    };

    onBeforeMount(() => {
      if (route.path.endsWith("new")) {
        isCreate.value = true;
        return;
      }

      uuid.value = route.path.split("/").reverse()[0];
      isLoading.value = true;
      fetching(apiUri + "/" + uuid.value)
        .then((data) => {
          time.value = data.time;
          comments.value = data.comments;
          playerId.value = data.playerId;
        })
        .catch((e) => {
          error.value =
            e.message || "Failed to get the DynamicSegment, try later.";
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    return {
      uuid,
      time,
      comments,
      formIsValid,
      playerId,
      isLoading,
      error,
      validators,
      isCreate,
      submitForm,
      handleError,
      deletePlayerSupportHsitory,
      PRIVILEGES,
      ROLES,
    };
  },
};
</script>
