<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <div class=" mt8 pa3">
      <w-card title="Clan Info" title-class="primary-dark1--bg">
        <w-flex row class="text-lef">
          <div class="xs4 pa2 box sh-4">Clan Id : {{ uuid }} </div>
          <div class="xs3 pa2 box sh-4">MasterId : <a :href='"/players/" + pfMasterId'>{{ pfMasterId }} </a></div>
          <div class="xs3 pa2 box sh-4">EntityId : <a :href='"/players/" + pfEntityId'>{{ pfEntityId }} </a> </div>
          <div class="xs2 pa2 box sh-4"> <a :href='"/clans/clansmemberList/" + uuid'>MemberCount : {{ memberCount
          }} </a> </div>
        </w-flex>
      </w-card>


    </div>
    <w-form @submit.prevent="submitForm" v-model="formIsValid">

      <w-flex wrap>
        <div class="xs1 text-right pa3 ma1">
          <div class="xs12 py4">Name:</div>
          <div class="xs12 py4">Acronym:</div>
          <div class="xs12 py4">Description:</div>
          <div class="xs12 py4">Icon Id:</div>
        </div>
        <div class="xs3 py3">
          <div class="xs12 py3"><w-input v-model.trim="ClanName" :validators="[validators.required]">
            </w-input>
          </div>
          <div class=" xs12 py3">
            <w-input v-model.trim="acronym" :validators="[validators.required]">
            </w-input>
          </div>
          <div class="xs12 py3"> <w-input v-model.trim="description">
            </w-input>
          </div>
          <div class="xs12 py3"> <w-input v-model.trim="iconId" :validators="[validators.required]">
            </w-input>
          </div>

        </div>
        <div class=" xs1 py3 text-right pa2">
          <div class="xs12 py4">leader Id:</div>
          <div class="xs12 py4">leaderNickname:</div>
          <div class="xs12 py4">Playfab Id:</div>
          <div class="xs12 py4">Created At:</div>
        </div>


        <div class="xs3 py3">
          <div class="xs12 py3"> <w-input v-model.trim="leaderId" :validators="[validators.required]">
            </w-input>
          </div>
          <div class="xs12 py3"> <w-input v-model.trim="leaderNickname" :validators="[validators.required]">
            </w-input>
          </div>
          <div class="xs12 py3">
            <w-input v-model.trim="playfabId" :validators="[validators.required]">
            </w-input>

          </div>
          <div class="xs12 py3"> <w-input v-model.trim="createdAt" :validators="[validators.required]">
            </w-input>
          </div>
        </div>

        <div class=" xs1 py3 text-right pa1">
          <div class="xs12 py4">State:</div>
          <div class="xs12 py4">Purpose:</div>
          <div class="xs12 py4">Rank:</div>
          <div class="xs12 py4">XP:</div>
        </div>
        <div class=" xs1 py3">
          <div class="xs12 py3"> <w-select :items="State.items" v-model="state">

            </w-select>
          </div>
          <div class="xs12 py3"> <w-select :items="ClanPurpose.items" v-model="purpose">

            </w-select>
          </div>
          <div class="xs12 py3"> <w-input v-model.trim="rank" :validators="[validators.required]">
            </w-input>
          </div>
          <div class="xs12 py3"> <w-input v-model.trim="xp" :validators="[validators.required]">
            </w-input>
          </div>
        </div>

        <w-flex column align-center justify-center class="wrapper">
          <w-button class="xs6 py3" type="submit">
            Save change
          </w-button>
          <w-button class="xs6 py3" bg-color="error" type="delete">
            Delete
          </w-button>
        </w-flex>

      </w-flex>
    </w-form>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary" />
    </w-dialog>

  </w-app>
</template>

<script>
import { fetching, ROLES } from "../../config/context";
import { ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { PRIVILEGES } from "../../config/context";
export default {
  name: "ClansForm",
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup: function () {
    const route = useRoute();
    const router = useRouter();

    const uuid = ref(null);
    const isCreate = ref(false);

    const pfMasterId = ref("");
    const pfEntityId = ref("");
    const acronym = ref("");
    const ClanName = ref("");
    const iconId = ref("");
    const description = ref("");
    const leaderId = ref("");
    const leaderNickname = ref("");
    const purpose = ref(0);
    const language = ref(0);
    const memberCount = ref(0);
    const territoryCount = ref("");
    const state = ref(0);
    const rank = ref(0);
    const xp = ref(0);
    const createdAt = ref("");
    const playfabId = ref("");
    const responsdata = ref();


    const formIsValid = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const validators = {
      required: value => !!value || 'This field is required',
    }

    const State = {
      items: [
        { label: 'None', value: 0 },
        { label: 'Opend', value: 1 },
        { label: 'Closed', value: 2 }
      ]
    }

    const ClanPurpose = {
      items: [
        { label: 'None', value: 0 },
        { label: 'PvE', value: 1 },
        { label: 'PvP', value: 2 },
        { label: 'Endgame', value: 3 },
        { label: 'Social', value: 4 }
      ]
    }

    const deleteClan = () => {

      if (confirm('Are you sure?\nDo you really want to delete "' + ClanName.value + '"?')) {
        isLoading.value = true;

        fetching('/api/Alliance/AllianceDelete/' + uuid.value, {
          method: 'DELETE',
        }).then(() => {
          router.push("/clans"); //todo: magic string
        }).catch(e => {
          error.value = e.message || 'Failed to delete the Clan, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
      }
    }

    const submitForm = () => {
      if (!formIsValid.value) {
        return
      }
      isLoading.value = true;

      let method = "POST";
      const body = {
        "clanid": uuid.value,
        "acronym": acronym.value,
        "name": ClanName.value,
        "description": description.value,
        "leaderId": leaderId.value,
        "playfabId": playfabId.value,
        "iconId": iconId.value,
        "pfMasterId": pfMasterId.value,
        "pfEntityId": pfEntityId.value,
        "leaderNickname": leaderNickname.value,
        "purpose": purpose.value,
        "language": language.value,
        "memberCount": memberCount.value,
        "territoryCount": territoryCount.value,
        "state": state.value,
        "rank": rank.value,
        "xp": xp.value,
        "createdAt": createdAt.value
      }

      if (!isCreate.value) {
        method = "PUT";
        body.id = uuid.value;
      }

      console.log("_________");
      console.log(body);

      fetching('/api/Alliance/AllianceUpdate', {
        method: method,
        body: body
      }).then(
        data => {
          responsdata.value = data;
          error.value = responsdata.value.errorMessage || 'clan Changeed successfully';

        }).catch(e => {
          error.value = e.message || 'Failed to create the Clan, try later.';
          isLoading.value = false;
        }).finally(() => {
          isLoading.value = false;
        });
    }

    const handleError = () => {
      error.value = null;
    };


    onBeforeMount(() => {
      if (route.path.endsWith("new")) {
        isCreate.value = true;
        return;
      }

      uuid.value = route.path.split("/").reverse()[0];

      isLoading.value = true;


      const body = {
        "ClanId": uuid.value,
        "playerId": ""
      }

      fetching('/api/Alliance/AllianceGetbyId', { method: "POST", body: body }).then(data => {
        acronym.value = data.alliance.data.acronym;
        ClanName.value = data.alliance.data.name;
        description.value = data.alliance.data.description;
        iconId.value = data.alliance.data.iconId;
        leaderId.value = data.alliance.leaderId;
        pfMasterId.value = data.alliance.pfMasterId;
        pfEntityId.value = data.alliance.pfEntityId;
        memberCount.value = data.alliance.memberCount;
        purpose.value = data.alliance.purpose;
        language.value = data.alliance.language;
        territoryCount.value = data.alliance.territoryCount;
        state.value = data.alliance.state;
        rank.value = data.alliance.rank;
        xp.value = data.alliance.xp;
        createdAt.value = data.alliance.createdAt;
        playfabId.value = data.alliance.leaderId;
      }).catch(e => {
        error.value = e.message || 'Failed to get the Clan, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    });

    return {
      uuid, acronym, ClanName, description, memberCount, playfabId, iconId, pfMasterId,
      pfEntityId, leaderId, leaderNickname, purpose, language, territoryCount, state,
      rank, xp, createdAt, formIsValid, isLoading, error, validators, isCreate,
      submitForm, handleError, deleteClan, State, ClanPurpose,
      PRIVILEGES, ROLES
    }
  }
};
</script>



<style scoped>
w-select {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box {

  height: 40px;
  margin: 2px;
  background-color: #e3f2fd;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
