<template>
  <w-app>
    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div>
      Sorting by Name:
      <w-button @click="sort('asc')" :outline="sorting != 'asc'">Ascending</w-button>
      <w-button @click="sort('desc')" :outline="sorting != 'desc'">Descending</w-button>
    </div>
    <div v-show="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <ul>
      <characters-item
                  v-for="item in displayedItems"
                  :key="item.CharacterId"
                  :name="item.CharacterName"
                  :id="item.CharacterId"
                  :fullname="item.CharacterFullName"
                  :desc="item.CharacterDesc"
                  :playfabId="playfabId"
                  :getItemFullName="getItemFullName"
                  :getItemDesc="getItemDesc"
                  :getItemThumbnail="getItemThumbnail"
                  :currentFlagship="currentFlagship"
                  :superCapitalFlagshipList="superCapitalFlagship"
                  @set-flagship="setFlagship"
              />
    </ul>
  </w-app>
</template>

<script>
import { toRefs } from 'vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import CharactersItem from "./CharactersItem";
import {useStore} from "vuex";

export default {
  components: {
    CharactersItem
  },
  props: ['items', 'playfabId', 'getItemFullName', 'getItemDesc', 'getItemThumbnail', 'currentFlagship'],
  emits: ['set-flagship'],
  setup(props, context) {
    const store = useStore();
    const unitData = JSON.parse(store.getters["title/unitData"]);
    const superCapitalFlagship = Object.entries(unitData)
                                        .filter(([, val])=> val["ShipType"] === 'SuperCapital_Flag')
                                        .map(x => x[0]);

    const { items } = toRefs(props);
    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        items,
        ['CharacterId', 'CharacterName', "CharacterFullName"]
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems,
        'CharacterFullName'
    );

    const setFlagship = (id) => {
      context.emit("set-flagship", id);
    }

    return {
      enteredSearchTerm, displayedItems, sorting, availableItems, superCapitalFlagship,
      sort, updateSearch, setFlagship
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
