<template>
  <li>
    <h3>
      Admin <b>{{ method }}</b> the <b>{{ category }}</b> for this Player
    </h3>
    <div>Id: {{ id }}</div>
    <div>
      Admin Name: <a :href="'/admin/view/' + adminId">{{ adminName }}</a>
    </div>
    <div>Category: {{ category }}</div>

    <div>method: {{ method }}</div>
    <div>path: {{ path }}</div>
    <div>
      playerId: <a :href="'/players/' + playerId">{{ playerId }}</a>
    </div>
    <div v-show="requestBody !== '' || queryStringToObject(query) !== ''">
      Details:
      <json-viewer
        v-show="queryStringToObject(query) !== ''"
        :value="queryStringToObject(query)"
        :expand-depth="10"
        copyable
        boxed
      />
      <json-viewer
        v-show="requestBody !== ''"
        :value="requestBody === '' ? {} : JSON.parse(requestBody)"
        :expand-depth="10"
        copyable
        boxed
      />
    </div>

    <div style="text-align: right;" v-ago="timestamp"></div>

    <!--    todo: should cleanup -->
    <!--    <div>host: {{host}}</div>-->
    <!--    <div>remoteIpAddress: {{remoteIpAddress}}</div>-->
    <!--    <div>headers: {{headers}}</div>-->
    <!--    <div>routeData: {{routeData}}</div>-->
  </li>
</template>

<script>
export default {
  props: [
    "id",
    "adminId",
    "adminName",
    "category",
    "headers",
    "host",
    "method",
    "path",
    "playerId",
    "query",
    "remoteIpAddress",
    "requestBody",
    "routeData",
    "timestamp",
  ],
  methods: {
    queryStringToObject(queryString) {
      let obj = {};
      if (queryString) {
        queryString
          .slice(1)
          .split("&")
          .map((item) => {
            const [k, v] = item.split("=");
            v ? (obj[k] = v) : null;
          });
      }

      if (JSON.stringify(obj) === "{}") {
        return "";
      }
      return obj;
    },
  },
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  border: 1px solid #ccc;
}

li h3 {
  margin: 0;
  color: darkblue;
}

button {
  float: right;
}
span {
  color: darkgray;
}

h3 b {
  color: #42b983;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
