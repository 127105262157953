<template>
  <w-app>

    <base-search @search="updateSearch" :search-term="enteredSearchTerm"></base-search>
    <div class="categories" v-show="(itemTypes || []).length > 1">
      <w-radios
          v-model="itemTypeRadio"
          :items="itemTypes"
          inline
          color="pink-light3">
        <template #item="{ item }">
          <span > {{item.label}}</span>
        </template>
      </w-radios>
    </div>

    <div class="categories" v-show="(itemStatus || []).length > 1">
      <w-radios
          v-model="itemStatusRadio"
          :items="itemStatus"
          inline
          color="green">
        <template #item="{ item }">
          <span > {{item.label}}</span>
        </template>
      </w-radios>
    </div>

    <div class="categories" v-show="(itemFactory || []).length > 1">
      <w-radios
          v-model="itemFactoryRadio"
          :items="itemFactory"
          inline
          color="blue">
        <template #item="{ item }">
          <span > {{item.label}}</span>
        </template>
      </w-radios>
    </div>

    <div class="categories">
      Sorting by:
      <w-radios
          v-model="sortBy"
          :items="[
              { value: 'createdDt', label: 'Create Date' },
              { value: 'startDt', label: 'Start Date' },
              { value: 'endDt', label: 'End Date' },
              { value: '__ItemId', label: 'Item Id' },
              { value: '__ItemFullName', label: 'Item Name' },
              { value: '__TimerTypeName', label: 'Type' },
              { value: '__TimerStatusName', label: 'Status' }
            ]"
          inline
      />
      <span id="SortButtonGroup">
        <w-button @click="sort('asc')" :outline="sorting !== 'asc'">Ascending</w-button>
        <w-button @click="sort('desc')" :outline="sorting !== 'desc'">Descending</w-button>
      </span>
    </div>
    <div v-if="displayedItems.length > 0">
      Total: <b>{{ displayedItems.length }}</b> results
    </div>
    <hr>
    <div v-show="displayedItems.length === 0">
      There are <b>no items</b> in this timer.
    </div>
    <table v-show="displayedItems.length !== 0" class="table table-hover table-striped table-bordered">
      <thead class="table-light">
        <tr class="text-center">
          <th>Timer Id</th>
          <th>Item</th>
          <th>Item Name</th>
          <th>Amount</th>
          <th>Factory</th>
          <th>Create Date</th>
          <th>Start Date</th>
          <th>End date</th>
          <th>Type</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <fabrication-item
            v-for="item in displayedItems"
            :key="item.id"
            :item="item"
            :getItemFullName="getItemFullName"
            :getItemThumbnail="getItemThumbnail"
            v-model="fabricationJson"
            @timer-speedup="timerSpeedupDialog"
            @timer-cancel="timerCancel"
            @timer-re-active="timerReActive"
            @timer-suspend="timerSuspend"

        />
      </tbody>
      <tfoot>
        <tr>
          <td colspan="9">&nbsp;</td>
        </tr>
      </tfoot>
    </table>

    <w-dialog
        v-model="fabricationJson"
        :title="fabricationJson? fabricationJson.__ItemFullName: ''"
        transition="bounce"
        :fullscreen="true"
    >
      <div class="row" style="margin-top: 60px">
        <w-flex justify-end>
          <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
        </w-flex>
      </div>
      <div class="row">
        <h3>Timer Id: <u>{{ fabricationJson.id }}</u></h3>
      </div>
      <div class="row" v-show="fabricationJson.histories.length !== 0">
        <table class="table table-hover table-striped table-bordered">
          <thead class="table-light">
            <caption>History</caption>
            <tr class="text-center">
              <th>ChangedType</th>
              <th>OccuredAt</th>
              <th>PrevEndDt</th>
              <th>PostEndDt</th>
              <th>PrevDuration</th>
              <th>PostDuration</th>
              <th>PrevStatus</th>
              <th>PostStatus</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in fabricationJson.histories" :key="i.id">
              <td>{{i["changedType"]}}</td>
              <td>{{i["occuredAt"]}}</td>
              <td>{{i["prevEndDt"]}}</td>
              <td>{{i["postEndDt"]}}</td>
              <td>{{i["prevDuration"]}}</td>
              <td>{{i["postDuration"]}}</td>
              <td>{{i["prevStatus"]}}</td>
              <td>{{i["postStatus"]}}</td>
              <td>{{i["reason"]}}</td>
            </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="9">&nbsp;</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <div class="row">
        <json-viewer
            :sort="true"
          :value="cleanHiddenElememt(fabricationJson)"
          :expand-depth=10
        />
      </div>
      <div class="row">
        <w-flex justify-end>
          <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
        </w-flex>
      </div>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>

    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Waiting..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog
        v-model="speedupDialog.show"
        :width="500"
        title-class="primary-light1--bg white">
      <template #title>
        <w-icon class="mr2">mdi mdi-tune</w-icon>
        Speed up
      </template>

      <h4>{{speedupDialog.timer["__ItemFullName"]}}</h4>
      <div style="display: flex; align-items: center; padding-left: 10px; padding-right: 10px">
        <div>
          <img :src="'https://cdn-nimbusx-assets.azureedge.net/nimbus-gmt-assets/'+ getItemThumbnail(speedupDialog.timer['__ItemId']) + '.png'"
               onerror="this.onerror=null;this.src='/images/onerror.png'"
               style="width: 100px"
          />
        </div>
        <div style="border:thin;width: 375px;border:1px solid black; height: 20px;">
          <span :style="'width: ' + processedPercent() + '%; height: 15px; display:inline-block; background: #4ECFFF'"></span>
          <span :style="'width: ' + (deltaSecond(speedupDialog.timer['endDt']) / speedupDialog.timer.duration * 100 * reduceTime()) + '%; height: 15px; display:inline-block; background: #02FF88'"></span>
        </div>
      </div>
      <div>
        <div>
          Requirements : {{secondsToString(deltaSecond(speedupDialog.timer["endDt"]))}} ({{ processedPercent().toFixed(0) }}% is done)
        </div>
        <div>
          <div class="mt4">
            Speed up:

            {{ secondsToString((deltaSecond(speedupDialog.timer["endDt"])) * reduceTime())}} save (<code class="ml1">{{(reduceTime() * 100).toFixed(0)}}%</code> reduce)
          </div>
          <w-slider v-model="speedupDialog.step" :step="1" :min="0" :max="5"></w-slider>
        </div>
      </div>

      <template #actions>
        <span class="white">{{ speedupDialog._sec }}</span> <!-- for timer -->
        <div class="spacer" />
        <w-button bg-color="success" @click="timerSpeedup" v-privilege="PRIVILEGES.PLAYER_WRITE">Speed Up</w-button>
        <w-button bg-color="error" @click="speedupDialog.show = false">Close</w-button>
      </template>
    </w-dialog>

  </w-app>
</template>
<script>
import {ref, toRefs, onUpdated, watch, reactive} from 'vue';
import useSearch from '../../hooks/search.js';
import useSort from '../../hooks/sort.js';
import useFilter from '../../hooks/filter.js';
import FabricationItem from "./FabricationItem.vue";
import {fetching, PRIVILEGES} from "../../config/context";
import {useRoute} from 'vue-router';

export default {
  components: {
    FabricationItem
  },

  props: [
    'items', "getItemFullName", "getTimerTypeName", "change-fabrication", "getItemThumbnail", "getTimerStatusName"
  ],
  setup(props, context) {
    const route = useRoute();
    const isLoading = ref(false);
    const error = ref(null);

    const itemTypeRadio = ref("");
    const itemTypes = ref(null);

    const itemStatusRadio = ref("");
    const itemStatus = ref(null);

    const itemFactoryRadio = ref("");
    const itemFactory = ref(null);

    const inputValue = ref(null);
    const inputEvents = ref(null);

    const fabricationJson = ref(null);

    function deltaSecond(dd) {
      const currentDate = new Date().getTime();
      const ddDt = new Date(dd).getTime();

      return ((ddDt - currentDate) / 1000)
    }

    const speedupDialog = reactive({
      step: 1,
      show: false,
      timerId : '',
      timer : null,
      _sec: null
    });

    const { items } = toRefs(props);
    const sortBy = ref('createdDt');

    const {filteredItems : statusFilteredItems, filterFields: statusFilterFields} = useFilter(
        items, "status"
    );

    const {filteredItems : typeFilteredItems, filterFields: typeFilterFields} = useFilter(
        statusFilteredItems, "type"
    );

    const {filteredItems : factoryFilteredItems, filterFields: factoryFilterFields} = useFilter(
        typeFilteredItems, "factory"
    );

    const { enteredSearchTerm, availableItems, updateSearch } = useSearch(
        factoryFilteredItems, ['id', '__TimerTypeName', '__TimerStatusName', '__ItemFullName', '__ItemId', 'contextId', 'factory']
    );
    const { sorting, displayedItems, sort } = useSort(
        availableItems, sortBy
    );

    const handleError = () => {
      fabricationJson.value = null;
      error.value = null;
    }

    onUpdated(() => {
      if (items.value === null) return;

      function _extractedItems(key, getName) {
        const groupByItemsCount = items.value.groupBy(key);
        const groupByKeys = Object.keys(groupByItemsCount);

        const sortedKeys = [...groupByKeys].sort((a, b) => groupByItemsCount[b] - groupByItemsCount[a]);

        const radioItems = sortedKeys.map(x => {
          const name = (getName !== undefined)? getName(x): x;
          return {label: name + " (" + groupByItemsCount[x] + ")", value: x}
        });

        radioItems.unshift({label: `All ${key} (${items.value.length})`, value: ""});
        return radioItems;
      }

      const radioTypesItems = _extractedItems("type", props.getTimerTypeName);
      itemTypes.value = radioTypesItems;

      const radioStatusItems = _extractedItems("status", props.getTimerStatusName);
      itemStatus.value = radioStatusItems;

      const radioFactoryItems = _extractedItems("factory");
      itemFactory.value = radioFactoryItems;
    });


    let __timerInterval = null;

    watch([() => speedupDialog.show], function(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if (curValue.toString() === "true") {
          __timerInterval = setInterval(function(){
            speedupDialog._sec = new Date().getSeconds();
          }, 1000);
        } else {
          clearInterval(__timerInterval);
        }
      }
    });

    watch(itemTypeRadio, function() {
      if (itemTypeRadio.value === ""){
        const groupByType = items.value.groupBy('type');
        typeFilterFields.value = Object.keys(groupByType);
        return;
      }
      typeFilterFields.value = [itemTypeRadio.value];
    });

    watch(itemStatusRadio, function() {
      if (itemStatusRadio.value === ""){
        const groupByStatus = items.value.groupBy('status');
        statusFilterFields.value = Object.keys(groupByStatus);
        return;
      }
      statusFilterFields.value = [itemStatusRadio.value];
    });

    watch(itemFactoryRadio, function() {
      if (itemFactoryRadio.value === ""){
        const groupByStatus = items.value.groupBy('factory');
        factoryFilterFields.value = Object.keys(groupByStatus);
        return;
      }
      factoryFilterFields.value = [itemFactoryRadio.value];
    });

    function cleanHiddenElememt(data){
      Object.keys(data).forEach(x => {
        if (x.startsWith("__")) delete data[x];
      })
      return data;
    }

    const timerSpeedupDialog = (timerId) => {
      speedupDialog.step = 0;
      speedupDialog.timerId = timerId;
      speedupDialog.timer = items.value.filter(x => x.id === speedupDialog.timerId )[0];
      speedupDialog.show = true;
    }

    const timerSpeedup = () => {

      if(!confirm("Are you sure to speed up this timer?")) return;

      speedupDialog.show = false;
      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/timer/${speedupDialog.timerId}/speedup/${speedupDialog.step}`, { "method" : 'PUT' }).then((res) => {
        console.log(res)
        if (res.errorMessage !== '' ){
          throw res.errorMessage;
        }


        context.emit('change-fabrication');
      }).catch(e => {
        speedupDialog.show = true;
        error.value = e.message || 'Failed to re-active the timer, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const timerCancel = (timerId) => {

      if(!confirm("There will be no refund for player's resources!\nAre you sure to cancel this process?")) return;

      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/timer/${timerId}/cancel`, { "method" : 'DELETE' }).then((res) => {
        if (res.errorMessage !== '' ){
          throw res.errorMessage;
        }
        console.log(res)
        context.emit('change-fabrication');
      }).catch(e => {
        error.value = e.message || 'Failed to cancel the timer, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const timerReActive = (timerId) => {

      if(!confirm("Are you sure to re-active this timer?")) return;

      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/timer/${timerId}/resume`, { "method" : 'PUT' }).then((res) => {
        if (res.errorMessage !== '' ){
          throw res.errorMessage;
        }
        console.log(res)
        context.emit('change-fabrication');
      }).catch(e => {
        error.value = e.message || 'Failed to re-active the timer, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    const timerSuspend = (timerId) => {

      if(!confirm("Are you sure to suspend this timer?")) return;

      isLoading.value = true;

      const playfabId = route.path.split("/").reverse()[0];
      fetching(`/api/profile/${playfabId}/timer/${timerId}/suspend`, { "method" : 'PUT' }).then((res) => {
        if (res.errorMessage !== '' ){
          throw res.errorMessage;
        }
        console.log(res)

        context.emit('change-fabrication');
      }).catch(e => {
        error.value = e.message || 'Failed to suspend the timer, try later.';
      }).finally(() => {
        isLoading.value = false;
      });
    }

    function secondsToString(seconds) {
      const numyears = Math.floor(seconds / 31536000);
      const numdays = Math.floor((seconds % 31536000) / 86400);
      const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
      const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
      const numseconds = Math.round(((seconds % 31536000) % 86400) % 3600) % 60;

      var returnStr = "";
      returnStr += (numyears > 0)? `${numyears} years `: "";
      returnStr += (numdays > 0)? `${numdays} days `: "";
      returnStr += (numhours > 0)? `${numhours} hours `: "";
      returnStr += (numminutes > 0)? `${numminutes} minutes `: "";
      returnStr += (numseconds > 0)? `${numseconds} seconds `: "";
      return returnStr.trim();
    }

    const reduceTime = () => {
      let speedUp = 1;
      let returnUp = 0;
      for (let i = 0; i < speedupDialog.step; i++) {
        speedUp = speedUp/2
        returnUp += speedUp
      }

      return returnUp;
    }

    const processedPercent = () => {
      return Math.min(Math.abs(deltaSecond(speedupDialog.timer['startDt'])) / speedupDialog.timer.duration * 100, 100)
    }


    return {
      handleError, cleanHiddenElememt,
      enteredSearchTerm, updateSearch, displayedItems,
      sorting, sortBy, sort, availableItems, fabricationJson,
      inputValue, inputEvents, itemTypeRadio, itemTypes, PRIVILEGES, deltaSecond, secondsToString,
      timerReActive, timerSpeedup, timerSpeedupDialog, timerCancel, timerSuspend, error, isLoading, speedupDialog,
      reduceTime, processedPercent, itemStatusRadio, itemStatus, itemFactoryRadio, itemFactory
    };
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#SortButtonGroup {
  margin-left: 10px;
}

.categories {
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
