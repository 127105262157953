<template>
  <w-app>
    <w-breadcrumbs :items="breadcrumbs" />
    <w-divider class="my6 mx-3"></w-divider>
    <div class="container-fluid">
      <div class="row" >
        <h2><em>{{ who }}</em> Activity Stream</h2>
        <audit-list :items="items"/>
      </div>
    </div>
    <w-dialog id="centerAlignDialog" v-model="isLoading" title="Loading..." persistent :width="400">
      <div class="spinner-border text-primary"/>
    </w-dialog>

    <w-dialog v-model="error" title="An error occurred" transition="bounce" :width="400">
      {{ error }}
      <w-flex justify-end>
        <w-button bg-color="error" @click="handleError" shadow>Close</w-button>
      </w-flex>
    </w-dialog>
  </w-app>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import {fetching} from "../../config/context";
import {useRoute} from 'vue-router';
import AuditList from '../../components/audit/AuditList.vue'
export default {
  components: {
    AuditList
  },
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  setup() {
    const route = useRoute();
    const isLoading = ref(true);
    const error = ref(null);
    const loading = ref(true);
    const items = ref([]);

    const who = ref('My');

    onBeforeMount(() => {
      const lastResource = route.path.split("/").reverse()[0];

      let api = '/api/activity/me?size=1000';
      if (lastResource !== 'activityLog'){
        api = '/api/admin/activity/user/'+ lastResource +'?size=1000';
        who.value = lastResource + "'s";
      }

      fetching(api).then( data  => {
          items.value = data;
        }).catch(e => {
          error.value = e.message || 'Failed to get the User, try later.';
        }).finally(() => {
          isLoading.value = false;
        });
    });

    const handleError = () => {
      error.value = null;
    };

    return {
      items, loading, isLoading, error, who,
      handleError
    };
  },
};
</script>