<template>
  <w-select class="py1 pa3" :items="ClanMemberState.items" v-model="CurrentStateId"
    @item-click="setNewState(CurrentStateId)">
  </w-select>

  <w-dialog v-model="dialogshowChangeClanState" title="Change ClanMember State" persistent :width="550">
    Are you sure to change clan State for PlayerId: <b>{{ playerIdInChangeState }}</b>
    and leaderId <b>{{ leaderIdInChangeState }} </b> to New State {{ newStateIdInChangeState }}

    <template #actions>
      <div class="spacer" />
      <w-button @click="ApplyMemberChangeState()" bg-color="error" shadow>
        Apply change clan member State
      </w-button>
      <w-button @click="dialogshowChangeClanState = false" bg-color="success">
        close
      </w-button>
    </template>
  </w-dialog>
  
  <w-dialog id="centerAlignDialog" v-model="isLoadingStateChange" title="Loading..." persistent :width="400">
    <div class="spinner-border text-primary" />
  </w-dialog>
  
</template>

<script>
import { ref } from 'vue';
import { fetching } from "../../config/context";

export default {
  name: "MemberChangeState",
  props:
    ["playeridInput", "leaderIdInput", "CurrentStateIdInput", "ClanIdInput"]
  ,
  setup: function (props) {
    const isLoading = ref(true);
    const error = ref(null);
    const isLoadingStateChange = ref(false);
    const playerIdInChangeState = ref("");
    const leaderIdInChangeState = ref("");
    const CurrentStateId = ref("");
    const CurrentStateName = ref("");
    const playerName = ref("");
    const newStateId = ref("");
    const ClanId = ref("");

    const newStateIdInChangeState = ref("");
    const isCreate = ref(false);
    const dialogshowChangeClanState = ref(false);
    const responsdata = ref();


    const OpenCloseDialogChangeClanState = () => {
      dialogshowChangeClanState.value = !(dialogshowChangeClanState.value);
      playerIdInChangeState.value = props.playeridInput;
      leaderIdInChangeState.value = props.leaderIdInput;
      CurrentStateId.value = props.CurrentStateIdInput;
    };

    const setNewState = (id) => {
      newStateIdInChangeState.value = id;
      OpenCloseDialogChangeClanState();
    }


    const ClanMemberState = {
      items: [
        { label: 'None', value: 0 },
        { label: 'Normal', value: 1 },
        { label: 'Applied', value: 2 },
        { label: 'Denied', value: 3 },
        { label: 'Invited', value: 4 },
        { label: 'Banned', value: 9 }
      ]
    }

    const setValue = () => {
      CurrentStateId.value = props.CurrentStateIdInput;
      playerIdInChangeState.value = props.playeridInput;
      leaderIdInChangeState.value = props.leaderIdInput;
      CurrentStateName.value = ClanMemberState.items[CurrentStateId.value].label;
      ClanId.value = props.ClanIdInput;
    }
    setValue();

    const ApplyMemberChangeState = () => {
      const body = {
        "clanId": "",
        "clanStateId": newStateIdInChangeState.value,
        "memebrId": playerIdInChangeState.value,
        "playerId": leaderIdInChangeState.value
      }

      console.log(body);
      isLoadingStateChange.value = true;
      fetching('/api/Alliance/AllianceMemberState', {
        method: "POST",
        body: body
      }).then(data => {
        responsdata.value = data;
        console.log(responsdata.value);
        error.value = responsdata.value.errorMessage || 'clan member removed successfully';
        isLoading.value = false;
        dialogshowChangeClanState.value = false;
        CurrentStateId.value = newStateIdInChangeState.value;
      }).catch(e => {
        error.value = e.message || 'Failed to create the Clan, try later.';
        isLoading.value = false;
      }).finally(() => {
        isLoading.value = false;
        isLoadingStateChange.value = false;
      });
    }

    return {
      isCreate, OpenCloseDialogChangeClanState, ApplyMemberChangeState, dialogshowChangeClanState, playerIdInChangeState, ClanMemberState,
      CurrentStateId, playerName, newStateId, setNewState, newStateIdInChangeState, CurrentStateName, leaderIdInChangeState, isLoadingStateChange
    }
  }
};
</script>


